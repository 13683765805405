
import { PropType, defineComponent } from "vue";
import {
  RemoteData,
  isInitial,
  isPending,
  isSuccess,
  isFailure,
  getOrElse,
} from "@devexperts/remote-data-ts";

import date from "../filters/date";
import formatNumber from "../filters/formatNumber";
import prefix from "../filters/prefix";
import suffix from "../filters/suffix";
import { LeaseRentReview, RentReviewType } from "../models/LeaseRentReview";
import { Maybe } from "../types/Maybe";
import { getCell, applyCell, formatCell } from "../utils/cell";

import IconCallMergeSvg from "../assets/images/icon-call-merge.svg";
import IconInvisibleSvg from "../assets/images/icon-invisible.svg";
import IconVisibleSvg from "../assets/images/icon-visible.svg";
import IconUnitFacilitySvg from "../assets/images/icon-unit-facility.svg";
import IconStairsSvg from "../assets/images/icon-stairs.svg";
import IconExcludeInSummary from "../assets/images/icon-exclude-in-summary.svg";

import CollapsibleArea from "./CollapsibleArea.vue";
import NegotiationSummary from "./NegotiationSummary.vue";
import EllipsisText from "./EllipsisText.vue";

import { FloorUnitTableData } from "./FloorUnitTableOfficeViewModel";

export default defineComponent({
  name: "FloorUnitTableOffice",

  components: {
    IconCallMergeSvg,
    IconInvisibleSvg,
    IconVisibleSvg,
    IconUnitFacilitySvg,
    IconStairsSvg,
    IconExcludeInSummary,
    NegotiationSummary,
    CollapsibleArea,
    EllipsisText,
  },

  props: {
    tableRemoteData: {
      type: Object as PropType<RemoteData<Error, FloorUnitTableData>>,
      required: true,
    },
  },

  emits: ["officeUnitClick", "tableScroll"],

  data() {
    return {
      containerScrolled: false,
      remarksShown: true,
      RentReviewType,
    };
  },

  computed: {
    floorRows() {
      return getOrElse(() => [] as FloorUnitTableData)(this.tableRemoteData);
    },
    isLoading() {
      return isPending(this.tableRemoteData);
    },
    isEmpty() {
      return (
        isInitial(this.tableRemoteData) ||
        (isSuccess(this.tableRemoteData) &&
          this.tableRemoteData.value.length === 0)
      );
    },
    isFailure() {
      return isFailure(this.tableRemoteData);
    },
  },

  methods: {
    formatNumber,
    prefix,
    suffix,
    date,
    getCell,

    formatDigits(value: number) {
      return formatNumber(value, 2);
    },

    formatRflp(value: Maybe<string>, type: "rf" | "lp") {
      if (value == null) {
        return "";
      }
      switch (type) {
        case "rf":
          return `RF: ${value}`;
        case "lp":
          return `LP: ${value}`;
      }
    },

    emitScrollData() {
      const {
        clientHeight,
        clientWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
        scrollWidth,
      } = this.$refs.container as Element;
      this.$emit("tableScroll", {
        clientHeight,
        clientWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
        scrollWidth,
      });
    },

    handleContainerScroll(e: Event) {
      this.emitScrollData();
      const { scrollLeft } = e.target as Element;
      if (!this.containerScrolled && scrollLeft > 0) {
        this.containerScrolled = true;
      } else if (this.containerScrolled && scrollLeft === 0) {
        this.containerScrolled = false;
      }
    },

    handleUnitRowClick(
      buildingUnitId: number,
      leaseUnitId: Maybe<number>,
      effectiveUnitRentStartDate: Maybe<Date>
    ) {
      this.$emit("officeUnitClick", {
        buildingUnitId,
        leaseUnitId,
        effectiveUnitRentStartDate,
      });
    },

    handleCustomRemarksClick(unitRowKey: Maybe<string>) {
      if (unitRowKey == null) {
        return;
      }
      const container = this.$refs.container as Element;
      const el = container.querySelector(`[id='${unitRowKey}']`);
      if (el == null) {
        return;
      }
      container.scrollBy({
        top:
          el.getBoundingClientRect().top -
          container.getBoundingClientRect().top -
          56, // Offset for header
        behavior: "smooth",
      });
    },

    toggleRemarksShown() {
      this.remarksShown = !this.remarksShown;
    },

    hasOtherFacilities(facilities: Maybe<string[]>) {
      return (
        facilities != null &&
        facilities.filter((x) => x !== "Staircase").length > 0
      );
    },

    hasStaircaseFacility(facilities: Maybe<string[]>) {
      return facilities != null && facilities.includes("Staircase");
    },

    scrollX(left: number) {
      this.$refs.container.scrollTo({ left });
    },

    scrollY(top: number) {
      this.$refs.container.scrollTo({ top });
    },
  },

  filters: {
    formatNumber,
    prefix,
    suffix,
    date,
    getCell,
    applyCell,
    formatCell,

    formatDigits(value: number) {
      return formatNumber(value, 2);
    },

    formatRentReview(rentReview: LeaseRentReview, kind: "cap" | "collar") {
      const { minimumRent, maximumRent, minimumPercentage, maximumPercentage } =
        rentReview;
      switch (kind) {
        case "cap": {
          if (maximumRent != null) {
            return `Cap: $${formatNumber(maximumRent)}`;
          }
          if (maximumPercentage != null) {
            return `Cap: ${formatNumber(maximumPercentage)}%`;
          }
          return "";
        }
        case "collar": {
          if (minimumRent != null) {
            return `Collar: $${formatNumber(minimumRent)}`;
          }
          if (minimumPercentage != null) {
            return `Collar: ${formatNumber(minimumPercentage)}%`;
          }
          return "";
        }
      }
    },
  },

  mounted() {
    this.emitScrollData();
    window.addEventListener("resize", this.emitScrollData);
  },

  updated() {
    this.emitScrollData();
  },

  unmounted() {
    window.removeEventListener("resize", this.emitScrollData);
  },
});

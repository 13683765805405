
import { getToken } from "@/utils/auth";
import { getCodeMap } from "@/api/code";
import { getMe } from "@/api/me";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",

  mounted() {
    if (this.isLoggedIn) {
      getMe().then((res) => {
        sessionStorage.setItem(
          "OperationCodes",
          JSON.stringify(res.result.operationCodes)
        );
      });
      getCodeMap().then((res) => {
        sessionStorage.setItem("CodeMapData", JSON.stringify(res.result));
      });
      this.$router.replace("/StackingPlan");
    }
  },

  computed: {
    isLoggedIn() {
      const token = getToken();
      return token != null;
    },
  },
});

import { Negotiation, NegotiationListSchema } from "../models/Negotiation";
import apiClient from "../utils/apiClient";

export const getNegotiationsByBuildingCodeAndBuildingUnitIds = async (
  buildingCode: string,
  buildingUnitIds: number[]
): Promise<Negotiation[]> => {
  const response = await apiClient.get(
    `stackingplan/negotiations/building-code/${buildingCode}?buildingUnitIds=${buildingUnitIds.join(
      ","
    )}`
  );
  const result = await NegotiationListSchema.validate(response);
  return result;
};

export const getNegotiationsByMOCodeAndBuildingUnitIds = async (
  moCode: string,
  buildingUnitIds: number[]
): Promise<Negotiation[]> => {
  const response = await apiClient.get(
    `stackingplan/negotiations/mo-code/${moCode}?buildingUnitIds=${buildingUnitIds.join(
      ","
    )}`
  );
  const result = await NegotiationListSchema.validate(response);
  return result;
};

import * as yup from "yup";

import { PlsDateSchema } from "./PlsDate";
import { TORPercentageSchema } from "./TORPercentage";

export const LeaseTurnoverRentSchema = yup.object({
  leaseShopID: yup.number().nullable(),
  salesCategory: yup.string().nullable(),
  startDate: PlsDateSchema.nullable(),
  endDate: PlsDateSchema.nullable(),
  torPercentages: yup.array().of(TORPercentageSchema.required()).nullable(),
});

export type LeaseTurnoverRent = yup.InferType<typeof LeaseTurnoverRentSchema>;

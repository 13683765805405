
import { defineComponent, PropType } from "vue";
import { Hooper, Slide } from "hooper";

import "hooper/dist/hooper.css";

import { File } from "../models/File";

import IconZoomInSvg from "../assets/images/icon-zoom-in.svg";
import IconCloseSvg from "../assets/images/icon-close.svg";
import OSSImage from "./OSSImage.vue";

export default defineComponent({
  name: "BuildingViewCarousel",

  components: {
    IconZoomInSvg,
    IconCloseSvg,
    Hooper,
    Slide,
    OSSImage,
  },

  props: {
    views: { type: Array as PropType<File[]>, required: true },
    itemIndex: { type: Number, required: true },
    showHeading: { type: Boolean, default: () => false },
    showThumbnails: { type: Boolean, default: () => false },
    showCloseControl: { type: Boolean, default: () => false },
    showZoomControl: { type: Boolean, default: () => false },
    lazyLoadHooper: { type: Boolean, default: () => false },
  },

  data() {
    return {
      showHooper: false,
    };
  },

  emits: ["closeClick", "itemClick", "zoomClick", "itemIndexChange"],

  computed: {
    showCarousel() {
      return this.views.length > 0;
    },
  },

  methods: {
    handleCloseClick() {
      this.$emit("closeClick");
    },
    handleItemClick(itemIndex: number) {
      this.$emit("itemClick", itemIndex);
    },
    handleZoomClick() {
      this.$emit("zoomClick");
    },
    handleHooperSlide(payload: { currentSlide: number; slideFrom: number }) {
      this.$emit("itemIndexChange", payload.currentSlide);
    },
    loadHooper() {
      this.$refs.hooper.update();
      this.showHooper = true;
    },
    unloadHooper() {
      this.showHooper = false;
    },
  },

  watch: {
    itemIndex(value: number) {
      this.$refs.hooper.slideTo(value);
    },
  },
});

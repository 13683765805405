
import { PropType, defineComponent } from "vue";

import { Legend, LegendFilterType, LegendIconType } from "../models/Legend";
import { deriveLegendUniqueKey } from "../utils/legend";
import { getOptions } from "../utils/options";

export default defineComponent({
  name: "LegendFilterItem",

  props: {
    legend: {
      type: Object as PropType<Legend>,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["click"],

  data(): {
    legendLabel: string;
  } {
    return { legendLabel: "" };
  },

  computed: {
    LegendIconType() {
      return LegendIconType;
    },
  },

  methods: {
    click() {
      this.$emit("click", deriveLegendUniqueKey(this.legend));
    },

    makeLabel(legend: Legend) {
      const labelOptions = getOptions("legendLabelOptions");
      const label = labelOptions.find(
        (option) => option.value === legend.codeValue
      )?.name;
      return label ?? this.makeFallbackLabel(legend);
    },

    makeFallbackLabel(legend: Legend) {
      const { filterType } = legend;
      switch (filterType) {
        case LegendFilterType.VACANT:
          return this.$skp.t("legendFilterItem.vacant");
        case LegendFilterType.LIKELY_TO_VACATE:
          return this.$skp.t("legendFilterItem.likelyToVacate");
        case LegendFilterType.CONFIRMED_TO_VACATE:
          return this.$skp.t("legendFilterItem.confirmedToVacate");
        case LegendFilterType.VULNERABLE:
          return this.$skp.t("legendFilterItem.vulnerable");
        case LegendFilterType.LTE_12_MONTHS:
          return this.$skp.t("legendFilterItem.lte12Months");
        case LegendFilterType.GT_12_MONTHS:
          return this.$skp.t("legendFilterItem.gt12Months");
        case LegendFilterType.RENT_REVIEW_IN_12_MONTHS:
          return this.$skp.t("legendFilterItem.rentReviewIn12Months");
        case LegendFilterType.HIGHEST_ZONE:
          return this.$skp.t("legendFilterItem.highestZone");
        case LegendFilterType.HIGH_ZONE:
          return this.$skp.t("legendFilterItem.highZone");
        case LegendFilterType.MID_ZONE:
          return this.$skp.t("legendFilterItem.midZone");
        case LegendFilterType.LOW_ZONE:
          return this.$skp.t("legendFilterItem.lowZone");
        case LegendFilterType.CALCULATION_C:
          return this.$skp.t("legendFilterItem.calculationC");
        case LegendFilterType.CALCULATION_O:
          return this.$skp.t("legendFilterItem.calculationO");
        case LegendFilterType.CALCULATION_F:
          return this.$skp.t("legendFilterItem.calculationF");
      }
    },
  },

  mounted() {
    this.legendLabel = this.makeLabel(this.legend);
  },

  watch: {
    "$i18n.locale": {
      handler() {
        this.legendLabel = this.makeLabel(this.legend);
      },
    },
  },
});

import { render, staticRenderFns } from "./BuildingUnitDetailsDialog.vue?vue&type=template&id=2672d790&scoped=true&"
import script from "./BuildingUnitDetailsDialog.vue?vue&type=script&lang=ts&"
export * from "./BuildingUnitDetailsDialog.vue?vue&type=script&lang=ts&"
import style0 from "./BuildingUnitDetailsDialog.vue?vue&type=style&index=0&id=2672d790&prod&lang=scss&"
import style1 from "./BuildingUnitDetailsDialog.vue?vue&type=style&index=1&id=2672d790&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2672d790",
  null
  
)

export default component.exports

import { defineComponent } from "vue";

export default defineComponent({
  name: "GrandTotalSummaryRemarks",

  props: {
    remarks: String,
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["editClick"],

  methods: {
    handleEditClick() {
      this.$emit("editClick");
    },
  },
});

import { Building } from "@/models/Building";

export function compareBuildingsByIsStreetBuildingAndDisplaySeq(
  a: Building,
  b: Building
): number {
  // Move street building to the end.
  if (a.isStreetBuilding !== b.isStreetBuilding) {
    return a.isStreetBuilding ? 1 : -1;
  }
  return a.displaySeq === b.displaySeq
    ? a.id - b.id
    : a.displaySeq - b.displaySeq;
}

export function isOfficeBuilding(building: Building): boolean {
  return building.isOfficeBuilding;
}

export function isOfficeOrStreetBuilding(building: Building): boolean {
  return building.isOfficeBuilding || building.isStreetBuilding;
}


import { defineComponent } from "vue";
import { downloadFile } from "../utils/oss";

export default defineComponent({
  name: "OSSImage",

  props: {
    fileName: {
      type: String,
    },
    cover: {
      type: Boolean,
      required: false,
    },
    contain: {
      type: Boolean,
      required: false,
    },
    width: {
      type: [Number, String],
      required: false,
    },
    height: {
      type: [Number, String],
      required: false,
    },
  },

  data(): { filePath: string | null } {
    return {
      filePath: null,
    };
  },

  methods: {
    resolveFilePath(fileName: string | undefined) {
      this.filePath = null;
      if (fileName) {
        downloadFile(fileName)
          .then((filePath) => (this.filePath = filePath))
          .catch(console.warn);
      }
    },
  },

  watch: {
    fileName(fileName: string | undefined) {
      this.resolveFilePath(fileName);
    },
  },

  mounted() {
    this.resolveFilePath(this.fileName);
  },
});


import { FoodLicenseSummary } from "../models/FoodLicenseSummary";
import Vue, { PropType } from "vue";

import { Building } from "../models/Building";
import { GrandTotalSummary } from "../models/GrandTotalSummary";

import GrandTotalSummaryTable from "./GrandTotalSummaryTable.vue";
import FoodLicenseSummaryTable from "./FoodLicenseSummaryTable.vue";

export default Vue.extend({
  name: "MOGrandTotalSummary",

  components: {
    GrandTotalSummaryTable,
    FoodLicenseSummaryTable,
  },

  props: {
    building: Object as PropType<Building | undefined>,
    grandTotalSummary: {
      type: Object as PropType<GrandTotalSummary | null>,
      default: null,
    },
    buildingLevelFoodLicenseSummary: {
      type: Object as PropType<FoodLicenseSummary | null>,
      default: null,
    },
    streetLevelFoodLicenseSummary: {
      type: Object as PropType<FoodLicenseSummary | null>,
      default: null,
    },
  },

  data() {
    return {
      tabIndex: 0,
      levelOptions: [
        {
          itemValue: "building",
        },
        {
          itemValue: "street",
        },
      ],
      selectedLevelOption: "building",
    };
  },
});

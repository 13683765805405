
import { defineComponent, PropType } from "vue";

import IconAppartmentSvg from "../assets/images/icon-appartment.svg";
import IconRetailSvg from "../assets/images/icon-retail.svg";
import { Building } from "../models/Building";
import { MO } from "../models/MO";
import { Maybe } from "../types/Maybe";
import { Option, SelectableOption } from "./BuildingSelectViewModel";
import OSSImage from "./OSSImage.vue";

export default defineComponent({
  name: "buildingSelect",

  components: {
    IconAppartmentSvg,
    IconRetailSvg,
    OSSImage,
  },

  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: String,
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    initialSelectedOption: {
      type: Object as PropType<Maybe<SelectableOption>>,
      default: null,
    },
  },

  emits: ["change"],

  data() {
    return {
      selectedOption: this.initialSelectedOption,
    };
  },

  methods: {
    handleChange(option: SelectableOption) {
      this.$emit("change", option);
    },

    getLocationSiteName(item: MO) {
      switch (this.$i18n.locale) {
        case "en":
          return item.name;
        case "zhcn":
          return item.nameCn;
        case "zhtw":
          return item.nameHk;
        default:
          return item.name;
      }
    },

    getName(item: Building) {
      switch (this.$i18n.locale) {
        case "en":
          return item.name;
        case "zhcn":
          return item.nameCn;
        case "zhtw":
          return item.nameHk;
        default:
          return item.name;
      }
    },
  },

  watch: {
    initialSelectedOption: {
      handler(value?: SelectableOption) {
        this.selectedOption = value;
      },
      deep: true,
      immediate: true,
    },
  },
});

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
import * as msal from "@azure/msal-browser";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Msal from "msal";
let msalConfig = {
  auth: {
    clientId: "",
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority: "",
    // Full redirect URL, in form of http://localhost:3000
    redirectUri: "",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

let timestamp = Date.parse(new Date());
fetch(`config.json?timestamp=${timestamp}`)
  .then((resp) => resp.json())
  .then((res) => {
    msalConfig.auth.clientId = res.clientId;
    msalConfig.auth.authority = res.authority;
    msalConfig.auth.redirectUri = res.redirectUri;
  });

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
  scopes: ["User.Read", "Mail.Read"],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

const refreshRequest = {
  scopes: ["User.Read"],
  forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
};
export { msalConfig, loginRequest, tokenRequest, refreshRequest };

import { Maybe } from "../types/Maybe";

export function set<Key extends string | number, Value>(
  record: Record<Key, Value>,
  key: Key,
  setter: (value: Maybe<Value>) => Value
) {
  return {
    ...record,
    [key]: setter(record[key]),
  };
}

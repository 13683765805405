type Color = string;

export function tintColor(color: Color, factor: number): Color {
  const r = color.slice(1, 3);
  const g = color.slice(3, 5);
  const b = color.slice(5, 7);
  return `#${[r, g, b]
    .map((x) => parseInt(x, 16))
    .map((x) => 255 - x)
    .map((x) => x * factor)
    .map((x) => Math.floor(x))
    .map((x) => 255 - x)
    .map((x) => x.toString(16))
    .join("")}`;
}

import { Module } from "vuex";

import { Building } from "../models/Building";

interface State {
  byBuildingCode: Record<Building["id"], string>;
}

export const buildingRemarksModule: Module<State, unknown> = {
  state: {
    byBuildingCode: {},
  },
  mutations: {
    updateBuildingRemarks(
      state,
      payload: { buildingCode: string; remarks: string }
    ) {
      state.byBuildingCode = {
        ...state.byBuildingCode,
        [payload.buildingCode]: payload.remarks,
      };
    },
  },
  actions: {
    updateBuildingRemarks(
      context,
      payload: { buildingCode: string; remarks: string }
    ) {
      context.commit("updateBuildingRemarks", payload);
    },
  },
  modules: {},
};

import { Module } from "vuex";

import {
  getKeyAttribute,
  PlsBuildingUnitLease,
} from "../models/PlsBuildingUnitLease";
import {
  getRecord,
  KeyAttribute,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

interface State {
  byKeyAttribute: RecordByKeyAttribute<PlsBuildingUnitLease>;
}

const getters = {
  getPlsBuildingUnitLease:
    (state: State) =>
    (keyAttribute: KeyAttribute): Maybe<PlsBuildingUnitLease> => {
      return getRecord(state.byKeyAttribute, keyAttribute);
    },
};

export const plsBuildingUnitLeaseModule: Module<State, unknown> = {
  state: {
    byKeyAttribute: {},
  },
  getters,
  mutations: {
    updatePlsBuildingUnitLease(state, payload: PlsBuildingUnitLease) {
      const keyAttribute = getKeyAttribute(payload);
      state.byKeyAttribute = setRecord(
        state.byKeyAttribute,
        keyAttribute,
        () => payload
      );
    },

    fillPlsBuildingUnitLeases(state, payload: PlsBuildingUnitLease[]) {
      state.byKeyAttribute = payload.reduce<State["byKeyAttribute"]>(
        (prev, curr) => setRecord(prev, getKeyAttribute(curr), () => curr),
        state.byKeyAttribute
      );
    },
  },
  actions: {
    fillPlsBuildingUnitLeases(context, payload: PlsBuildingUnitLease[]) {
      context.commit("fillPlsBuildingUnitLeases", payload);
    },

    updatePlsBuildingUnitLease(context, payload: PlsBuildingUnitLease) {
      context.commit("updatePlsBuildingUnitLease", payload);
    },
  },
  modules: {},
};

export interface Valid<T> {
  type: "Valid";
  data: T;
}

export function Valid<T>(data: T): Valid<T> {
  return {
    type: "Valid",
    data,
  };
}

export interface Invalid<E> {
  type: "Invalid";
  error: E;
}

export function Invalid<E>(error: E): Invalid<E> {
  return {
    type: "Invalid",
    error,
  };
}

export type Validate<T, E> = Valid<T> | Invalid<E>;

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-select',{staticClass:"select",attrs:{"items":_vm.options,"item-text":"itemText","item-value":"itemValue","item-disabled":"disabled","return-object":"","hide-details":"","dense":"","disabled":_vm.loading || _vm.disabled,"menu-props":{
    offsetY: true,
    auto: true, // Hack: ref https://github.com/vuetifyjs/vuetify/blob/42acb0c8a2a39f718b443532db41fb4ad1544305/packages/vuetify/src/components/VSelect/VSelect.ts#L229
    transition: false,
    nudgeTop: -5,
    zIndex: 20,
  }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([(_vm.selectedOption == null)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"placeholder"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"18","width":"2"}}):_c('span',[_vm._v(" "+_vm._s(_vm.placeholder ?? "Select")+" ")])],1)]},proxy:true}:null,{key:"selection",fn:function({ item }){return [(item.type === 'building')?_c('div',{staticClass:"selection"},[_vm._v(" "+_vm._s(_vm.getName(item.building))+" ")]):_vm._e(),(item.type === 'retail')?_c('div',{staticClass:"selection"},[_vm._v(" "+_vm._s(_vm.$skp.t("buildingSelect.option.retail", _vm.getLocationSiteName(item.mo)))+" ")]):_vm._e()]}},{key:"item",fn:function({ item, on, attrs }){return [(item.type === 'locationSite')?_c('v-subheader',{staticClass:"subheader",domProps:{"textContent":_vm._s(_vm.getLocationSiteName(item.locationSite))}}):_c('v-list-item',_vm._g(_vm._b({staticClass:"building-select__item",attrs:{"dense":""}},'v-list-item',attrs,false),on),[(item.type === 'building')?[_c('v-list-item-avatar',{attrs:{"rounded":"rounded","size":"24"}},[_c('OSSImage',{attrs:{"fileName":item.building.photoList?.[0]?.fileName ?? undefined,"width":"100%","height":"100%","cover":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"building-image__placeholder"},[_c('icon-appartment-svg',{staticClass:"building-image__placeholder-icon"})],1)]},proxy:true}],null,true)})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(_vm.getName(item.building))}})]:(item.type === 'retail')?[_c('v-list-item-avatar',{attrs:{"rounded":"rounded","size":"24"}},[_c('div',{staticClass:"retail-image"},[_c('div',{staticClass:"retail-image__placeholder"},[_c('icon-retail-svg',{staticClass:"retail-image__placeholder-icon"})],1)])]),_c('v-list-item-content',{domProps:{"textContent":_vm._s(
            _vm.$skp.t(
              'buildingSelect.option.retail',
              _vm.getLocationSiteName(item.mo)
            )
          )}})]:_vm._e()],2)]}}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})
}
var staticRenderFns = []

export { render, staticRenderFns }

import { defineComponent } from "vue";

export default defineComponent({
  name: "ScrollBar",

  props: {
    clientHeight: {
      type: Number,
      required: true,
    },
    scrollHeight: {
      type: Number,
      required: true,
    },
    scrollTop: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 10,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["drag"],

  data(): {
    trackSize: number;
    dragClientStart: null | number;
    dragOffsetStart: null | number;
    draggingOffset: null | number;
  } {
    return {
      trackSize: 0,
      dragClientStart: null,
      dragOffsetStart: null,
      draggingOffset: null,
    };
  },

  computed: {
    scrollable(): boolean {
      return this.scrollHeight != this.clientHeight;
    },
    isDragging(): boolean {
      return this.dragClientStart != null && this.dragOffsetStart != null;
    },
    offset(): number {
      return (
        this.draggingOffset ??
        this.getScaledSize(this.scrollTop, this.scrollHeight, this.trackSize)
      );
    },
    thumbSize(): number {
      return this.getScaledSize(
        this.clientHeight,
        this.scrollHeight,
        this.trackSize
      );
    },
    borderRadius(): number {
      return this.size / 2;
    },
  },

  methods: {
    cap(value: number, [min, max]: [number, number]) {
      return Math.min(max, Math.max(min, value));
    },
    getScaledSize(value: number, from: number, to: number) {
      return from !== 0 ? (value / from) * to : 0;
    },
    updateTrackSize() {
      const container = this.$refs.container as Element;
      if (container != null) {
        this.trackSize = this.horizontal
          ? container.clientWidth
          : container.clientHeight;
      }
    },
    handleThumbMousedown(e: MouseEvent) {
      this.dragClientStart = this.horizontal ? e.clientX : e.clientY;
      this.dragOffsetStart = this.offset;
      this.registerMousemoveListener();
    },
    handleThumbMouseup() {
      this.dragClientStart = null;
      this.dragOffsetStart = null;
      this.draggingOffset = null;
      this.unregisterMousemoveListener();
    },
    handleThumbMousemove(e: MouseEvent) {
      if (this.dragClientStart == null || this.dragOffsetStart == null) {
        return;
      }
      const dragClientEnd = this.horizontal ? e.clientX : e.clientY;
      const delta = dragClientEnd - this.dragClientStart;
      this.draggingOffset = this.cap(this.dragOffsetStart + delta, [
        0,
        this.trackSize - this.thumbSize,
      ]);
      const scroll = this.getScaledSize(
        this.draggingOffset,
        this.trackSize,
        this.scrollHeight
      );
      this.$emit("drag", scroll);
    },
    registerMousemoveListener() {
      window.addEventListener("mousemove", this.handleThumbMousemove);
      window.addEventListener("mouseup", this.handleThumbMouseup);
    },
    unregisterMousemoveListener() {
      window.removeEventListener("mousemove", this.handleThumbMousemove);
      window.removeEventListener("mouseup", this.handleThumbMouseup);
    },
  },

  mounted() {
    this.updateTrackSize();
  },

  updated() {
    this.updateTrackSize();
  },
});


import { PropType, defineComponent } from "vue";

import { getOptions } from "../utils/options";

import { OpportunityType } from "../models/Opportunity";

export default defineComponent({
  name: "OpportunityTypeBadge",

  props: {
    opportunityType: {
      type: Object as PropType<OpportunityType>,
      required: true,
    },
  },

  data() {
    return {
      OpportunityType,
    };
  },

  computed: {
    label() {
      const options = getOptions("negotiationTypeOptions");
      const option = options.find((x) => x.value === this.opportunityType);
      return option?.name ?? this.opportunityType;
    },
  },
});

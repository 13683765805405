export interface MonthDay {
  months: number;
  days: number;
}

// Ref
// https://github.com/oursky/swire-pmp-web-mgmt/blob/ce680ad7c8ca21ecff98daae7d41e2902140982b/src/utils/tool.js
export function getMonthDayFromStartAndEnd(
  dateStart: Date,
  dateEnd: Date
): MonthDay {
  const startYear = dateStart.getFullYear(),
    startMonth = dateStart.getMonth() + 1,
    startDay = dateStart.getDate(),
    endYear = dateEnd.getFullYear(),
    endMonth = dateEnd.getMonth() + 1,
    endDay = dateEnd.getDate();

  const startDaysOfMonth = new Date(startYear, startMonth, 0).getDate();
  const endDaysOfMonth = new Date(endYear, endMonth, 0).getDate();

  let numOfMonth = 0;
  let numOfDay = 0;

  if (endYear > startYear) {
    numOfMonth += (endYear - startYear - 1) * 12;
    numOfMonth += endMonth - 1;
    numOfMonth += 12 - startMonth;
  } else {
    numOfMonth += endMonth - startMonth - 1;
  }

  //Days
  numOfDay += startDaysOfMonth - startDay + 1;
  numOfDay += endDay;

  //Handle the last month is full month
  if (endDay == endDaysOfMonth) {
    numOfMonth += 1;
    numOfDay -= endDaysOfMonth;
  }
  //Handle the cross month
  if (endDay + 1 >= startDay) {
    numOfMonth += 1;
    numOfDay -= startDaysOfMonth;
  }
  //Handle when the count day < 0, hit when last month and cross month at the same time
  //When the start month is not full month, the use first month as base, otherwise use the end month as base
  let daysInMonth = endDaysOfMonth;
  if (startDay != 1) {
    daysInMonth = startDaysOfMonth;
  }

  if (numOfDay < 0) {
    numOfMonth -= 1;
    numOfDay += daysInMonth;
  }

  return {
    months: numOfMonth,
    days: numOfDay,
  };
}

export function getMonthsFromStartAndEnd(
  dateStart: Date,
  dateEnd: Date
): number {
  // NOTE:
  // Fixed 30 days to align their implementation, see:
  // https://github.com/oursky/swire-pmp-web-mgmt/blob/bbbc4bd859076ebb6b85917fc32ba5d254a8ce20/src/views/Prospect/Opportunities/details/component/mixins/negotiation.js#L26
  const daysPerMonth = 30;
  const monthDay = getMonthDayFromStartAndEnd(dateStart, dateEnd);
  return monthDay.months + monthDay.days / daysPerMonth;
}

export function formatMonthDay({ months, days }: MonthDay): string {
  return `${months}/${days}`;
}

export function formatMonthDayInterval(startDate: Date, endDate: Date): string {
  return formatMonthDay(getMonthDayFromStartAndEnd(startDate, endDate));
}

import { Module } from "vuex";

import { Negotiation } from "../models/Negotiation";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

interface State {
  byId: Record<Negotiation["id"], Negotiation>;
}

const getters = {
  getNegotiations:
    (state: State) =>
    (buildingUnitId: number): Negotiation[] => {
      return Object.values(state.byId)
        .filter((negotiation) => negotiation.opportunity.active)
        .filter((negotiation) =>
          negotiation.units.some(
            (unit) => unit.buildingUnitId === buildingUnitId
          )
        );
    },
};

export const negotiationModule: Module<State, unknown> = {
  state: {
    byId: {},
  },
  getters,
  mutations: {
    fillNegotiations(state, payload: Negotiation[]) {
      state.byId = payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.id]: curr,
        };
      }, state.byId);
    },
  },
  actions: {
    fillNegotiations(context, payload: Negotiation[]) {
      context.commit("fillNegotiations", payload);
    },
  },
  modules: {},
};

import { getOptions, OptionKey } from "../utils/options";

function getOptionName(
  value: string | undefined,
  key: OptionKey
): string | null {
  const options = getOptions(key);
  return options.find((option) => option.value === value)?.name ?? null;
}

export default getOptionName;

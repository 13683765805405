import {
  getKeyAttribute as getLeaseKeyAttribute,
  OfficeLease,
  RetailLease,
} from "../models/Lease";
import {
  getKeyAttribute as getLeaseCustomizationKeyAttribute,
  OfficeLeaseCustomization,
  RetailLeaseCustomization,
} from "../models/LeaseCustomization";
import { isVacant } from "../utils/lease";
import {
  getRecord,
  RecordByKeyAttribute,
  setRecord,
} from "./LeaseKeyAttribute";
import { Maybe } from "./Maybe";

export type OfficeLeaseAndCustomization = {
  lease: OfficeLease;
  customization: Maybe<OfficeLeaseCustomization>;
};

export type RetailLeaseAndCustomization = {
  lease: RetailLease;
  customization: Maybe<RetailLeaseCustomization>;
};

export type LeaseAndCustomization =
  | OfficeLeaseAndCustomization
  | RetailLeaseAndCustomization;

function getLeaseCustomizationMap<
  T extends OfficeLeaseCustomization | RetailLeaseCustomization
>(leaseCustomizations: T[]): RecordByKeyAttribute<T> {
  let res: RecordByKeyAttribute<T> = {};

  for (const leaseCustomization of leaseCustomizations) {
    res = setRecord(
      res,
      getLeaseCustomizationKeyAttribute(leaseCustomization),
      () => leaseCustomization
    );
  }

  return res;
}

export function makeOfficeLeaseAndCustomizations(
  leases: OfficeLease[],
  leaseCustomizations: OfficeLeaseCustomization[]
): OfficeLeaseAndCustomization[] {
  const leaseCustomizationMap = getLeaseCustomizationMap(leaseCustomizations);
  return leases.map((lease) => ({
    lease,
    customization: isVacant(lease)
      ? null
      : getRecord(leaseCustomizationMap, getLeaseKeyAttribute(lease)),
  }));
}

export function makeRetailLeaseAndCustomizations(
  leases: RetailLease[],
  leaseCustomizations: RetailLeaseCustomization[]
): RetailLeaseAndCustomization[] {
  const leaseCustomizationMap = getLeaseCustomizationMap(leaseCustomizations);
  return leases.map((lease) => ({
    lease,
    customization: isVacant(lease)
      ? null
      : getRecord(leaseCustomizationMap, getLeaseKeyAttribute(lease)),
  }));
}

export const getCustomizedOrElse =
  <
    Key extends keyof OfficeLeaseCustomization,
    T extends OfficeLeaseCustomization[Key]
  >(
    { lease, customization }: OfficeLeaseAndCustomization,
    key: Key
  ) =>
  (orElse: (lease: OfficeLease) => T) => {
    if (customization && customization[key] != null) {
      return customization[key];
    }
    return orElse(lease);
  };

import { Maybe } from "../types/Maybe";

export interface Option {
  value: string;
  name: string;
}

export type GetOptions = () => Option[];

export type GetOptionsMap = {
  leaseCustomizationStatusOptions: GetOptions;
  leaseCustomizationFacilitieOptions: GetOptions;
  leaseCustomizationUnitFeatureOptions: GetOptions;
  leaseCustomizationFoodLicenseOptions: GetOptions;
  legendLabelOptions: GetOptions;
  legalDocumentTypeOptions: GetOptions;
  negotiationTypeOptions: GetOptions;
};

export type OptionKey = keyof GetOptionsMap;

let _getOptionsMap: GetOptionsMap | null = null;

export function setupGetOptions(getOptionsMap: GetOptionsMap) {
  _getOptionsMap = getOptionsMap;
}

export function getOptions(key: OptionKey) {
  const getOptions = _getOptionsMap ? _getOptionsMap[key] : null;
  if (getOptions) {
    return getOptions();
  }
  return [];
}

export const getOptionLabel =
  (key: OptionKey) =>
  (value: string): Maybe<string> => {
    const labelOptions = getOptions(key);
    return labelOptions.find((option) => option.value === value)?.name;
  };

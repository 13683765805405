
import { defineComponent } from "vue";

import IconGreenTickOutlineSvg from "../assets/images/icon-green-tick-outline.svg";

export default defineComponent({
  name: "BuildingSummary",

  components: {
    IconGreenTickOutlineSvg,
  },

  props: {
    value: Boolean,
    message: String,
    timeout: { type: Number, default: 2000 },
  },

  emits: ["input"],

  methods: {
    handleInput(value: boolean) {
      this.$emit("input", value);
    },
  },
});

import { set } from "../utils/record";
import { isNonNullable, Maybe } from "./Maybe";

export interface KeyAttribute {
  plsBuildingUnitId: number;
  plsLeaseUnitId: string;
  effectiveUnitRentStartDateStr: string;
}

export type RecordByKeyAttribute<T> = Record<
  KeyAttribute["plsBuildingUnitId"],
  | Record<
      KeyAttribute["plsLeaseUnitId"],
      | Record<KeyAttribute["effectiveUnitRentStartDateStr"], T | undefined>
      | undefined
    >
  | undefined
>;

export function getRecord<T>(
  recordByKeyAttribute: RecordByKeyAttribute<T>,
  keyAttribute: KeyAttribute
): Maybe<T> {
  return recordByKeyAttribute[keyAttribute.plsBuildingUnitId]?.[
    keyAttribute.plsLeaseUnitId
  ]?.[keyAttribute.effectiveUnitRentStartDateStr];
}

export function setRecord<T>(
  recordByKeyAttribute: RecordByKeyAttribute<T>,
  keyAttribute: KeyAttribute,
  setter: (value: Maybe<T>) => T
): RecordByKeyAttribute<T> {
  return set(
    recordByKeyAttribute,
    keyAttribute.plsBuildingUnitId,
    (byBuildingUnitId) =>
      set(
        byBuildingUnitId ?? {},
        keyAttribute.plsLeaseUnitId,
        (byLeaseUnitId) =>
          set(
            byLeaseUnitId ?? {},
            keyAttribute.effectiveUnitRentStartDateStr,
            (v) => setter(v)
          )
      )
  );
}

export function getValuesFromRecords<T>(
  recordByKeyAttribute: RecordByKeyAttribute<T>
): T[] {
  return Object.values(recordByKeyAttribute).reduce<T[]>(
    (prev1, curr1) => [
      ...prev1,
      ...Object.values(curr1 ?? {}).reduce<T[]>(
        (prev2, curr2) => [
          ...prev2,
          ...Object.values(curr2 ?? {}).filter(isNonNullable),
        ],
        []
      ),
    ],
    []
  );
}

export function isEqual(a1: KeyAttribute, a2: KeyAttribute): boolean {
  return (
    a1.effectiveUnitRentStartDateStr === a2.effectiveUnitRentStartDateStr &&
    a1.plsBuildingUnitId === a2.plsBuildingUnitId &&
    a1.plsLeaseUnitId === a2.plsLeaseUnitId
  );
}

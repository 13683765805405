import differenceInSeconds from "date-fns/differenceInSeconds";

import formatNumber from "../filters/formatNumber";
import formatDate from "../filters/date";
import { Negotiation } from "../models/Negotiation";

export function deriveNegotiationDescription(negotiation: Negotiation) {
  let str = "";
  if (negotiation.faceUnitRent != null) {
    str += `$${formatNumber(
      parseInt(negotiation.faceUnitRent.toFixed(2), 10)
    )}`;
  }
  if (
    negotiation.licenceCommencementDate != null &&
    negotiation.licenceExpiryDate != null
  ) {
    const diff =
      differenceInSeconds(
        negotiation.licenceExpiryDate,
        negotiation.licenceCommencementDate
      ) /
      (60 * 60 * 24 * 30); // in months
    str += ` +${diff.toFixed(2)}LP`;
  }
  if (negotiation.rentFreePeriod != null) {
    str += ` +${negotiation.rentFreePeriod}RF`;
  }
  if (negotiation.effectiveUnitRent != null) {
    str += ` (eff. $${negotiation.effectiveUnitRent})`;
  }
  if (negotiation.leaseTermMonth) {
    const [month, day = "0"] = negotiation.leaseTermMonth.split("/");
    const year = (parseInt(month, 10) + parseInt(day, 10) / 30) / 12;
    str += ` for ${year.toFixed(2)} yrs`;
  }
  if (negotiation.leaseCommencementDate != null) {
    str += `, from LCD ${formatDate(
      negotiation.leaseCommencementDate,
      "dd/MM/yyyy"
    )}`;
  }
  return str;
}

export function formatRentReview(
  date?: null | Date,
  type?: null | string,
  cap?: null | number,
  collar?: null | number
) {
  const dateString = date ? formatDate(date, "dd MMM yyyy") : "";
  switch (type) {
    case "OMR":
      return `${dateString} - OMR`;
    case "Cap / Collar ($)":
      return `${dateString} - Cap: $${cap}%; Collar: $${collar}`;
    case "Cap / Collar (%)":
      return `${dateString} - Cap: ${cap}%; Collar: ${collar}%`;
    default:
      return "";
  }
}

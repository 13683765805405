import * as yup from "yup";

import { LeaseEffectiveUnitRentSchema } from "./LeaseEffectiveUnitRent";
import { LeaseRentFreePeriodSchema } from "./LeaseRentFreePeriod";
import { PlsDateSchema } from "./PlsDate";

export const OfficeLeaseUnitSchema = yup.object({
  leaseUnitID: yup.number().nullable(),
  effectiveDate: PlsDateSchema.nullable(),
  expiryDate: PlsDateSchema.nullable(),
  licenseStartDate: PlsDateSchema.nullable(),
  licenseEndDate: PlsDateSchema.nullable(),
  shopName: yup.string().nullable(),
  effectiveUnitRents: yup.array().of(LeaseEffectiveUnitRentSchema).nullable(),
  rentFreePeriods: yup.array().of(LeaseRentFreePeriodSchema).nullable(),
  faceRent: yup.number().nullable(),
});

export type OfficeLeaseUnit = yup.InferType<typeof OfficeLeaseUnitSchema>;

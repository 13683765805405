
import { defineComponent, PropType } from "vue";

export interface TextDialogConfig {
  title?: string;
  message?: string;
  buttons: {
    style?: {
      depressed?: boolean;
      outlined?: boolean;
    };
    disabled?: boolean;
    loading?: boolean;
    color?: string;
    text: string;
    action: () => void;
  }[];
}

export default defineComponent({
  name: "TextDialog",

  emits: ["dismiss"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object as PropType<TextDialogConfig>,
      required: true,
    },
  },
});

import { Module } from "vuex";
import { getKeyAttribute, UnitFacility } from "../models/LeaseCustomization";
import {
  getRecord,
  KeyAttribute,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { isNonNullable, Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

declare module "vuex" {
  interface Dispatch {
    (
      type: "stackingPlan/fillUnitFacilities",
      payload: UnitFacility[],
      options?: DispatchOptions
    ): Promise<unknown>;

    (
      type: "stackingPlan/updateUnitFacilities",
      payload: {
        keyAttributes: KeyAttribute[];
        unitFacilities: UnitFacility[];
      },
      options?: DispatchOptions
    ): Promise<unknown>;
  }
}

interface State {
  byKeyAttribute: RecordByKeyAttribute<UnitFacility[]>;
}

const getters = {
  getUnitFacilities:
    (state: State) =>
    (keyAttribute: KeyAttribute): Maybe<UnitFacility[]> => {
      return getRecord(state.byKeyAttribute, keyAttribute);
    },

  getUnitFacilitiesByKeyAttributes:
    (state: State) =>
    (keyAttributes: KeyAttribute[]): UnitFacility[] => {
      return keyAttributes
        .map((keyAttribute) => getRecord(state.byKeyAttribute, keyAttribute))
        .filter(isNonNullable)
        .reduce<UnitFacility[]>((prev, curr) => [...prev, ...curr], []);
    },
};

export const unitFacilityModule: Module<State, unknown> = {
  state: {
    byKeyAttribute: {},
  },

  getters,

  mutations: {
    fillUnitFacilities(state, payload: UnitFacility[]) {
      for (const unitFacility of payload) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          getKeyAttribute(unitFacility),
          (maybeUfs) => [...(maybeUfs ?? []), unitFacility]
        );
      }
    },

    updateUnitFacilities(
      state: State,
      payload: {
        keyAttributes: KeyAttribute[];
        unitFacilities: UnitFacility[];
      }
    ) {
      const { keyAttributes, unitFacilities } = payload;

      for (const keyAttribute of keyAttributes) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          keyAttribute,
          () => []
        );
      }

      for (const unitFacility of unitFacilities) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          getKeyAttribute(unitFacility),
          (maybeUfs) => [...(maybeUfs ?? []), unitFacility]
        );
      }
    },
  },

  actions: {
    fillUnitFacilities(context, payload: UnitFacility[]) {
      context.commit("fillUnitFacilities", payload);
    },

    updateUnitFacilities(
      context,
      payload: { keyAttributes: KeyAttribute[]; unitFacilities: UnitFacility[] }
    ) {
      context.commit("updateUnitFacilities", payload);
    },
  },

  modules: {},
};

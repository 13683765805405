import OSS from "ali-oss";
export type OSSConfig = {
  resolveFilePath: (fileName: string) => Promise<string>;
  upload: (file: File) => Promise<OSS.PutObjectResult>;
};

let _config: OSSConfig | null = null;

export function setupOSSConfig(config: OSSConfig) {
  _config = config;
}

export function downloadFile(fileName: string): Promise<string> {
  if (!_config) {
    throw new Error("OSSConfig is not set");
  }
  return _config.resolveFilePath(fileName);
}

export function uploadFile(file: File) {
  if (!_config) {
    throw new Error("OSSConfig is not set");
  }
  return _config.upload(file);
}

import { render, staticRenderFns } from "./BuildingRemarksUnsavedChangesDialog.vue?vue&type=template&id=0f875218&scoped=true&"
import script from "./BuildingRemarksUnsavedChangesDialog.vue?vue&type=script&lang=ts&"
export * from "./BuildingRemarksUnsavedChangesDialog.vue?vue&type=script&lang=ts&"
import style0 from "./BuildingRemarksUnsavedChangesDialog.vue?vue&type=style&index=0&id=0f875218&prod&lang=scss&"
import style1 from "./BuildingRemarksUnsavedChangesDialog.vue?vue&type=style&index=1&id=0f875218&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f875218",
  null
  
)

export default component.exports
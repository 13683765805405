import axios from "axios";
import { getToken } from "./auth";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 1000000,
});
// request拦截器
service.interceptors.request.use(
  async (config) => {
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // console.log('res.data', res.data)
    let customRes = {
      data: {
        code: res.status || res.data.errorCode,
        message: res.data.severity || "",
        codeSuccess: res.status == 200,
        result:
          res.data.list && Array.isArray(res.data.list)
            ? {
                content: res.data.list,
                totalElements: res.data.count,
                totalPages: res.data.page,
                size: res.data.limit,
                number: 0,
              }
            : res.data,
      },
    };

    // 未设置状态码则默认成功状态
    const code = res.status || customRes.data.code || 200;

    if (code === 401) {
      return Promise.reject(new Error("401"));
    } else if (code === 500) {
      return Promise.reject(new Error("500"));
    } else if (code !== 200) {
      return Promise.reject("error");
    } else {
      // return res.data;
      if (res.config.responseType == "blob") {
        return res.data;
      }
      // ddas 平台自定义的 返回状态码
      if (customRes.data.result) {
        // 请求成功
        return customRes.data;
      } else if (
        customRes.data.code == "12000001" ||
        customRes.data.code == "12000000" ||
        customRes.data.code == "20000004"
      ) {
        // console.log("request res", res);
        // 请求 token 过期/ 缺失
        // MessageBox.confirm(
        //   "登录状态已过期，您可以继续留在该页面，或者重新登录",
        //   "系统提示",
        //   {
        //     confirmButtonText: "重新登录",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   }
        // ).then(() => {
        //   store.dispatch("LogOut").then(() => {
        //     location.href = "/login";
        //   });
        // });
      } else {
        // let message = res.data.message;
        // Message({
        //   message: message,
        //   type: "error",
        //   duration: 5 * 1000,
        // });
      }
    }
  },
  (error) => {
    let data = error.response.data || {};
    let dateString = "";
    if (data && data.timestamp) {
      let a = new Date(data.timestamp).getTime();
      let date = new Date(a);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "  ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      // const s = date.getSeconds(); // 秒
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dateString = Y + M + D + h + m;
    }
    return Promise.reject(error);
  }
);

export default service;

import { Module } from "vuex";

import { Maybe } from "../types/Maybe";
import { Legend, LegendFilterType } from "../models/Legend";

interface State {
  byId: Record<Legend["id"], Legend>;
}

export const legendModule: Module<State, unknown> = {
  state: {
    byId: {},
  },
  getters: {
    getLegends: (state) => (): Legend[] => {
      return Object.values(state.byId);
    },
    getLegendByFilterType:
      (state) =>
      (filterType: LegendFilterType): Maybe<Legend> => {
        return Object.values(state.byId).find(
          (legend) => legend.filterType === filterType
        );
      },
  },
  mutations: {
    fillLegends(state, payload: Legend[]) {
      state.byId = payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.id]: curr,
        };
      }, state.byId);
    },
  },
  actions: {
    fillLegends(context, payload: Legend[]) {
      context.commit("fillLegends", payload);
    },
  },
  modules: {},
};


import { defineComponent } from "vue";

export default defineComponent({
  name: "EllipsisText",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ellipsis: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const textArea = this.$refs.textArea as HTMLElement;
      this.ellipsis = this.isTextOverflow(textArea);
    });
  },

  methods: {
    isTextOverflow(textArea: HTMLElement) {
      return textArea.offsetWidth < textArea.scrollWidth;
    },
  },
});


import { PropType, defineComponent } from "vue";
import { RemoteData, getOrElse, isSuccess } from "@devexperts/remote-data-ts";

import { Legend, LegendFilterGroup } from "../models/Legend";
import { deriveLegendUniqueKey } from "../utils/legend";

import IconFacilitiesSvg from "../assets/images/icon-facilities.svg";
import IconMinusSvg from "../assets/images/icon-minus.svg";
import IconPlusSvg from "../assets/images/icon-plus.svg";
import LegendFilterItem from "./LegendFilterItem.vue";

export default defineComponent({
  name: "LegendFilter",

  components: {
    IconFacilitiesSvg,
    IconMinusSvg,
    IconPlusSvg,
    LegendFilterItem,
  },

  props: {
    legendsRemoteData: {
      type: Object as PropType<RemoteData<Error, Legend[]>>,
      required: true,
    },
    selectedLegends: {
      type: Map as PropType<Map<string, Legend>>,
      required: true,
    },
  },

  emits: ["reset", "select"],

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    isLoading() {
      return !isSuccess(this.legendsRemoteData);
    },
    legends() {
      return getOrElse(() => [] as Legend[])(this.legendsRemoteData);
    },
    legendsByFilterGroup() {
      return this.legends
        .slice()
        .sort((a, b) => {
          return a.displaySeq - b.displaySeq;
        })
        .reduce((groups, legend) => {
          const { filterGroup } = legend;
          if (!(filterGroup in groups)) {
            groups[filterGroup] = [];
          }
          groups[filterGroup].push(legend);
          return groups;
        }, {} as Record<LegendFilterGroup, Legend[]>);
    },
    isResetButtonDisabled() {
      return this.selectedLegends.size === 0;
    },
  },

  methods: {
    collapse() {
      this.isExpanded = false;
    },
    expand() {
      this.isExpanded = true;
    },
    reset() {
      this.$emit("reset");
    },
    isLegendItemSelected(legend: Legend) {
      return this.selectedLegends.has(deriveLegendUniqueKey(legend));
    },
    clickLegendItem(legend: Legend) {
      this.$emit("select", legend);
    },
    deriveLegendUniqueKey,
  },
});

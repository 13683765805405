var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"opportunity-type-badge",class:{
    'opportunity-type-badge--renewal':
      _vm.opportunityType === _vm.OpportunityType.RENEWAL,
    'opportunity-type-badge--new-letting':
      _vm.opportunityType === _vm.OpportunityType.NEW_LETTING,
    'opportunity-type-badge--rent-review':
      _vm.opportunityType === _vm.OpportunityType.RENT_REVIEW,
    'opportunity-type-badge--extension':
      _vm.opportunityType === _vm.OpportunityType.EXTENSION,
    'opportunity-type-badge--relocation':
      _vm.opportunityType === _vm.OpportunityType.RELOCATION,
  }},[_vm._v(" "+_vm._s(_vm.label)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { defineComponent } from "vue";
import {
  RemoteData,
  initial,
  pending,
  success,
  failure,
  getOrElse,
  isPending,
  isSuccess,
  isFailure,
} from "@devexperts/remote-data-ts";

import {
  getBuildingStackingPlanXlsx,
  getMoRetailStackingPlanXlsx,
  getLegendList,
} from "../apis";
import {
  getFloorListByBuildingCode,
  getFloorListByMOCode,
} from "../apis/floor";
import { getMoList } from "../apis/mo";
import {
  getNegotiationsByBuildingCodeAndBuildingUnitIds,
  getNegotiationsByMOCodeAndBuildingUnitIds,
} from "../apis/negotiation";
import {
  getOfficeLeasesByBuildingCode,
  getRetailLeasesByMOCode,
} from "../apis/lease";
import {
  getLeaseCustomizations,
  LeaseCustomizationResult,
} from "../apis/leaseCustomization";
import {
  getPlsBuildingUnitLeaseListByBuildingCode,
  getPlsBuildingUnitLeaseListByMOCode,
} from "../apis/plsBuildingUnitLease";
import { getRetailLeaseCustomizationsByMOCode } from "../apis/retailLeaseCustomization";

import { Building } from "../models/Building";
import { Legend } from "../models/Legend";
import { MO } from "../models/MO";
import { Floor } from "../models/Floor";
import { getKeyAttribute, OfficeLease, RetailLease } from "../models/Lease";
import { Negotiation } from "../models/Negotiation";
import {
  RetailLeaseCustomization,
  mapRetailLeaseCustomizationToOfficeLeaseCustomization,
} from "../models/LeaseCustomization";
import { KeyAttribute } from "../types/LeaseKeyAttribute";
import {
  makeOfficeLeaseAndCustomizations,
  makeRetailLeaseAndCustomizations,
} from "../types/LeaseAndCustomization";
import { Maybe, isNonNullable } from "../types/Maybe";
import { ScrollData } from "../types/ScrollData";
import { isOfficeOrStreetBuilding } from "../utils/buildings";
import {
  getBuildingGrandTotalSummary,
  getMOGrandTotalSummary,
  getFoodLicenseSummary,
} from "../utils/summary";
import { deriveLegendUniqueKey } from "../utils/legend";
import { sum } from "../utils/sum";
import { unique } from "../utils/unique";
import {
  isVacant,
  aggregateFloorsAndLeaseFloors,
  makeFloorsFromLeases,
  isDraft,
  isOffice,
} from "../utils/lease";
import {
  getLastSelectedBuildingSelectOption,
  setLastSelectedBuildingSelectOption,
} from "../utils/cacheSelectedBuildingCode";

import ScrollBar from "../components/ScrollBar.vue";
import ResultUpdateSnackbar from "../components/ResultUpdateSnackbar.vue";
import BuildingGrandTotalSummary from "../components/BuildingGrandTotalSummary.vue";
import MOGrandTotalSummary from "../components/MOGrandTotalSummary.vue";
import FloorUnitTableOffice from "../components/FloorUnitTableOffice.vue";
import FloorUnitTableRetail from "../components/FloorUnitTableRetail.vue";
import {
  FloorUnitTableData,
  makeFloorUnitTableData,
} from "../components/FloorUnitTableOfficeViewModel";
import {
  BuildingFloorUnitTableData,
  makeBuildingFloorUnitTableData,
} from "../components/FloorUnitTableRetailViewModel";
import HeaderFilter from "../components/HeaderFilter.vue";
import LegendFilter from "../components/LegendFilter.vue";
import BuildingUnitDetailsDialog from "../components/BuildingUnitDetailsDialog.vue";
import {
  makeBuildingSelectOptionsFromMOList,
  SelectableOption,
  isSelectableOption,
} from "../components/BuildingSelectViewModel";
import { PlsBuildingUnitLease } from "@/models/PlsBuildingUnitLease";

export default defineComponent({
  name: "StackingPlan",

  components: {
    BuildingGrandTotalSummary,
    MOGrandTotalSummary,
    FloorUnitTableOffice,
    FloorUnitTableRetail,
    HeaderFilter,
    LegendFilter,
    BuildingUnitDetailsDialog,
    ScrollBar,
    ResultUpdateSnackbar,
  },

  data(): {
    showBuildingSummary: boolean;
    selectedLegends: Map<string, Legend>;
    floorsRemoteData: RemoteData<Error, Floor[]>;
    leaseCustomizationsRemoteData: RemoteData<Error, LeaseCustomizationResult>;
    retailLeaseCustomizationsRemoteData: RemoteData<
      Error,
      RetailLeaseCustomization[]
    >;
    leasesRemoteData: RemoteData<Error, OfficeLease[]>;
    legendsRemoteData: RemoteData<Error, Legend[]>;
    mosRemoteData: RemoteData<Error, MO[]>;
    negotiationsRemoteData: RemoteData<Error, Negotiation[]>;
    plsBuildingUnitLeasesRemoteData: RemoteData<Error, PlsBuildingUnitLease[]>;
    retailLeasesRemoteData: RemoteData<Error, RetailLease[]>;
    retailNegotiationsRemoteData: RemoteData<Error, Negotiation[]>;
    exportRemoteData: RemoteData<Error, Blob>;
    headerExpiryDateFromDate: Maybe<Date>;
    headerExpiryDateToDate: Maybe<Date>;
    headerIsFbOnly: Maybe<boolean>;
    headerSearchText: Maybe<string>;
    floorUnitTableScrollData: Maybe<ScrollData>;
  } {
    return {
      selectedLegends: new Map(),
      showBuildingSummary: false,
      floorsRemoteData: initial,
      leaseCustomizationsRemoteData: initial,
      retailLeaseCustomizationsRemoteData: initial,
      leasesRemoteData: initial,
      legendsRemoteData: initial,
      mosRemoteData: initial,
      negotiationsRemoteData: initial,
      plsBuildingUnitLeasesRemoteData: initial,
      retailLeasesRemoteData: initial,
      retailNegotiationsRemoteData: initial,
      exportRemoteData: initial,
      headerExpiryDateToDate: null,
      headerExpiryDateFromDate: null,
      headerIsFbOnly: null,
      headerSearchText: null,
      floorUnitTableScrollData: null,
    };
  },

  mounted() {
    this.fetchRemoteData();
  },

  methods: {
    isPending,

    async fetchRemoteData(): Promise<void> {
      await Promise.all([
        // remote data on view mounted
        this.fetchMos(),
        this.fetchLegends(),
      ]);
    },

    async fetchMos() {
      if (!this.$skp.can("PMP_OP:rSKP") && !this.$skp.can("PMP_OP:rRSKP")) {
        this.mosRemoteData = success([]);
        return;
      }
      this.mosRemoteData = pending;
      try {
        const mos = await getMoList();
        this.mosRemoteData = success(mos);
        this.recoverLastSelectedOption();
        for (const mo of mos) {
          this.$store.dispatch("stackingPlan/fillBuildings", mo.buildings);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.mosRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchLegends() {
      if (!this.$skp.can("PMP_OP:rSKP") && !this.$skp.can("PMP_OP:rRSKP")) {
        this.legendsRemoteData = success([]);
        return;
      }
      this.legendsRemoteData = pending;
      try {
        const legends = await getLegendList();
        this.$store.dispatch("stackingPlan/fillLegends", legends);
        this.legendsRemoteData = success(legends);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.legendsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchBuildingRemoteData(building: Building): Promise<void> {
      await Promise.all([
        this.fetchFloorsByBuildingCode(building.code),
        this.fetchLeases(building),
      ]);
    },

    async fetchRetailRemoteData(mo: MO): Promise<void> {
      await Promise.all([
        this.fetchFloorsByMOCode(mo.code),
        this.fetchRetailLeases(mo),
      ]);
    },

    async fetchFloorsByBuildingCode(buildingCode: string): Promise<void> {
      this.floorsRemoteData = pending;
      try {
        const floors = await getFloorListByBuildingCode(buildingCode);
        this.floorsRemoteData = success(floors);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.floorsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchFloorsByMOCode(moCode: string): Promise<void> {
      this.floorsRemoteData = pending;
      try {
        const floors = await getFloorListByMOCode(moCode);
        this.floorsRemoteData = success(floors);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.floorsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchLeases(building: Building): Promise<void> {
      const { code } = building;
      this.leasesRemoteData = pending;
      try {
        const leases = await getOfficeLeasesByBuildingCode(code);
        const buildingUnitIds = unique(leases.flatMap((l) => l.buildingUnitID));
        await Promise.all([
          this.fetchLeaseCustomizations(code),
          this.fetchRetailLeaseCustomizations(building.moCode, buildingUnitIds),
          this.fetchNegotiations(code, buildingUnitIds),
          this.fetchPlsBuildingUnitLeasesByBuildingCode(code, buildingUnitIds),
        ]);
        this.$store.dispatch("stackingPlan/fillOfficeLeases", leases);
        this.leasesRemoteData = success(leases);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.leasesRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchRetailLeases({ code }: MO): Promise<void> {
      this.retailLeasesRemoteData = pending;
      try {
        const leases = await getRetailLeasesByMOCode(code);
        const buildingUnitIds = unique(leases.flatMap((l) => l.buildingUnitID));
        await Promise.all([
          this.fetchRetailLeaseCustomizations(code, buildingUnitIds),
          this.fetchRetailNegotiations(code, buildingUnitIds),
          this.fetchPlsBuildingUnitLeasesByMOCode(code, buildingUnitIds),
        ]);
        this.$store.dispatch("stackingPlan/fillRetailLeases", leases);
        this.retailLeasesRemoteData = success(leases);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.retailLeasesRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchLeaseCustomizations(buildingCode: string) {
      try {
        const leaseCustomizationsResult = await getLeaseCustomizations(
          buildingCode
        );
        const { leaseCustomizations, unitFeatures, unitFacilities } =
          leaseCustomizationsResult;
        this.$store.dispatch(
          "stackingPlan/fillLeaseCustomizations",
          leaseCustomizations
        );
        this.$store.dispatch("stackingPlan/fillUnitFeatures", unitFeatures);
        this.$store.dispatch("stackingPlan/fillUnitFacilities", unitFacilities);
        this.leaseCustomizationsRemoteData = success(leaseCustomizationsResult);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.leaseCustomizationsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchRetailLeaseCustomizations(
      moCode: string,
      plsBuildingUnitIds: number[]
    ) {
      if (!this.$skp.can("PMP_OP:rRSKP")) {
        this.retailLeaseCustomizationsRemoteData = success([]);
        return;
      }
      try {
        const retailLeaseCustomizations =
          await getRetailLeaseCustomizationsByMOCode(
            moCode,
            plsBuildingUnitIds
          );
        this.$store.dispatch(
          "stackingPlan/fillRetailLeaseCustomizations",
          retailLeaseCustomizations
        );
        this.retailLeaseCustomizationsRemoteData = success(
          retailLeaseCustomizations
        );
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.leaseCustomizationsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchNegotiations(buildingCode: string, buildingIds: number[]) {
      if (!this.$skp.can("PMP_OP:rSKPNEG")) {
        this.negotiationsRemoteData = success([]);
        return;
      }
      try {
        const negotiations =
          await getNegotiationsByBuildingCodeAndBuildingUnitIds(
            buildingCode,
            buildingIds
          );
        this.$store.dispatch("stackingPlan/fillNegotiations", negotiations);
        this.negotiationsRemoteData = success(negotiations);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.negotiationsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchRetailNegotiations(moCode: string, buildingIds: number[]) {
      this.retailNegotiationsRemoteData = pending;
      if (!this.$skp.can("PMP_OP:rRSKPNEG")) {
        this.retailNegotiationsRemoteData = success([]);
        return;
      }
      try {
        const negotiations = await getNegotiationsByMOCodeAndBuildingUnitIds(
          moCode,
          buildingIds
        );
        this.$store.dispatch("stackingPlan/fillNegotiations", negotiations);
        this.retailNegotiationsRemoteData = success(negotiations);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.retailNegotiationsRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchPlsBuildingUnitLeasesByBuildingCode(
      buildingCode: string,
      buildingUnitIds: number[]
    ) {
      const building =
        this.$store.getters["stackingPlan/getBuildingByBuildingCode"](
          buildingCode
        );
      if (!building?.canReadUnitRemarks) {
        this.plsBuildingUnitLeasesRemoteData = success([]);
        return;
      }
      try {
        const plsBuildingUnitLeases =
          await getPlsBuildingUnitLeaseListByBuildingCode(
            buildingCode,
            buildingUnitIds
          );
        this.$store.dispatch(
          "stackingPlan/fillPlsBuildingUnitLeases",
          plsBuildingUnitLeases
        );
        this.plsBuildingUnitLeasesRemoteData = success(plsBuildingUnitLeases);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.plsBuildingUnitLeasesRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchPlsBuildingUnitLeasesByMOCode(
      moCode: string,
      buildingUnitIds: number[]
    ) {
      if (!this.$skp.can("PMP_OP:rRSKPRemark")) {
        this.plsBuildingUnitLeasesRemoteData = success([]);
        return;
      }
      try {
        const plsBuildingUnitLeases = await getPlsBuildingUnitLeaseListByMOCode(
          moCode,
          buildingUnitIds
        );
        this.$store.dispatch(
          "stackingPlan/fillPlsBuildingUnitLeases",
          plsBuildingUnitLeases
        );
        this.plsBuildingUnitLeasesRemoteData = success(plsBuildingUnitLeases);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.plsBuildingUnitLeasesRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchBuildingExport(building: Building) {
      if (isPending(this.exportRemoteData)) {
        return;
      }
      this.exportRemoteData = pending;
      try {
        const blob = await getBuildingStackingPlanXlsx(building.code);
        const fileName = `${building.name} - Stacking Plan.xlsx`;
        this.exportRemoteData = success(blob);
        this.triggerBlobDownload(blob, fileName);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.exportRemoteData = failure(e);
        }
        throw e;
      }
    },

    async fetchMoExport(mo: MO) {
      if (isPending(this.exportRemoteData)) {
        return;
      }
      this.exportRemoteData = pending;
      try {
        const blob = await getMoRetailStackingPlanXlsx(mo.code);
        const fileName = `${mo.name} - Retail Stacking Plan.xlsx`;
        this.exportRemoteData = success(blob);
        this.triggerBlobDownload(blob, fileName);
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.exportRemoteData = failure(e);
        }
        throw e;
      }
    },

    updateSelectedBuildingSelectOption(
      option: SelectableOption,
      args?: { reset: boolean }
    ) {
      const { type, itemValue } = option;
      const { reset = false } = args ?? {};
      const query = reset ? {} : this.$route.query;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...query,
          type,
          code: itemValue,
        },
      });
      this.rememberLastSelectedOption(option);
    },

    handleBuildingSelectSelected(option: SelectableOption) {
      this.updateSelectedBuildingSelectOption(option, { reset: true });
      this.showBuildingSummary = false;
    },

    handleExportClick(option: SelectableOption) {
      switch (option.type) {
        case "building":
          this.fetchBuildingExport(option.building);
          break;
        case "retail":
          this.fetchMoExport(option.mo);
          break;
      }
    },

    handleSearchClick(payload: {
      expiryDateFromDate: Date | null;
      expiryDateToDate: Date | null;
      isFbOnly: boolean | null;
      searchText: string;
    }) {
      this.headerExpiryDateFromDate = payload.expiryDateFromDate;
      this.headerExpiryDateToDate = payload.expiryDateToDate;
      this.headerIsFbOnly = payload.isFbOnly;
      this.headerSearchText = payload.searchText;
    },

    handleViewSummaryClick() {
      this.showBuildingSummary = !this.showBuildingSummary;
    },

    handleLegendFilterReset() {
      this.selectedLegends = new Map();
    },

    handleLegendSelect(legend: Legend) {
      const legends = new Map(this.selectedLegends);
      const key = deriveLegendUniqueKey(legend);
      if (legends.has(key)) {
        legends.delete(key);
      } else {
        legends.set(key, legend);
      }
      this.selectedLegends = legends;
    },

    handleFloorUnitTableUnitClick(payload: {
      buildingCode: Maybe<string>;
      buildingUnitId: Maybe<number>;
      leaseUnitId: Maybe<number>;
      effectiveUnitRentStartDate: Maybe<Date>;
    }) {
      const {
        buildingCode,
        buildingUnitId,
        leaseUnitId,
        effectiveUnitRentStartDate,
      } = payload;
      const query = {
        ...(buildingCode != null ? { buildingCode } : {}),
        ...(buildingUnitId != null ? { buildingUnitId } : {}),
        ...(leaseUnitId != null ? { leaseUnitId } : {}),
        ...(effectiveUnitRentStartDate != null
          ? {
              effectiveUnitRentStartDate:
                effectiveUnitRentStartDate.toISOString(),
            }
          : {}),
      };
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },

    handleFloorUnitTableScroll(payload: ScrollData) {
      this.floorUnitTableScrollData = payload;
    },

    handleScrollBarDragHorizontal(left: number) {
      this.floorUnitTableRef?.scrollX(left);
    },

    handleScrollBarDragVertical(top: number) {
      this.floorUnitTableRef?.scrollY(top);
    },

    handleBuildingUnitDetailsDialogCloseClick() {
      const {
        buildingCode, // eslint-disable-line @typescript-eslint/no-unused-vars
        buildingUnitId, // eslint-disable-line @typescript-eslint/no-unused-vars
        leaseUnitId, // eslint-disable-line @typescript-eslint/no-unused-vars
        effectiveUnitRentStartDate, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...query
      } = this.$route.query;
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },
    recoverLastSelectedOption() {
      let { type, code } = this.$route.query;
      if (!type || !code) {
        const lastSelectedBuildingSelectOption =
          getLastSelectedBuildingSelectOption();
        if (lastSelectedBuildingSelectOption) {
          type = lastSelectedBuildingSelectOption.type;
          code = lastSelectedBuildingSelectOption.code;
        }
      }
      const maybeSelectedOption = this.selectableBuildingSelectOptions.find(
        (buildingSelectOption) =>
          buildingSelectOption.type === type &&
          buildingSelectOption.itemValue === code
      );
      if (maybeSelectedOption) {
        this.updateSelectedBuildingSelectOption(maybeSelectedOption);
      }
    },
    rememberLastSelectedOption(option: SelectableOption) {
      setLastSelectedBuildingSelectOption({
        type: option.type,
        code: option.itemValue,
      });
    },
    triggerBlobDownload(blob: Blob, fileName: string) {
      const downloader = this.$refs.downloader as HTMLAnchorElement;
      if (downloader == null) {
        return;
      }
      downloader.href = window.URL.createObjectURL(blob);
      downloader.download = fileName;
      downloader.click();
    },
  },

  computed: {
    buildingSelectOptions() {
      return makeBuildingSelectOptionsFromMOList(
        getOrElse(() => [] as MO[])(this.mosRemoteData)
      );
    },

    selectableBuildingSelectOptions() {
      return this.buildingSelectOptions.filter(isSelectableOption);
    },

    resultUpdateSnackbar() {
      return this.$store.state.stackingPlan.global.resultUpdateSnackbar;
    },

    floorUnitTableOfficeRemoteData(): RemoteData<Error, FloorUnitTableData> {
      if (this.selectedBuilding == null) {
        return initial;
      }
      if (isFailure(this.floorsRemoteData)) {
        return this.floorsRemoteData;
      }
      if (isFailure(this.leasesRemoteData)) {
        return this.leasesRemoteData;
      }
      if (isFailure(this.legendsRemoteData)) {
        return this.legendsRemoteData;
      }
      if (isFailure(this.leaseCustomizationsRemoteData)) {
        return this.leaseCustomizationsRemoteData;
      }
      if (isFailure(this.retailLeaseCustomizationsRemoteData)) {
        return this.retailLeaseCustomizationsRemoteData;
      }
      if (isFailure(this.negotiationsRemoteData)) {
        return this.negotiationsRemoteData;
      }
      if (isFailure(this.plsBuildingUnitLeasesRemoteData)) {
        return this.plsBuildingUnitLeasesRemoteData;
      }
      if (
        !(
          isSuccess(this.floorsRemoteData) &&
          isSuccess(this.leasesRemoteData) &&
          isSuccess(this.legendsRemoteData) &&
          isSuccess(this.leaseCustomizationsRemoteData) &&
          isSuccess(this.retailLeaseCustomizationsRemoteData) &&
          isSuccess(this.negotiationsRemoteData) &&
          isSuccess(this.plsBuildingUnitLeasesRemoteData) &&
          true
        )
      ) {
        return pending;
      }
      const allLeases: OfficeLease[] = this.$store.getters[
        "stackingPlan/getLeasesByBuildingId"
      ](this.selectedBuilding.plsBuildingId);
      const leases = allLeases;
      const customizations = leases
        .map((lease) => {
          const customization = this.$store.getters[
            "stackingPlan/getLeaseCustomization"
          ](getKeyAttribute(lease));
          if (customization) {
            return customization;
          }
          const retailCustomization = this.$store.getters[
            "stackingPlan/getRetailLeaseCustomization"
          ](getKeyAttribute(lease));
          if (retailCustomization) {
            return mapRetailLeaseCustomizationToOfficeLeaseCustomization(
              retailCustomization
            );
          }
          return null;
        })
        .filter(isNonNullable);

      const floors = aggregateFloorsAndLeaseFloors(
        this.floorsRemoteData.value,
        [this.selectedBuilding],
        makeFloorsFromLeases(leases)
      );
      const data = makeFloorUnitTableData({
        floors,
        leases,
        leaseAndCustomizations: makeOfficeLeaseAndCustomizations(
          leases,
          customizations
        ),
        legends: this.legendsRemoteData.value,
        getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId:
          this.$store.getters["stackingPlan/getPlsBuildingUnitLease"],
        getNegotiationsByBuildingUnitId:
          this.$store.getters["stackingPlan/getNegotiations"],
        getUnitFacilitiesByLeaseUnitId:
          this.$store.getters["stackingPlan/getUnitFacilities"],
        filtering: {
          expiryDateFromDate: this.headerExpiryDateFromDate,
          expiryDateToDate: this.headerExpiryDateToDate,
          isFbOnly: this.headerIsFbOnly,
          searchText: this.headerSearchText,
          selectedLegends: this.selectedLegends,
        },
      });
      return success(data);
    },

    floorUnitTableRetailRemoteData(): RemoteData<
      Error,
      BuildingFloorUnitTableData
    > {
      if (this.selectedRetail == null) {
        return initial;
      }
      if (isFailure(this.floorsRemoteData)) {
        return this.floorsRemoteData;
      }
      if (isFailure(this.retailLeasesRemoteData)) {
        return this.retailLeasesRemoteData;
      }
      if (isFailure(this.legendsRemoteData)) {
        return this.legendsRemoteData;
      }
      if (isFailure(this.retailLeaseCustomizationsRemoteData)) {
        return this.retailLeaseCustomizationsRemoteData;
      }
      if (isFailure(this.retailNegotiationsRemoteData)) {
        return this.retailNegotiationsRemoteData;
      }
      if (
        !(
          isSuccess(this.floorsRemoteData) &&
          isSuccess(this.retailLeasesRemoteData) &&
          isSuccess(this.legendsRemoteData) &&
          isSuccess(this.retailLeaseCustomizationsRemoteData) &&
          isSuccess(this.retailNegotiationsRemoteData) &&
          true
        )
      ) {
        return pending;
      }
      const buildings = this.selectedRetail.buildings
        .filter((x) => x.canReadRetailStackingPlan)
        .filter(isOfficeOrStreetBuilding)
        .filter((x) => x.active);
      const buildingIdSet = new Set(
        buildings.flatMap((building) => building.plsBuildingId)
      );
      const allLeases: RetailLease[] = this.$store.getters[
        "stackingPlan/getRetailLeasesByMoCode"
      ](this.selectedRetail.code);
      const leases = allLeases.filter((lease) =>
        buildingIdSet.has(lease.buildingID)
      );
      const customizations = leases
        .map((lease) =>
          this.$store.getters["stackingPlan/getRetailLeaseCustomization"](
            getKeyAttribute(lease)
          )
        )
        .filter(isNonNullable);
      const floors = aggregateFloorsAndLeaseFloors(
        this.floorsRemoteData.value,
        this.selectedRetail.buildings,
        makeFloorsFromLeases(leases)
      );
      const data = makeBuildingFloorUnitTableData({
        buildings,
        floors,
        leases,
        leaseAndCustomizations: makeRetailLeaseAndCustomizations(
          leases,
          customizations
        ),
        legends: this.legendsRemoteData.value,
        getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId:
          this.$store.getters["stackingPlan/getPlsBuildingUnitLease"],
        getNegotiationsByBuildingUnitId:
          this.$store.getters["stackingPlan/getNegotiations"],
        filtering: {
          expiryDateFromDate: this.headerExpiryDateFromDate,
          expiryDateToDate: this.headerExpiryDateToDate,
          isFbOnly: this.headerIsFbOnly,
          searchText: this.headerSearchText,
          selectedLegends: this.selectedLegends,
        },
      });
      return success(data);
    },

    selectedBuildingSelectOption(): Maybe<SelectableOption> {
      const { type, code } = this.$route.query;
      return this.selectableBuildingSelectOptions.find(
        (buildingSelectOption) =>
          buildingSelectOption.type === type &&
          buildingSelectOption.itemValue === code
      );
    },

    selectedBuilding(): Maybe<Building> {
      return this.selectedBuildingSelectOption?.type === "building"
        ? this.selectedBuildingSelectOption.building
        : null;
    },

    selectedRetail(): Maybe<MO> {
      return this.selectedBuildingSelectOption?.type === "retail"
        ? this.selectedBuildingSelectOption.mo
        : null;
    },

    buildingUnitDetailsDialogData(): {
      isOpen: boolean;
      data?: {
        kind: "office" | "retail";
        moCode: string;
        buildingCode: string;
        buildingUnitId: number;
        keyAttribute: KeyAttribute;
        building: Building;
      };
    } {
      const {
        type,
        code,
        buildingCode: buildingCodeQuery,
        buildingUnitId,
        leaseUnitId = "",
        effectiveUnitRentStartDate = "",
      } = this.$route.query;
      const moCode = type === "retail" ? code : this.selectedBuilding?.moCode;
      const buildingCode = type === "building" ? code : buildingCodeQuery;
      if (buildingCode == null || buildingUnitId == null || moCode == null) {
        return {
          isOpen: false,
        };
      }
      const building = this.$store.getters[
        "stackingPlan/getBuildingByBuildingCode"
      ](String(buildingCode));
      if (!building) {
        return {
          isOpen: false,
        };
      }
      return {
        isOpen: true,
        data: {
          kind: type === "building" ? "office" : "retail",
          moCode: String(moCode),
          buildingCode: String(buildingCode),
          buildingUnitId: parseInt(String(buildingUnitId), 10),
          keyAttribute: {
            plsBuildingUnitId: parseInt(String(buildingUnitId), 10),
            plsLeaseUnitId: String(leaseUnitId),
            effectiveUnitRentStartDateStr: String(effectiveUnitRentStartDate),
          },
          building,
        },
      };
    },

    buildingGrandTotalSummary() {
      if (
        !isSuccess(this.legendsRemoteData) ||
        !isSuccess(this.leasesRemoteData) ||
        !isSuccess(this.leaseCustomizationsRemoteData) ||
        this.selectedBuilding == null
      ) {
        return null;
      }
      const allLeases = this.$store.getters[
        "stackingPlan/getLeasesByBuildingId"
      ](this.selectedBuilding.plsBuildingId);
      const leases = allLeases.filter(
        (lease) => isOffice(lease) || isVacant(lease)
      );
      const leaseCustomizations = leases
        .map((lease: OfficeLease) => {
          return this.$store.getters["stackingPlan/getLeaseCustomization"](
            getKeyAttribute(lease)
          );
        })
        .filter(isNonNullable);
      const otherBuildingArea =
        this.selectedBuilding.stackingPlanOtherBuildingArea ?? 0;
      return getBuildingGrandTotalSummary(
        leases,
        leaseCustomizations,
        this.legendsRemoteData.value,
        otherBuildingArea
      );
    },

    mOGrandTotalSummary() {
      if (
        !isSuccess(this.legendsRemoteData) ||
        !isSuccess(this.retailLeasesRemoteData) ||
        !isSuccess(this.retailLeaseCustomizationsRemoteData) ||
        this.selectedRetail == null
      ) {
        return null;
      }
      const buildings = this.selectedRetail.buildings
        .filter((x) => x.canReadRetailStackingPlan)
        .filter(isOfficeOrStreetBuilding)
        .filter((x) => x.active);
      const buildingIdSet = new Set(
        buildings.flatMap((building) => building.plsBuildingId)
      );
      const allLeases = this.$store.getters[
        "stackingPlan/getRetailLeasesByMoCode"
      ](this.selectedRetail.code);
      const leases = allLeases.filter((lease) =>
        buildingIdSet.has(lease.buildingID)
      );
      const leaseCustomizations = leases
        .map((lease) => {
          return this.$store.getters[
            "stackingPlan/getRetailLeaseCustomization"
          ](getKeyAttribute(lease));
        })
        .filter(isNonNullable);
      const otherBuildingArea = sum(
        this.selectedRetail.buildings.map(
          (x) => x.stackingPlanOtherBuildingArea ?? 0
        )
      );
      return getMOGrandTotalSummary(
        leases,
        leaseCustomizations,
        this.legendsRemoteData.value,
        otherBuildingArea
      );
    },

    foodLicenseSummaries() {
      if (
        !isSuccess(this.legendsRemoteData) ||
        !isSuccess(this.retailLeasesRemoteData) ||
        !isSuccess(this.retailLeaseCustomizationsRemoteData) ||
        this.selectedRetail == null
      ) {
        return null;
      }

      const buildings = this.selectedRetail.buildings
        .filter(isOfficeOrStreetBuilding)
        .filter((x) => x.active);

      const streetBuildings = new Map<number, Building>();
      for (const building of buildings) {
        if (building.isStreetBuilding) {
          for (const plsBuildingId of building.plsBuildingId) {
            streetBuildings.set(plsBuildingId, building);
          }
        }
      }

      const allLeases = this.$store.getters[
        "stackingPlan/getRetailLeasesByMoCode"
      ](this.selectedRetail.code);
      const leases = allLeases;

      const buildingLevelLeases = leases.filter(
        (lease) => !streetBuildings.has(lease.buildingID)
      );
      const buildingLevelLeaseCustomizations = buildingLevelLeases
        .map((lease) => {
          return this.$store.getters[
            "stackingPlan/getRetailLeaseCustomization"
          ](getKeyAttribute(lease));
        })
        .filter(isNonNullable);
      const buildingLevelOtherBuildingArea = sum(
        this.selectedRetail.buildings
          .filter((x) => !x.isStreetBuilding)
          .map((x) => x.stackingPlanOtherBuildingArea ?? 0)
      );

      const streetLevelLeases = leases.filter((lease) =>
        streetBuildings.has(lease.buildingID)
      );
      const streetLevelLeaseCustomizations = streetLevelLeases
        .map((lease) => {
          return this.$store.getters[
            "stackingPlan/getRetailLeaseCustomization"
          ](getKeyAttribute(lease));
        })
        .filter(isNonNullable);
      const streetLevelOtherBuildingArea = sum(
        this.selectedRetail.buildings
          .filter((x) => x.isStreetBuilding)
          .map((x) => x.stackingPlanOtherBuildingArea ?? 0)
      );

      return {
        buildingLevel: getFoodLicenseSummary(
          buildingLevelLeases,
          buildingLevelLeaseCustomizations,
          this.legendsRemoteData.value,
          buildingLevelOtherBuildingArea
        ),
        streetLevel: getFoodLicenseSummary(
          streetLevelLeases,
          streetLevelLeaseCustomizations,
          this.legendsRemoteData.value,
          streetLevelOtherBuildingArea
        ),
      };
    },

    floorUnitTableRef() {
      if (this.selectedRetail) {
        return this.$refs.floorUnitTableRetail;
      }
      return this.$refs.floorUnitTableOffice;
    },
  },

  watch: {
    selectedBuilding: {
      handler(selectedBuilding: null | Building, oldValue: null | Building) {
        if (
          selectedBuilding != null &&
          selectedBuilding.code != oldValue?.code
        ) {
          this.fetchBuildingRemoteData(selectedBuilding);
        }
      },
      immediate: true,
    },

    selectedRetail: {
      handler(selectedRetail: Maybe<MO>, oldValue: Maybe<MO>) {
        if (selectedRetail != null && selectedRetail.code != oldValue?.code) {
          this.fetchRetailRemoteData(selectedRetail);
        }
      },
      immediate: true,
    },
  },
});

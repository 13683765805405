import * as yup from "yup";
import { Vue } from "vue/types/vue";
import { Invalid, Valid, Validate } from "../types/Validate";

const messageMappers = [
  {
    regex: /maxLength:(\d+)/,
    messageLocalizer:
      ([, max]: RegExpExecArray) =>
      (t: Vue["$skp"]["t"]) => {
        return t("validation.common.maxLength", max);
      },
  },
];

const defaultMessageLocalizer = (message: string) => () => message;

export function getValidationMessageLocalizer(message: string) {
  for (const messageMapper of messageMappers) {
    const matched = messageMapper.regex.exec(message);
    if (matched) {
      return messageMapper.messageLocalizer(matched);
    }
  }
  return defaultMessageLocalizer(message);
}

export function validate<Schema extends yup.AnySchema>(
  schema: Schema,
  value: unknown
): Validate<yup.InferType<Schema>, yup.ValidationError> {
  try {
    const data = schema.validateSync(value, { abortEarly: false });
    return Valid(data);
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      return Invalid(e);
    }
    throw e;
  }
}

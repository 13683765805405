import { VueConstructor } from "vue";
import VueI18n, { LocaleMessageObject } from "vue-i18n";

import en from "./lang/en.json";
import zhcn from "./lang/zhcn.json";
import zhtw from "./lang/zhtw.json";

const wrapObjectKey = (
  key: string,
  m: LocaleMessageObject | Record<string, LocaleMessageObject>
): Record<string, LocaleMessageObject> => {
  return {
    [key]: m,
  };
};

const wrapObjectKeys = (
  keys: string[],
  m: LocaleMessageObject | Record<string, LocaleMessageObject>
): LocaleMessageObject | Record<string, LocaleMessageObject> => {
  const [key, ...rest] = keys;
  return key != null ? wrapObjectKey(key, wrapObjectKeys(rest, m)) : m;
};

export const setupLocales = (i18n: VueI18n, Vue: VueConstructor): void => {
  const root = "$vuetify"; // Root locale key, as in parent repo
  const stackingPlan = "stackingPlan";
  i18n.mergeLocaleMessage("en", wrapObjectKeys([root, stackingPlan], en));
  i18n.mergeLocaleMessage("zhcn", wrapObjectKeys([root, stackingPlan], zhcn));
  i18n.mergeLocaleMessage("zhtw", wrapObjectKeys([root, stackingPlan], zhtw));
  Vue.prototype.$skp = Vue.prototype.$skp || {};
  Vue.prototype.$skp.t = (
    key: VueI18n.Path,
    ...values: unknown[]
  ): VueI18n.TranslateResult => {
    return i18n.t(`$vuetify.stackingPlan.${key}`, values);
  };
};

import { RetailLeaseAndCustomization } from "../types/LeaseAndCustomization";
import { Maybe } from "../types/Maybe";
import {
  TableFilter,
  matchExpiryFilter,
  matchLegendFilter,
  matchSearchFilter,
} from "../types/TableFilter";
import { Building } from "../models/Building";
import { Floor } from "../models/Floor";
import { Lease, RetailLease, getKeyAttribute } from "../models/Lease";
import { getCustomizedCof } from "../models/LeaseCustomization";
import { LeaseRentReview } from "../models/LeaseRentReview";
import {
  Legend,
  LegendFilterGroup,
  LegendIconType,
  findLegendByZone,
} from "../models/Legend";
import { Negotiation } from "../models/Negotiation";
import { CellData, makeCellData, makeNoneCellData } from "../utils/cell";
import { buildComparison } from "../utils/compare";
import {
  TurnoverRate,
  isVacant,
  getVacantFutureFlag,
  getFutureFlag,
  getRentReviewFlag,
  getUnitArea,
  getRentReviews,
  getCurrentTurnoverRates,
  getCof,
  getRflp,
  getRflpFromLeaseCustomization,
  mapRetailLeaseCustomizationTurnoverRateToTurnoverRate,
  matchLegendForColor,
} from "../utils/lease";
import { tintColor } from "../utils/color";
import { sum } from "../utils/sum";
import { Getters } from "vue/types/vue";
import { compareBuildingsByIsStreetBuildingAndDisplaySeq } from "../utils/buildings";

export type BuildingFloorUnitTableData = BuildingSectionData[];

type BuildingSectionData = {
  $meta: {
    buildingId: number;
  };
  building: {
    name: {
      name: string;
      nameHk: string;
      nameCn: string;
    };
  };
  floors: FloorRowData[];
};

type FloorRowData = {
  $meta: {
    floorId: number;
    background?: string;
  };
  floor: {
    name: string;
    area: number;
  };
  units: UnitRowData[];
};

type UnitRowData = {
  $meta: {
    key: string;
    buildingCode: string;
    buildingUnitId: number;
    leaseUnitId: Maybe<number>;
    effectiveUnitRentStartDate: Maybe<Date>;
    isFuture: boolean;
    isRentReview: boolean;
    background?: string;
    highlighterColor?: string;
  };
  unitNames: CellData<string[]>;
  excludeInSummary: CellData<boolean>;
  unitArea: CellData<number>;
  tenant: CellData<
    | { type: "sole"; name: string }
    | { type: "last"; name: Maybe<string>; leaseExpiry: Maybe<Date> }
  >;
  fb: CellData<boolean>;
  foodLicense: CellData<string>;
  rentPerMonth: CellData<number>;
  turnoverRates: CellData<TurnoverRate[]>;
  psf: CellData<number>;
  rf: CellData<Maybe<string>>;
  lp: CellData<Maybe<string>>;
  effPsf: CellData<number>;
  rentReviews: CellData<LeaseRentReview[]>;
  commencementDate: CellData<Date>;
  expiryDate: CellData<Date>;
  remarks: CellData<{
    unitRemarks: Maybe<string>;
    negotiations: Negotiation[];
  }>;
  cof: CellData<{
    c: boolean;
    o: boolean;
    f: boolean;
  }>;
};

type MakeContext = {
  buildings: Building[];
  floors: Floor[];
  leases: RetailLease[];
  leaseAndCustomizations: RetailLeaseAndCustomization[];
  legends: Legend[];
  getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId: Getters["stackingPlan/getPlsBuildingUnitLease"];
  getNegotiationsByBuildingUnitId: (buildingUnitId: number) => Negotiation[];
  filtering: TableFilter;
};

export function makeBuildingFloorUnitTableData(
  args: MakeContext
): BuildingFloorUnitTableData {
  return args.buildings
    .sort(compareBuildingsByIsStreetBuildingAndDisplaySeq)
    .flatMap((building) => makeBuildingSectionData(building, args));
}

function makeBuildingSectionData(
  building: Building,
  args: MakeContext
): [] | [BuildingSectionData] {
  const floorsSorted = args.floors
    .filter((floor) => floor.buildingCode === building.code)
    .sort((a, b) => {
      return a.displaySeq === b.displaySeq
        ? b.id - a.id
        : b.displaySeq - a.displaySeq;
    });
  const floorsSortedPLSBuildingFloorIds = floorsSorted.map(
    (floor) => floor.plsBuildingFloorId
  );
  const leasesSorted = args.leaseAndCustomizations
    .filter(({ lease }) => building.plsBuildingId.includes(lease.buildingID))
    .sort(({ lease: a }, { lease: b }) => {
      return buildComparison()
        .append(
          floorsSortedPLSBuildingFloorIds.indexOf(a.buildingFloorID),
          floorsSortedPLSBuildingFloorIds.indexOf(b.buildingFloorID)
        )
        .append(b.buildingUnitNo, a.buildingUnitNo)
        .append(a.leaseUnit?.leaseUnitID, b.leaseUnit?.leaseUnitID)
        .append(
          a.leaseUnit?.effectiveUnitRent?.startDate,
          b.leaseUnit?.effectiveUnitRent?.startDate
        )
        .result();
    });

  const floors = floorsSorted.flatMap((floor) =>
    makeFloorRowData(building, floor, {
      ...args,
      leaseAndCustomizations: leasesSorted,
    })
  );
  return floors.length > 0
    ? [
        {
          $meta: {
            buildingId: building.id,
          },
          building: {
            name: {
              name: building.name,
              nameHk: building.nameHk,
              nameCn: building.nameCn,
            },
          },
          floors,
        },
      ]
    : [];
}

function makeFloorRowData(
  building: Building,
  floor: Floor,
  args: MakeContext
): [] | [FloorRowData] {
  const unitAreaByUnitId: Record<number, number> = args.leaseAndCustomizations
    .filter(({ lease: x }) => x.buildingFloorID === floor.plsBuildingFloorId)
    .reduce(
      (dict, { lease: x }) => ({ ...dict, [x.buildingUnitID]: getUnitArea(x) }),
      {}
    );
  const floorArea = sum(Object.values(unitAreaByUnitId));
  const units = args.leaseAndCustomizations
    .filter(({ lease }) => lease.buildingFloorID === floor.plsBuildingFloorId)
    .flatMap((leaseAndCustomization) =>
      makeUnitRowData(building, floor, leaseAndCustomization, args)
    );
  return units.length > 0
    ? [
        {
          $meta: {
            floorId: floor.id,
            background: findLegendByZone(args.legends, floor.zone)?.color,
          },
          floor: {
            name: floor.floorName,
            area: floorArea,
          },
          units,
        },
      ]
    : [];
}

function makeUnitRowData(
  building: Building,
  floor: Floor,
  leaseAndCustomization: RetailLeaseAndCustomization,
  args: MakeContext
): [] | [UnitRowData] {
  const {
    leases,
    getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId,
    getNegotiationsByBuildingUnitId,
  } = args;
  const { lease, customization: leaseCustomization } = leaseAndCustomization;
  // No tenant with leaseUnit means non-tenant
  // No tenant without leaseUnit means new unit without history
  const { tenant: maybeTenant, leaseUnit: maybeLeaseUnit } = lease;
  if (maybeTenant == null && maybeLeaseUnit != null) {
    return [];
  }

  if (args.filtering.searchText) {
    if (
      !matchSearchFilter(
        args.filtering.searchText,
        maybeLeaseUnit?.leaseShop?.shopName ?? ""
      ) &&
      !matchSearchFilter(
        args.filtering.searchText,
        leaseCustomization?.tenantName ?? ""
      )
    ) {
      return [];
    }
  }

  if (args.filtering.expiryDateFromDate || args.filtering.expiryDateToDate) {
    if (
      !matchExpiryFilter(
        args.filtering.expiryDateFromDate,
        args.filtering.expiryDateToDate,
        { lease }
      )
    ) {
      return [];
    }
  }

  const selectedLegends = [...args.filtering.selectedLegends.values()];
  if (args.filtering.selectedLegends.size > 0) {
    const flag = [
      LegendFilterGroup.STATUS,
      LegendFilterGroup.ZONE,
      LegendFilterGroup.CALCULATION,
    ].every((filterGroup) => {
      const selectedLegendsInFilterGroup = selectedLegends.filter(
        (legend) => legend.filterGroup === filterGroup
      );
      if (selectedLegendsInFilterGroup.length === 0) {
        return true;
      }
      return selectedLegendsInFilterGroup.some((legend) =>
        matchLegendFilter(
          legend,
          {
            lease,
            leaseCustomization,
            leases,
            floorZone: floor.zone,
          },
          "retail"
        )
      );
    });
    if (!flag) {
      return [];
    }
  }

  if (args.filtering.isFbOnly != null) {
    if (
      // No customization but is fb only
      (!leaseCustomization && args.filtering.isFbOnly) ||
      // Has customization but isfbonly not match with fb of lease customization
      (leaseCustomization &&
        !!leaseCustomization.foodLicense !== args.filtering.isFbOnly)
    ) {
      return [];
    }
  }

  const maybeLegend = matchLegendForColor(
    args.legends,
    {
      lease,
      leaseCustomization,
      leases,
      floorZone: floor.zone,
    },
    "retail",
    selectedLegends
  );
  const isFuture = isVacant(lease)
    ? getVacantFutureFlag(lease)
    : getFutureFlag(lease);
  const isRentReview = getRentReviewFlag(lease);

  const unitRowData: UnitRowData = {
    $meta: {
      key: getUnitRowKey(lease),
      buildingCode: building.code,
      buildingUnitId: lease.buildingUnitID,
      leaseUnitId: maybeLeaseUnit?.leaseUnitID,
      effectiveUnitRentStartDate: maybeLeaseUnit?.effectiveUnitRent?.startDate,
      isFuture,
      isRentReview,
      background:
        maybeLegend?.iconType === LegendIconType.FILL
          ? tintColor(maybeLegend.color, 0.29)
          : undefined,
      highlighterColor: maybeLegend?.color,
    },
    unitNames: makeNoneCellData(),
    excludeInSummary: makeNoneCellData(),
    unitArea: makeNoneCellData(),
    tenant: makeNoneCellData(),
    fb: makeNoneCellData(),
    foodLicense: makeNoneCellData(),
    rentPerMonth: makeNoneCellData(),
    turnoverRates: makeNoneCellData(),
    psf: makeNoneCellData(),
    rf: makeNoneCellData(),
    lp: makeNoneCellData(),
    effPsf: makeNoneCellData(),
    rentReviews: makeNoneCellData(),
    commencementDate: makeNoneCellData(),
    expiryDate: makeNoneCellData(),
    remarks: makeNoneCellData(),
    cof: makeNoneCellData(),
  };

  const keyAttribute = getKeyAttribute(lease);

  const unitRemarks =
    getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId(
      keyAttribute
    )?.remarks;

  const unitArea = getUnitArea(lease);

  const areaTotal = leaseCustomization?.unitArea ?? unitArea;
  const faceRent = maybeLeaseUnit?.faceRent ?? 0;
  const faceUnitRent = areaTotal > 0 ? faceRent / areaTotal : null;

  unitRowData.unitNames = makeCellData([lease.buildingUnitNo]);
  unitRowData.excludeInSummary = makeCellData(
    leaseCustomization?.excludeInSummary ?? false
  );
  unitRowData.unitArea = makeCellData(
    leaseCustomization?.unitArea ?? unitArea,
    leaseCustomization?.unitArea != null
  );
  unitRowData.tenant = isVacant(lease)
    ? makeCellData({
        type: "last",
        name:
          (leaseCustomization?.tenantName ||
            maybeLeaseUnit?.leaseShop?.shopName) ??
          "",
        leaseExpiry: maybeLeaseUnit?.expiryDate,
      })
    : makeCellData(
        {
          type: "sole",
          name:
            (leaseCustomization?.tenantName ||
              maybeLeaseUnit?.leaseShop?.shopName) ??
            "",
        },
        !!leaseCustomization?.tenantName
      );
  unitRowData.fb = makeCellData(
    !!leaseCustomization?.foodLicense,
    !!leaseCustomization?.foodLicense
  );
  unitRowData.foodLicense = makeCellData(
    leaseCustomization?.foodLicense ?? null,
    leaseCustomization?.foodLicense != null
  );
  unitRowData.rentPerMonth = makeCellData(
    leaseCustomization?.faceRent ?? faceRent,
    leaseCustomization?.faceRent != null
  );
  unitRowData.turnoverRates = makeCellData(
    (leaseCustomization?.turnoverRates.length ?? 0) > 0
      ? leaseCustomization?.turnoverRates.map(
          mapRetailLeaseCustomizationTurnoverRateToTurnoverRate
        )
      : getCurrentTurnoverRates(lease.leaseTurnoverRents),
    (leaseCustomization?.turnoverRates.length ?? 0) > 0
  );
  unitRowData.psf = makeCellData(
    leaseCustomization?.faceUnitRent ?? faceUnitRent,
    leaseCustomization?.faceUnitRent != null
  );
  const customRflp =
    leaseCustomization != null
      ? getRflpFromLeaseCustomization(leaseCustomization)
      : null;
  const defaultRflp = getRflp(lease);
  unitRowData.rf = makeCellData(
    customRflp?.rf ?? defaultRflp.rf,
    customRflp?.rf != null
  );
  unitRowData.lp =
    lease.licensePeriodIdx === 0
      ? makeCellData(customRflp?.lp ?? defaultRflp.lp, customRflp?.lp != null)
      : makeNoneCellData();
  unitRowData.effPsf = makeCellData(
    leaseCustomization?.effectiveUnitRent ??
      maybeLeaseUnit?.effectiveUnitRent?.effectiveUnitRent ??
      null,
    leaseCustomization?.effectiveUnitRent != null
  );
  unitRowData.commencementDate = makeCellData(lease.commencementDate);
  unitRowData.rentReviews = makeCellData(
    getRentReviews(lease.rentReviews ?? [])
  );
  unitRowData.expiryDate = makeCellData(maybeLeaseUnit?.expiryDate ?? null);
  unitRowData.remarks = makeCellData({
    unitRemarks,
    negotiations: getNegotiationsByBuildingUnitId(lease.buildingUnitID),
  });

  const customizedCof = leaseCustomization
    ? getCustomizedCof(leaseCustomization)
    : null;
  unitRowData.cof = makeCellData(
    customizedCof ?? getCof(lease, args.leases, "retail", new Date()),
    customizedCof != null
  );

  if (isVacant(lease)) {
    // Dash
    unitRowData.rentPerMonth = makeNoneCellData();
    unitRowData.psf = makeNoneCellData();
    unitRowData.rf = makeNoneCellData();
    unitRowData.lp = makeNoneCellData();
    unitRowData.effPsf = makeNoneCellData();
    unitRowData.rentReviews = makeNoneCellData();
    unitRowData.expiryDate = makeNoneCellData();
  }

  return [unitRowData];
}

function getUnitRowKey(lease: Lease): string {
  const keyAttribute = getKeyAttribute(lease);
  return `unit-row#${keyAttribute.plsBuildingUnitId}-${keyAttribute.plsLeaseUnitId}-${keyAttribute.effectiveUnitRentStartDateStr}`;
}


import { PropType, defineComponent } from "vue";

import { GrandTotalSummary } from "../models/GrandTotalSummary";
import { Maybe } from "../types/Maybe";

import formatNumber from "../filters/formatNumber";
import prefix from "../filters/prefix";
import suffix from "../filters/suffix";

import BuildingSelect from "./BuildingSelect.vue";
import HeaderSearchBar from "./HeaderSearchBar.vue";
import OSSImage from "./OSSImage.vue";
import IconAppartmentSvg from "../assets/images/icon-appartment.svg";
import IconRetailSvg from "../assets/images/icon-retail.svg";
import { Option, SelectableOption } from "./BuildingSelectViewModel";

export default defineComponent({
  name: "HeaderFilter",

  props: {
    isBuildingSelectLoading: {
      type: Boolean,
      required: true,
    },
    buildingSelectOptions: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    selectedOption: {
      type: Object as PropType<Maybe<SelectableOption>>,
      default: null,
    },
    showBuildingSummary: {
      type: Boolean,
      required: true,
    },
    isBottomRounded: {
      type: Boolean,
      required: true,
    },
    buildingSummary: {
      type: Object as PropType<GrandTotalSummary | null>,
      default: null,
    },
    isExportButtonLoading: {
      type: Boolean,
      required: true,
    },
    showRetailTypesSelect: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    "buildingSelectSelected",
    "exportClick",
    "searchClick",
    "viewSummaryClick",
  ],

  components: {
    BuildingSelect,
    HeaderSearchBar,
    IconAppartmentSvg,
    IconRetailSvg,
    OSSImage,
  },

  data() {
    return {
      showSearchBar: false,
    };
  },

  computed: {
    isViewSummaryDisabled() {
      return this.selectedOption == null;
    },

    image() {
      if (this.selectedOption?.type === "building") {
        return this.selectedOption.image;
      }
      return null;
    },
  },

  methods: {
    handleBuildingSelectChange(option: SelectableOption) {
      this.$emit("buildingSelectSelected", option);
    },

    handleExportClick() {
      this.$emit("exportClick", this.selectedOption);
    },

    handleSearchToggleClick() {
      this.showSearchBar = !this.showSearchBar;
    },

    handleSearchClick(payload: unknown) {
      this.$emit("searchClick", payload);
    },

    handleViewSummaryClick() {
      this.$emit("viewSummaryClick");
    },
  },

  filters: {
    formatNumber,
    prefix,
    suffix,
  },
});

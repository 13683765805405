import Cookies from "js-cookie";

import { Maybe } from "../types/Maybe";

const LAST_SELECTED_OPTION_COOKIE_KEY = "STACKING_PLAN_LAST_SELECTED_OPTION";

interface CachedOption {
  type: string;
  code: string;
}

export function getLastSelectedBuildingSelectOption(): Maybe<CachedOption> {
  const maybeLastSelectedOptionStr = Cookies.get(
    LAST_SELECTED_OPTION_COOKIE_KEY
  );
  if (!maybeLastSelectedOptionStr) {
    return null;
  }
  try {
    const { type, code } = JSON.parse(maybeLastSelectedOptionStr);
    return {
      type,
      code,
    };
  } catch {
    return null;
  }
}

export function setLastSelectedBuildingSelectOption(
  option: CachedOption
): void {
  Cookies.set(LAST_SELECTED_OPTION_COOKIE_KEY, JSON.stringify(option), {
    sameSite: "strict",
  });
}

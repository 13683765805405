
import { defineComponent } from "vue";
import { clampDecimalDigits } from "../utils/clamp";
import DatePicker, { parseValue } from "./DatePicker.vue";

export default defineComponent({
  name: "LeaseCustomizationFormTurnoverRateInput",

  components: {
    DatePicker,
  },

  props: {},

  emits: ["submit"],

  data(): {
    turnoverRateStr: string | null;
    startDateStr: string | null;
    endDateStr: string | null;
  } {
    return {
      turnoverRateStr: null,
      startDateStr: null,
      endDateStr: null,
    };
  },

  computed: {
    canAdd() {
      return this.turnoverRateStr && this.startDateStr && this.endDateStr;
    },
  },

  methods: {
    parse(value: string | null): number | null {
      try {
        return value != null ? parseInt(value, 10) : null;
      } catch (e: unknown) {
        return null;
      }
    },
    handleTurnoverRateChange(value: string) {
      let turnoverRate = this.parse(value);
      if (turnoverRate == null) {
        return;
      }
      this.turnoverRateStr = clampDecimalDigits(turnoverRate, 9, 2).toString();
    },
    handleStartDateChange(value: string) {
      this.startDateStr = value;
    },
    handleEndDateChange(value: string) {
      this.endDateStr = value;
    },
    handleSubmit() {
      if (!this.turnoverRateStr || !this.startDateStr || !this.endDateStr) {
        return;
      }

      const turnoverRate = this.parse(this.turnoverRateStr);
      const startDate = parseValue(this.startDateStr);
      const endDate = parseValue(this.endDateStr);

      this.$emit("submit", turnoverRate, startDate, endDate);
      this.reset();
    },
    reset() {
      this.turnoverRateStr = null;
      this.startDateStr = null;
      this.endDateStr = null;
    },
  },
});


import { PropType, defineComponent } from "vue";

import { File } from "../models/File";

import BuildingViewCarousel from "./BuildingViewCarousel.vue";

export default defineComponent({
  name: "BuildingViewDialog",

  props: {
    value: Boolean,
    initialCarouselItemIndex: {
      type: Number,
      default: 0,
    },
    views: {
      type: Array as PropType<File[]>,
      required: true,
    },
  },

  emits: ["closeClick", "input"],

  components: {
    BuildingViewCarousel,
  },

  data() {
    return {
      carouselItemIndex: 0,
      itemIndexInitialized: false,
    };
  },

  beforeUpdate() {
    if (!this.value) {
      // Reset initialized state upon dialog closed
      this.itemIndexInitialized = false;
      this.$refs.carousel?.unloadHooper();
      return;
    }
    if (this.itemIndexInitialized) {
      return;
    }
    this.itemIndexInitialized = true;
    this.carouselItemIndex = this.initialCarouselItemIndex;
    setTimeout(() => {
      this.$refs.carousel?.loadHooper();
    }, 500);
  },

  methods: {
    handleInput(value: number) {
      this.$emit("input", value);
    },
    handleCarouselCloseClick() {
      this.$emit("closeClick");
    },
    handleCarouselItemClick(itemIndex: number) {
      this.carouselItemIndex = itemIndex;
    },
    handleCarouselItemIndexChange(itemIndex: number) {
      this.carouselItemIndex = itemIndex;
    },
  },
});

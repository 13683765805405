
import { defineComponent } from "vue";

export default defineComponent({
  name: "LeaseCustomizationFormChipsInput",

  props: {
    label: String,
    placeholder: String,
    messages: String,
    items: Array,
  },

  emits: ["removeItem"],
});

import { Getters } from "vue/types/vue";
import { OfficeLeaseAndCustomization } from "../types/LeaseAndCustomization";
import { Maybe } from "../types/Maybe";
import {
  TableFilter,
  matchExpiryFilter,
  matchLegendFilter,
  matchSearchFilter,
} from "../types/TableFilter";
import { Floor } from "../models/Floor";
import { Lease, OfficeLease, getKeyAttribute } from "../models/Lease";
import { getCustomizedCof } from "../models/LeaseCustomization";
import { LeaseRentReview } from "../models/LeaseRentReview";
import {
  Legend,
  LegendFilterGroup,
  LegendIconType,
  findLegendByZone,
} from "../models/Legend";
import { Negotiation } from "../models/Negotiation";
import { CellData, makeCellData, makeNoneCellData } from "../utils/cell";
import { buildComparison } from "../utils/compare";
import {
  isOffice,
  GroupType,
  getGrouping,
  getUnitArea,
  getTotalUnitArea,
  getRentReviews,
  getCof,
  getRflp,
  getRflpFromLeaseCustomization,
  matchLegendForColor,
} from "../utils/lease";
import { tintColor } from "../utils/color";
import { sum } from "../utils/sum";

export type FloorUnitTableData = FloorRowData[];

type FloorRowData = {
  $meta: {
    floorId: number;
    background?: string;
  };
  floor: {
    name: string;
    area: number;
  };
  units: UnitRowData[];
};

type UnitRowData = {
  $meta: {
    key: string;
    groupType: GroupType;
    buildingUnitId: number;
    leaseUnitId: Maybe<number>;
    effectiveUnitRentStartDate: Maybe<Date>;
    isFuture: boolean;
    isRentReview: boolean;
    isOffice: boolean;
    background?: string;
    highlighterColor?: string;
    jumpKey: Maybe<string>;
  };
  unitNames: CellData<string[]>;
  unitFacilities: CellData<string[]>;
  excludeInSummary: CellData<boolean>;
  unitArea: CellData<number>;
  tenant: CellData<
    | { type: "sole"; name: string }
    | { type: "parent"; name: string }
    | { type: "child"; name: string; unitName: string; parentName: string }
    | { type: "last"; name: Maybe<string>; leaseExpiry: Maybe<Date> }
  >;
  rentPerMonth: CellData<number>;
  psf: CellData<number>;
  rf: CellData<Maybe<string>>;
  lp: CellData<Maybe<string>>;
  effPsf: CellData<number>;
  rentReviews: CellData<LeaseRentReview[]>;
  expiryDate: CellData<Date>;
  remarks: CellData<{
    jump: boolean;
    units: Array<{
      name: string;
      remarks: string;
    }>;
    negotiations: Negotiation[];
  }>;
  cof: CellData<{
    c: boolean;
    o: boolean;
    f: boolean;
  }>;
};

type MakeContext = {
  floors: Floor[];
  leases: OfficeLease[];
  leaseAndCustomizations: OfficeLeaseAndCustomization[];
  legends: Legend[];
  getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId: Getters["stackingPlan/getPlsBuildingUnitLease"];
  getUnitFacilitiesByLeaseUnitId: Getters["stackingPlan/getUnitFacilities"];
  getNegotiationsByBuildingUnitId: (buildingUnitId: number) => Negotiation[];
  filtering: TableFilter;
};

export function makeFloorUnitTableData(args: MakeContext): FloorUnitTableData {
  const floorsSorted = args.floors.slice().sort((a, b) => {
    return a.displaySeq === b.displaySeq
      ? b.id - a.id
      : b.displaySeq - a.displaySeq;
  });
  const floorsSortedPLSBuildingFloorIds = floorsSorted.map(
    (floor) => floor.plsBuildingFloorId
  );
  const leasesSorted = args.leaseAndCustomizations
    .slice()
    .sort(({ lease: a }, { lease: b }) => {
      return buildComparison()
        .append(
          floorsSortedPLSBuildingFloorIds.indexOf(a.buildingFloorID),
          floorsSortedPLSBuildingFloorIds.indexOf(b.buildingFloorID)
        )
        .append(b.buildingUnitNo, a.buildingUnitNo)
        .append(a.leaseUnit?.leaseUnitID, b.leaseUnit?.leaseUnitID)
        .append(
          a.leaseUnit?.effectiveUnitRent?.startDate,
          b.leaseUnit?.effectiveUnitRent?.startDate
        )
        .result();
    });
  return floorsSorted.flatMap((floor) =>
    makeFloorRowData(floor, {
      ...args,
      leaseAndCustomizations: leasesSorted,
    })
  );
}

function makeFloorRowData(
  floor: Floor,
  args: MakeContext
): [] | [FloorRowData] {
  const unitAreaByUnitId: Record<number, number> = args.leaseAndCustomizations
    .filter(({ lease: x }) => x.buildingFloorID === floor.plsBuildingFloorId)
    .reduce(
      (dict, { lease: x }) => ({ ...dict, [x.buildingUnitID]: getUnitArea(x) }),
      {}
    );
  const floorArea = sum(Object.values(unitAreaByUnitId));
  const units = args.leaseAndCustomizations
    .filter(({ lease }) => lease.buildingFloorID === floor.plsBuildingFloorId)
    .flatMap((leaseAndCustomization) =>
      makeUnitRowData(floor, leaseAndCustomization, args)
    );
  return units.length > 0
    ? [
        {
          $meta: {
            floorId: floor.id,
            background: findLegendByZone(args.legends, floor.zone)?.color,
          },
          floor: {
            name: floor.floorName,
            area: floorArea,
          },
          units,
        },
      ]
    : [];
}

function makeUnitRowData(
  floor: Floor,
  leaseAndCustomization: OfficeLeaseAndCustomization,
  args: MakeContext
): [] | [UnitRowData] {
  const {
    leases,
    leaseAndCustomizations,
    getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId,
    getNegotiationsByBuildingUnitId,
    getUnitFacilitiesByLeaseUnitId,
  } = args;
  const { lease, customization: leaseCustomization } = leaseAndCustomization;

  const { tenant: maybeTenant, leaseUnit: maybeLeaseUnit } = lease;
  // No tenant with leaseUnit means non-tenant
  // No tenant without leaseUnit means new unit without history
  if (maybeTenant == null && maybeLeaseUnit != null) {
    return [];
  }

  const {
    leases: grouping,
    customization: groupCustomization,
    groupType,
    isFuture,
    isRentReview,
  } = getGrouping(leaseAndCustomization, leaseAndCustomizations);

  if (args.filtering.searchText) {
    if (
      !matchSearchFilter(
        args.filtering.searchText,
        maybeTenant?.tenantName ?? ""
      ) &&
      !matchSearchFilter(
        args.filtering.searchText,
        maybeTenant?.tenantShortName ?? ""
      ) &&
      !matchSearchFilter(
        args.filtering.searchText,
        leaseCustomization?.tenantName ?? ""
      )
    ) {
      return [];
    }
  }

  if (args.filtering.expiryDateFromDate || args.filtering.expiryDateToDate) {
    if (
      !matchExpiryFilter(
        args.filtering.expiryDateFromDate,
        args.filtering.expiryDateToDate,
        { lease }
      )
    ) {
      return [];
    }
  }

  const selectedLegends = [...args.filtering.selectedLegends.values()];
  if (args.filtering.selectedLegends.size > 0) {
    const flag = [
      LegendFilterGroup.STATUS,
      LegendFilterGroup.ZONE,
      LegendFilterGroup.CALCULATION,
    ].every((filterGroup) => {
      const selectedLegendsInFilterGroup = selectedLegends.filter(
        (legend) => legend.filterGroup === filterGroup
      );
      if (selectedLegendsInFilterGroup.length === 0) {
        return true;
      }
      return selectedLegendsInFilterGroup.some((legend) =>
        matchLegendFilter(
          legend,
          {
            lease,
            leaseCustomization: groupCustomization,
            leases,
            floorZone: floor.zone,
          },
          "office"
        )
      );
    });
    if (!flag) {
      return [];
    }
  }

  const maybeLegend = matchLegendForColor(
    args.legends,
    {
      lease,
      leaseCustomization: groupCustomization,
      leases,
      floorZone: floor.zone,
    },
    "office",
    selectedLegends
  );

  const unitRowData: UnitRowData = {
    $meta: {
      key: getUnitRowKey(lease),
      groupType,
      buildingUnitId: lease.buildingUnitID,
      leaseUnitId: maybeLeaseUnit?.leaseUnitID,
      effectiveUnitRentStartDate: maybeLeaseUnit?.effectiveUnitRent?.startDate,
      isFuture,
      isRentReview,
      isOffice: isOffice(lease),
      background:
        maybeLegend?.iconType === LegendIconType.FILL
          ? tintColor(maybeLegend.color, 0.29)
          : undefined,
      highlighterColor: maybeLegend?.color,
      jumpKey: groupType === "group-child" ? getUnitRowKey(grouping[0]) : null,
    },
    unitNames: makeNoneCellData(),
    unitFacilities: makeNoneCellData(),
    excludeInSummary: makeNoneCellData(),
    unitArea: makeNoneCellData(),
    tenant: makeNoneCellData(),
    rentPerMonth: makeNoneCellData(),
    psf: makeNoneCellData(),
    rf: makeNoneCellData(),
    lp: makeNoneCellData(),
    effPsf: makeNoneCellData(),
    rentReviews: makeNoneCellData(),
    expiryDate: makeNoneCellData(),
    remarks: makeNoneCellData(),
    cof: makeNoneCellData(),
  };

  const keyAttribute = getKeyAttribute(lease);

  const unitFacilities =
    getUnitFacilitiesByLeaseUnitId(keyAttribute)?.map(
      (unitFacility) => unitFacility.facility
    ) ?? [];
  const unitRemarks =
    getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId(keyAttribute);
  const unitRemarksUnits = grouping.flatMap((x) => {
    const unit = getPlsBuildingUnitLeaseByBuildingUnitIdAndLeaseUnitId(
      getKeyAttribute(x)
    );
    if (unit == null || !unit.remarks) {
      return [];
    }
    return [
      {
        name: x.buildingUnitNo,
        remarks: unit.remarks,
      },
    ];
  });

  const areaTotal = getTotalUnitArea(grouping);

  const rentTotal = sum(grouping.map((x) => x.leaseUnit?.faceRent ?? 0));

  unitRowData.unitNames =
    groupType === "group-parent"
      ? makeCellData(grouping.map((x) => x.buildingUnitNo))
      : makeCellData([lease.buildingUnitNo]);
  unitRowData.unitFacilities = makeCellData(unitFacilities);
  unitRowData.excludeInSummary =
    groupType !== "group-child"
      ? makeCellData(
          (groupCustomization?.excludeInSummary ?? false) || !isOffice(lease)
        )
      : makeCellData(!isOffice(lease));
  unitRowData.unitArea =
    groupType === "group-child"
      ? makeCellData(
          leaseCustomization?.unitArea ?? getUnitArea(lease),
          leaseCustomization?.unitArea != null
        )
      : makeCellData(
          leaseCustomization?.unitArea ?? areaTotal,
          leaseCustomization?.unitArea != null
        );
  unitRowData.tenant =
    groupType === "vacant"
      ? makeCellData({
          type: "last",
          leaseExpiry: maybeLeaseUnit?.expiryDate,
          name: maybeTenant?.tenantShortName || maybeTenant?.tenantName,
        })
      : groupType === "group-parent"
      ? makeCellData(
          {
            type: "parent",
            name:
              leaseCustomization?.tenantName ||
              maybeTenant?.tenantShortName ||
              maybeTenant?.tenantName ||
              "",
          },
          leaseCustomization?.tenantName != null
        )
      : groupType === "group-child"
      ? makeCellData(
          {
            type: "child",
            name:
              leaseCustomization?.tenantName ||
              maybeTenant?.tenantShortName ||
              maybeTenant?.tenantName ||
              "",
            unitName: grouping[0].buildingUnitNo,
            parentName:
              groupCustomization?.tenantName ||
              grouping[0].tenant?.tenantShortName ||
              grouping[0].tenant?.tenantName ||
              "",
          },
          leaseCustomization?.tenantName != null
        )
      : makeCellData(
          {
            type: "sole",
            name:
              leaseCustomization?.tenantName ||
              maybeTenant?.tenantShortName ||
              maybeTenant?.tenantName ||
              "",
          },
          leaseCustomization?.tenantName != null
        );
  unitRowData.rentPerMonth = makeCellData(
    leaseCustomization?.faceRent ?? rentTotal,
    leaseCustomization?.faceRent != null
  );
  unitRowData.psf = makeCellData(
    leaseCustomization?.faceUnitRent ??
      rentTotal / (areaTotal === 0 ? 1 : areaTotal),
    leaseCustomization?.faceUnitRent != null
  );
  const customRflp =
    leaseCustomization != null
      ? getRflpFromLeaseCustomization(leaseCustomization)
      : null;
  const defaultRflp = getRflp(lease);
  unitRowData.rf = makeCellData(
    customRflp?.rf ?? defaultRflp.rf,
    customRflp?.rf != null
  );
  unitRowData.lp =
    lease.licensePeriodIdx === 0
      ? makeCellData(customRflp?.lp ?? defaultRflp.lp, customRflp?.lp != null)
      : makeNoneCellData();
  unitRowData.effPsf = makeCellData(
    leaseCustomization?.effectiveUnitRent ??
      maybeLeaseUnit?.effectiveUnitRent?.effectiveUnitRent ??
      null,
    leaseCustomization?.effectiveUnitRent != null
  );
  unitRowData.rentReviews = makeCellData(
    getRentReviews(lease.rentReviews ?? [])
  );
  unitRowData.expiryDate = makeCellData(maybeLeaseUnit?.expiryDate ?? null);
  unitRowData.remarks = makeCellData({
    jump: groupType === "group-child" && unitRemarks?.remarks != null,
    units: groupType === "group-child" ? [] : unitRemarksUnits,
    negotiations: getNegotiationsByBuildingUnitId(lease.buildingUnitID),
  });
  const customizedCof = leaseCustomization
    ? getCustomizedCof(leaseCustomization)
    : null;
  unitRowData.cof = makeCellData(
    customizedCof ?? getCof(lease, args.leases, "office", new Date()),
    customizedCof != null
  );

  if (groupType === "vacant" || groupType === "group-child") {
    // Dash
    unitRowData.rentPerMonth = makeNoneCellData();
    unitRowData.psf = makeNoneCellData();
    unitRowData.rf = makeNoneCellData();
    unitRowData.lp = makeNoneCellData();
    unitRowData.effPsf = makeNoneCellData();
    unitRowData.rentReviews = makeNoneCellData();
    unitRowData.expiryDate = makeNoneCellData();
  }

  return [unitRowData];
}

function getUnitRowKey(lease: Lease): string {
  const keyAttribute = getKeyAttribute(lease);
  return `unit-row#${keyAttribute.plsBuildingUnitId}-${keyAttribute.plsLeaseUnitId}-${keyAttribute.effectiveUnitRentStartDateStr}`;
}

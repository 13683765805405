import * as yup from "yup";

import { LeaseEffectiveUnitRentSchema } from "./LeaseEffectiveUnitRent";
import { LeaseRentFreePeriodSchema } from "./LeaseRentFreePeriod";
import { LeaseShopSchema } from "./LeaseShop";
import { PlsDateSchema } from "./PlsDate";

export const RetailLeaseUnitSchema = yup.object({
  leaseUnitID: yup.number().required(),
  effectiveDate: PlsDateSchema.required(),
  expiryDate: PlsDateSchema.required(),
  licenseStartDate: PlsDateSchema.nullable(),
  licenseEndDate: PlsDateSchema.nullable(),
  shopName: yup.string().nullable(),
  effectiveUnitRents: yup.array().of(LeaseEffectiveUnitRentSchema).nullable(),
  rentFreePeriods: yup.array().of(LeaseRentFreePeriodSchema).nullable(),
  faceRent: yup.number().nullable(),
  leaseShop: LeaseShopSchema.nullable(),
});

export type RetailLeaseUnit = yup.InferType<typeof RetailLeaseUnitSchema>;

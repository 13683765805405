
import formatNumber from "../filters/formatNumber";
import { FoodLicenseSummary } from "@/models/FoodLicenseSummary";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "FoodLicenseSummaryTableStatistics",
  props: {
    foodLicenseSummary: {
      type: Object as PropType<FoodLicenseSummary>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    isNaN,
    ifConditionMeet(
      condition: (value: number) => boolean,
      yes: () => unknown,
      no: (value: number) => unknown
    ) {
      return (value: number) => {
        return condition(value) ? yes() : no(value);
      };
    },
    render(value: string | number) {
      return () => value;
    },
    pipe(...args: ((value: unknown) => unknown)[]) {
      return (value: unknown) => {
        return args.reduce((prev, fn) => fn(prev), value);
      };
    },
    formatCurrency(value: number) {
      return `$${value}`;
    },
    formatDigits(dp?: number) {
      return (value: number) => formatNumber(value, dp);
    },
    formatPercent(value: number) {
      return `${value.toFixed(2)}%`;
    },
  },
});

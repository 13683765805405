import { render, staticRenderFns } from "./FoodLicenseSummaryTable.vue?vue&type=template&id=33b4bca7&scoped=true&"
import script from "./FoodLicenseSummaryTable.vue?vue&type=script&lang=ts&"
export * from "./FoodLicenseSummaryTable.vue?vue&type=script&lang=ts&"
import style0 from "./FoodLicenseSummaryTable.vue?vue&type=style&index=0&id=33b4bca7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b4bca7",
  null
  
)

export default component.exports
import { Maybe } from "../types/Maybe";

export type CellData<T> =
  | { type: "default"; value: T }
  | { type: "custom"; value: T }
  | { type: "none" };

export function makeNoneCellData<T>(): CellData<T> {
  return { type: "none" };
}

export function makeCellData<T>(value: Maybe<T>, custom = false): CellData<T> {
  return value == null
    ? makeNoneCellData()
    : custom
    ? { type: "custom", value }
    : { type: "default", value };
}

export function getCell<T>(cell: CellData<T>, key?: keyof T) {
  switch (cell.type) {
    case "none":
      return null;
    case "custom":
    case "default":
      return key == null ? cell.value : cell.value[key];
  }
}

export function applyCell<T, S>(
  cell: CellData<T>,
  map: (t: T, ...args: unknown[]) => S,
  ...mapArgs: unknown[]
) {
  switch (cell.type) {
    case "none":
      return makeCellData(null);
    case "custom":
      return makeCellData(map(cell.value, ...mapArgs), true);
    case "default":
      return makeCellData(map(cell.value, ...mapArgs));
  }
}

export function formatCell<T>(cell: CellData<T>, noneString = "-") {
  switch (cell.type) {
    case "none":
      return noneString;
    case "custom":
      return `${cell.value}*`;
    case "default":
      return `${cell.value}`;
  }
}

export function asDefaultIfCustom<T>(cell: CellData<T>): CellData<T> {
  switch (cell.type) {
    case "custom":
      return { type: "default", value: cell.value };
    default:
      return cell;
  }
}

import { RequestBody } from "../apis/retailLeaseCustomization";
import { Lease, OfficeLease, RetailLease } from "../models/Lease";
import {
  getCustomizedCof,
  RetailLeaseCustomization,
} from "../models/LeaseCustomization";
import { getMonthDayFromStartAndEnd, MonthDay } from "../models/MonthDay";
import { Maybe } from "../types/Maybe";
import {
  COF,
  TurnoverRate,
  getCurrentTurnoverRates,
  getExpiryGreaterThan12MonthsFlag,
  getExpiryLessThanOrEqualTo12MonthsFlag,
  getPendingRentReviewIn12Month,
  getUnitArea,
  getVacantFlag,
  getFutureLeasesOfLease,
  getFutureLeasesOfLeaseEndAfter12Months,
} from "../utils/lease";
import { Option } from "../utils/options";

export interface SelectOption<ValueType> {
  value: ValueType;
  name: string;
}

export interface FormValueBase {
  unitArea: Maybe<number>;
  tenantName: Maybe<string>;
  faceRent: Maybe<number>;
  faceUnitRent: Maybe<number>;
  effectiveUnitRent: Maybe<number>;
  licensePeriods: MonthDay[];
  rentFreePeriods: MonthDay[];
  turnoverRates: TurnoverRate[];
  status: Maybe<string>;
  cof: COF;
}

function makeFormValueBaseStatus(
  lease: Lease,
  leases: Lease[],
  statusOptions: Option[]
): Maybe<string> {
  const now = new Date();
  let option: Maybe<Option> = null;
  if (
    getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
    getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now).length === 0
  ) {
    option = statusOptions.find((x) => x.name === "<= 12 Months");
  }
  if (
    getExpiryGreaterThan12MonthsFlag(lease, now) ||
    (getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
      getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now).length > 0)
  ) {
    option = statusOptions.find((x) => x.name === "> 12 Months");
  }
  if (
    getPendingRentReviewIn12Month(lease, now) &&
    getFutureLeasesOfLease(lease, leases).length === 0
  ) {
    option = statusOptions.find((x) => x.name === "Rent Review in 12 Months");
  }
  if (getVacantFlag(lease)) {
    option = statusOptions.find((x) => x.name === "Vacant");
  }
  return option?.value ?? null;
}

export function makeFormValueBase(
  lease: RetailLease,
  leases: RetailLease[],
  statusOptions: Option[],
  cof: COF
): FormValueBase {
  const { leaseTurnoverRents } = lease;
  const licenseStartDate = lease.leaseUnit?.licenseStartDate;
  const licenseEndDate = lease.leaseUnit?.licenseEndDate;

  const unitArea = getUnitArea(lease);
  const rentFreePeriods = lease.leaseUnit?.rentFreePeriods;
  const faceRent = lease.leaseUnit?.faceRent;

  const licensePeriods =
    licenseStartDate && licenseEndDate
      ? [getMonthDayFromStartAndEnd(licenseStartDate, licenseEndDate)]
      : [];

  const _rentFreePeriods =
    rentFreePeriods?.map((rentFreePeriod) =>
      getMonthDayFromStartAndEnd(
        rentFreePeriod.startDate,
        rentFreePeriod.endDate
      )
    ) ?? [];

  const status = makeFormValueBaseStatus(lease, leases, statusOptions);

  const turnoverRates = getCurrentTurnoverRates(leaseTurnoverRents);

  return {
    unitArea: unitArea,
    tenantName: lease.leaseUnit?.leaseShop?.shopName ?? "",
    faceRent,
    faceUnitRent: unitArea > 0 ? (faceRent ?? 0) / unitArea : 0,
    effectiveUnitRent:
      lease.leaseUnit?.effectiveUnitRent?.effectiveUnitRent ?? null,
    licensePeriods,
    rentFreePeriods: _rentFreePeriods,
    turnoverRates,
    status,
    cof,
  };
}

export function makeFormValueBaseFromOfficeLease(
  lease: OfficeLease,
  leases: OfficeLease[],
  statusOptions: Option[],
  cof: COF
): FormValueBase {
  const licenseStartDate = lease.leaseUnit?.licenseStartDate;
  const licenseEndDate = lease.leaseUnit?.licenseEndDate;

  const unitArea = getUnitArea(lease);
  const rentFreePeriods = lease.leaseUnit?.rentFreePeriods;
  const faceRent = lease.leaseUnit?.faceRent;

  const licensePeriods =
    licenseStartDate && licenseEndDate
      ? [getMonthDayFromStartAndEnd(licenseStartDate, licenseEndDate)]
      : [];

  const _rentFreePeriods =
    rentFreePeriods?.map((rentFreePeriod) =>
      getMonthDayFromStartAndEnd(
        rentFreePeriod.startDate,
        rentFreePeriod.endDate
      )
    ) ?? [];

  const status = makeFormValueBaseStatus(lease, leases, statusOptions);

  return {
    unitArea: unitArea,
    tenantName: lease.leaseUnit?.shopName,
    faceRent,
    faceUnitRent: unitArea > 0 ? (faceRent ?? 0) / unitArea : 0,
    effectiveUnitRent:
      lease.leaseUnit?.effectiveUnitRent?.effectiveUnitRent ?? null,
    licensePeriods,
    rentFreePeriods: _rentFreePeriods,
    turnoverRates: [],
    status,
    cof,
  };
}

export interface FormState {
  unitArea: Maybe<number>;
  tenantName: Maybe<string>;
  faceRent: Maybe<number>;
  faceUnitRent: Maybe<number>;
  effectiveUnitRent: Maybe<number>;
  licensePeriods: { months: number; days: number }[];
  rentFreePeriods: { months: number; days: number }[];
  turnoverRates: { turnoverRate: number; startDate: Date; endDate: Date }[];
  hasFoodLicense: boolean;
  foodLicense: Maybe<string>;
  status: Maybe<string>;
  excludeInSummary: boolean;
  outsideSittingArea: boolean;
  includeInCalculationWithoutNewLeases: boolean;
  includeInCalculationWithNewLeases: boolean;
  includeInOccupied: boolean;
}

export function makeFormState(
  leaseCustomization: Maybe<RetailLeaseCustomization>,
  cof: COF
): FormState {
  if (!leaseCustomization) {
    return {
      unitArea: null,
      tenantName: null,
      faceRent: null,
      faceUnitRent: null,
      effectiveUnitRent: null,
      licensePeriods: [],
      rentFreePeriods: [],
      turnoverRates: [],
      hasFoodLicense: false,
      foodLicense: null,
      status: null,
      excludeInSummary: false,
      outsideSittingArea: false,
      includeInCalculationWithoutNewLeases: cof.c,
      includeInCalculationWithNewLeases: cof.f,
      includeInOccupied: cof.o,
    };
  }

  const {
    unitArea = null,
    tenantName = null,
    faceRent = null,
    faceUnitRent = null,
    effectiveUnitRent = null,
    licensePeriods = null,
    rentFreePeriods = null,
    turnoverRates,
    foodLicense = null,
    status = null,
    excludeInSummary,
    outsideSittingArea,
  } = leaseCustomization;

  const maybeCustomizedCof = getCustomizedCof(leaseCustomization) ?? cof;

  return {
    unitArea,
    tenantName,
    faceRent,
    faceUnitRent,
    effectiveUnitRent,
    licensePeriods: [...(licensePeriods ?? [])].sort(
      (a, b) => a.displaySeq - b.displaySeq
    ),
    rentFreePeriods: [...(rentFreePeriods ?? [])].sort(
      (a, b) => a.displaySeq - b.displaySeq
    ),
    turnoverRates: turnoverRates.slice(),
    hasFoodLicense: !!foodLicense,
    foodLicense,
    status,
    excludeInSummary,
    outsideSittingArea,
    includeInCalculationWithNewLeases: maybeCustomizedCof.f,
    includeInCalculationWithoutNewLeases: maybeCustomizedCof.c,
    includeInOccupied: maybeCustomizedCof.o,
  };
}

export function makeRequestBody(
  moCode: string,
  buildingCode: string,
  formState: FormState,
  lease: Lease,
  customization: Maybe<RetailLeaseCustomization>,
  baseCof: COF
): RequestBody {
  const isCusotmizedCofSameAsLeaseCof =
    baseCof.c === formState.includeInCalculationWithoutNewLeases &&
    baseCof.o === formState.includeInOccupied &&
    baseCof.f === formState.includeInCalculationWithNewLeases;

  const obj: RequestBody = {
    moCode,
    buildingCode,
    plsBuildingUnitId: lease.buildingUnitID,
    plsLeaseUnitId: lease.leaseUnit?.leaseUnitID,
    effectiveUnitRentStartDate: lease.leaseUnit?.effectiveUnitRent?.startDate,
    unitArea: formState.unitArea,
    tenantName: formState.tenantName || null,
    faceRent: formState.faceRent,
    faceUnitRent: formState.faceUnitRent,
    effectiveUnitRent: formState.effectiveUnitRent,
    licensePeriods: formState.licensePeriods.map((value, displaySeq) => ({
      ...value,
      displaySeq,
    })),
    rentFreePeriods: formState.rentFreePeriods.map((value, displaySeq) => ({
      ...value,
      displaySeq,
    })),
    turnoverRates: formState.turnoverRates.map((value, displaySeq) => ({
      ...value,
      displaySeq,
    })),
    foodLicense: formState.hasFoodLicense ? formState.foodLicense : null,
    status: formState.status,
    excludeInSummary: formState.excludeInSummary,
    outsideSittingArea: formState.outsideSittingArea,
    includeInCalculationWithNewLeases: isCusotmizedCofSameAsLeaseCof
      ? null
      : formState.includeInCalculationWithNewLeases,
    includeInCalculationWithoutNewLeases: isCusotmizedCofSameAsLeaseCof
      ? null
      : formState.includeInCalculationWithoutNewLeases,
    includeInOccupied: isCusotmizedCofSameAsLeaseCof
      ? null
      : formState.includeInOccupied,
    version: customization?.version,
  };

  return obj;
}

import { fetchEventEmitter } from "../events/fetchEventEmitter";

export type BaseUrlFactory = () => string;

export type HeadersFactory = () => { [key in string]: string };

let _baseUrlFactory: BaseUrlFactory | null = null;

let _getDefaultHeaders: HeadersFactory | null = null;

const myFetch: typeof fetch = async (input, init) => {
  if (!_baseUrlFactory) {
    throw new Error("No base url factory");
  }
  const baseUrl = _baseUrlFactory();
  const newInput =
    typeof input === "string" ? new URL(input, baseUrl).toString() : input;

  const headers = init?.headers;
  const defaultHeaders = _getDefaultHeaders ? _getDefaultHeaders() : null;

  const newHeaders = {
    ...defaultHeaders,
    ...headers,
  };

  fetchEventEmitter.emit("before-fetch");
  const response = await fetch(newInput, {
    ...init,
    headers: newHeaders,
    credentials: "omit",
  });
  if (response.ok) {
    fetchEventEmitter.emit("after-fetch");
    return response;
  }
  const error = new Error(await response.text());
  fetchEventEmitter.emit("error", { status: response.status, error });
  throw error;
};

export function setupFetch(
  baseUrlFactory: BaseUrlFactory,
  getDefaultHeaders?: HeadersFactory
) {
  _baseUrlFactory = baseUrlFactory;
  _getDefaultHeaders = getDefaultHeaders ?? null;
}

export default myFetch;

import { Maybe } from "../types/Maybe";
import { FloorZone } from "../models/Floor";
import { Legend, LegendFilterType } from "../models/Legend";
import { Lease } from "../models/Lease";
import {
  getCustomizedCof,
  OfficeLeaseCustomization,
  RetailLeaseCustomization,
} from "../models/LeaseCustomization";
import {
  getCof,
  getExpiryLessThanOrEqualTo12MonthsFlag,
  getExpiryGreaterThan12MonthsFlag,
  getPendingRentReviewIn12Month,
  getFutureLeasesOfLease,
  getFutureLeasesOfLeaseEndAfter12Months,
} from "../utils/lease";

export type TableFilter = {
  expiryDateFromDate: Maybe<Date>;
  expiryDateToDate: Maybe<Date>;
  searchText: Maybe<string>;
  selectedLegends: Map<string, Legend>;
  isFbOnly: Maybe<boolean>;
};

export function matchLegendFilter(
  legend: Legend,
  args: {
    lease: Lease;
    leaseCustomization: Maybe<
      OfficeLeaseCustomization | RetailLeaseCustomization
    >;
    floorZone: Maybe<string>;
    leases: Lease[];
  },
  stackingPlanType: "office" | "retail"
): boolean {
  const { filterType } = legend;
  const { lease, leaseCustomization, floorZone, leases } = args;
  const now = new Date();
  const customizedCof = leaseCustomization
    ? getCustomizedCof(leaseCustomization)
    : null;
  const cof = customizedCof ?? getCof(lease, leases, stackingPlanType, now);

  switch (filterType) {
    case LegendFilterType.VACANT:
      return lease.leaseNo == null || leaseCustomization?.status === "Vacant";
    case LegendFilterType.LIKELY_TO_VACATE:
      return leaseCustomization?.status === "Likely to Vacate";
    case LegendFilterType.CONFIRMED_TO_VACATE:
      return leaseCustomization?.status === "Confirmed to Vacate";
    case LegendFilterType.LTE_12_MONTHS:
      return leaseCustomization?.status != null
        ? leaseCustomization.status === "<= 12 Months"
        : getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
            getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now)
              .length === 0;
    case LegendFilterType.GT_12_MONTHS:
      return leaseCustomization?.status != null
        ? leaseCustomization.status === "> 12 Months"
        : getExpiryGreaterThan12MonthsFlag(lease, now) ||
            (getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
              getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now)
                .length > 0);
    case LegendFilterType.RENT_REVIEW_IN_12_MONTHS:
      return leaseCustomization?.status != null
        ? leaseCustomization.status === "Rent Review in 12 Months"
        : getPendingRentReviewIn12Month(lease, now) &&
            getFutureLeasesOfLease(lease, leases).length === 0;
    case LegendFilterType.VULNERABLE:
      return leaseCustomization?.status === "Vulnerable";
    case LegendFilterType.HIGHEST_ZONE:
      return floorZone === FloorZone.HIGHEST_ZONE;
    case LegendFilterType.HIGH_ZONE:
      return floorZone === FloorZone.HIGH_ZONE;
    case LegendFilterType.MID_ZONE:
      return floorZone === FloorZone.MID_ZONE;
    case LegendFilterType.LOW_ZONE:
      return floorZone === FloorZone.LOW_ZONE;
    case LegendFilterType.CALCULATION_C:
      return cof.c;
    case LegendFilterType.CALCULATION_O:
      return cof.o;
    case LegendFilterType.CALCULATION_F:
      return cof.f;
  }
}

export function matchSearchFilter(searchText: string, text: string): boolean {
  const trimmedSearchText = searchText.trim();
  if (!trimmedSearchText) {
    return true;
  }
  return text.toLowerCase().includes(trimmedSearchText.toLowerCase());
}

export function matchExpiryFilter(
  fromDate: Maybe<Date>,
  toDate: Maybe<Date>,
  args: { lease: Lease }
): boolean {
  const expiryDate = args.lease.leaseUnit?.expiryDate;
  if (expiryDate == null) {
    return false;
  }
  if (fromDate != null && expiryDate < fromDate) {
    return false;
  }
  if (toDate != null && toDate < expiryDate) {
    return false;
  }
  return true;
}

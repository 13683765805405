import * as yup from "yup";
import { PlsDateSchema } from "./PlsDate";

export const LeaseEffectiveUnitRentSchema = yup.object({
  startDate: PlsDateSchema.required(),
  endDate: PlsDateSchema.required(),
  effectiveUnitRent: yup.number().required(),
});

export type LeaseEffectiveUnitRent = yup.InferType<
  typeof LeaseEffectiveUnitRentSchema
>;

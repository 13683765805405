import fetch from "./fetch";

class ApiClient {
  private defaultHeader: HeadersInit = { "Content-Type": "application/json" };

  public download(url: string, headers?: HeadersInit) {
    return fetch(url, {
      method: "GET",
      headers,
    }).then((resp) => resp.blob());
  }

  public get(url: string, headers?: HeadersInit) {
    return fetch(url, {
      method: "GET",
      headers: {
        ...this.defaultHeader,
        ...headers,
      },
    }).then((resp) => resp.json());
  }

  public post(url: string, body?: object, headers?: HeadersInit) {
    return fetch(url, {
      method: "POST",
      headers: {
        ...this.defaultHeader,
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    }).then((resp) => resp.json());
  }

  public put(url: string, body?: object, headers?: HeadersInit) {
    return fetch(url, {
      method: "PUT",
      headers: {
        ...this.defaultHeader,
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    }).then((resp) => resp.json());
  }

  public delete(url: string, headers?: HeadersInit) {
    return fetch(url, {
      method: "DELETE",
      headers: {
        ...this.defaultHeader,
        ...headers,
      },
    }).then((resp) => resp.json());
  }
}

const apiClient = new ApiClient();

export default apiClient;

import { GrandTotalSummary, RentSummary } from "@/models/GrandTotalSummary";
import { OfficeLease, Lease, RetailLease } from "@/models/Lease";
import {
  LeaseCustomization,
  OfficeLeaseCustomization,
  RetailLeaseCustomization,
} from "@/models/LeaseCustomization";
import {
  makeOfficeLeaseAndCustomizations,
  makeRetailLeaseAndCustomizations,
} from "../types/LeaseAndCustomization";
import { RecordByKeyAttribute, getRecord } from "../types/LeaseKeyAttribute";
import { addMonths, lastDayOfMonth } from "date-fns";
import { Maybe } from "yup/lib/types";
import {
  COF,
  getCOFMap,
  getGrouping,
  getRetailGrouping,
  getUnitArea,
  Grouping,
  getLegendMap,
  isOffice,
  getVacantFutureFlag,
  getFutureFlag,
} from "./lease";
import { getKeyAttribute } from "../models/Lease";
import { Legend, LegendFilterType } from "../models/Legend";
import { sum } from "./sum";
import {
  FoodLicenseSummary,
  AreaRangeRentSummary,
} from "@/models/FoodLicenseSummary";

export function getBuildingGrandTotalSummary(
  leases: OfficeLease[],
  leaseCustomizations: OfficeLeaseCustomization[],
  legends: Legend[],
  otherBuildingArea: number
): GrandTotalSummary {
  const leaseAndCustomizations = makeOfficeLeaseAndCustomizations(
    leases,
    leaseCustomizations
  );
  const groupings = leaseAndCustomizations
    .filter(({ lease }) => {
      return isOffice(lease);
    })
    .map((leaseAndCustomization) =>
      getGrouping(leaseAndCustomization, leaseAndCustomizations)
    )
    .filter(({ leases }) => leases.length > 0)
    .filter(
      (grouping) =>
        grouping.groupType !== "group-child" &&
        !(grouping.customization?.excludeInSummary ?? false)
    );
  const cofMap = getCOFMap(
    groupings.reduce<Lease[]>((prev, { leases }) => {
      return [...prev, ...leases];
    }, []),
    "office"
  );
  const legendMap = getLegendMap(groupings, legends, "office");
  return getGrandTotalSummary(groupings, cofMap, legendMap, otherBuildingArea);
}

export function getMOGrandTotalSummary(
  leases: RetailLease[],
  leaseCustomizations: RetailLeaseCustomization[],
  legends: Legend[],
  otherBuildingArea: number
): GrandTotalSummary {
  const leaseAndCustomizations = makeRetailLeaseAndCustomizations(
    leases,
    leaseCustomizations
  );
  const groupings = leaseAndCustomizations
    .filter(({ customization }) => {
      return customization ? !customization.excludeInSummary : true;
    })
    .map((leaseAndCustomization) => getRetailGrouping(leaseAndCustomization));
  const cofMap = getCOFMap(
    groupings.reduce<Lease[]>((prev, { leases }) => {
      return [...prev, ...leases];
    }, []),
    "retail"
  );
  const legendMap = getLegendMap(groupings, legends, "retail");
  return getGrandTotalSummary(groupings, cofMap, legendMap, otherBuildingArea);
}

function getGrandTotalSummary<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  cofMap: RecordByKeyAttribute<COF>,
  legendMap: ReturnType<typeof getLegendMap>,
  otherBuildingArea: number,
  expiryMonth = 12, // Assumed to be 12 months
  rentReviewMonth = 12 // Assumed to be 12 months
): GrandTotalSummary {
  const currentLeases = filterGroupings(groupings, isCurrent(cofMap));
  const currentTotalArea = getTotalArea(currentLeases, "L");

  const futureLeases = filterGroupings(groupings, isFuture(cofMap));
  const futureTotalArea = getTotalArea(futureLeases, "L");

  const occupiedLeases = filterGroupings(groupings, isOccupied(cofMap));
  const occupiedArea = getTotalArea(occupiedLeases, "L");

  const vacantLeases = filterGroupings(
    groupings,
    (lease) =>
      isLegendFilterType(legendMap, LegendFilterType.VACANT)(lease) &&
      !getFutureFlag(lease) &&
      !getVacantFutureFlag(lease)
  );
  const vacantArea = getTotalArea(vacantLeases, "L");

  const totalArea = occupiedArea + vacantArea;
  const totalBuildingArea = totalArea + otherBuildingArea;

  const dateRangeStart = new Date();
  const expiryDate = addMonths(dateRangeStart, expiryMonth);
  const rentReviewDate = lastDayOfMonth(
    addMonths(dateRangeStart, rentReviewMonth)
  );

  const confirmedToVacateLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.CONFIRMED_TO_VACATE)
  );
  const confirmedToVacateArea = getTotalArea(confirmedToVacateLeases, "L");

  const expiringLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.LTE_12_MONTHS)
  );
  const expiringArea = getTotalArea(expiringLeases, "L");

  const inRentReviewLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.RENT_REVIEW_IN_12_MONTHS)
  );
  const inRentReviewArea = getTotalArea(inRentReviewLeases, "L");

  return {
    rent: {
      totalOrAverageRent: getRentSummary(currentLeases, currentTotalArea),
      totalOrAverageRentWithNewLeases: getRentSummary(
        futureLeases,
        futureTotalArea
      ),
    },
    area: {
      occupiedArea: {
        sqFt: occupiedArea,
        percent: getAreaPercentage(occupiedArea, totalArea),
      },
      vacantArea: {
        sqFt: vacantArea,
        percent: getAreaPercentage(vacantArea, totalArea),
      },
      totalArea: {
        sqFt: totalArea,
        percent: getAreaPercentage(totalArea, totalBuildingArea),
      },
      otherBuildingArea: {
        sqFt: otherBuildingArea,
        percent: getAreaPercentage(otherBuildingArea, totalBuildingArea),
      },
      totalBuildingArea: {
        sqFt: totalBuildingArea,
        percent: 100,
      },
      upcomingExpiryArea: {
        sqFt: expiringArea,
        percent: getAreaPercentage(expiringArea, totalBuildingArea),
      },
      upcomingExpiryDate: expiryDate,
      confirmedVacateArea: {
        sqFt: confirmedToVacateArea,
        percent: getAreaPercentage(confirmedToVacateArea, totalBuildingArea),
      },
      rentReviewArea: {
        sqFt: inRentReviewArea,
        percent: getAreaPercentage(inRentReviewArea, totalBuildingArea),
      },
      rentReviewDate: rentReviewDate,
    },
  };
}

export function getFoodLicenseSummary(
  leases: RetailLease[],
  leaseCustomizations: RetailLeaseCustomization[],
  legends: Legend[],
  otherBuildingArea: number
): FoodLicenseSummary {
  const leaseAndCustomizations = makeRetailLeaseAndCustomizations(
    leases,
    leaseCustomizations
  );
  const groups = leaseAndCustomizations
    .filter(({ customization }) => {
      return customization ? !customization.excludeInSummary : true;
    })
    .map((leaseAndCustomization) => getRetailGrouping(leaseAndCustomization));
  const cofMap = getCOFMap(
    groups.reduce<Lease[]>((prev, { leases }) => {
      return [...prev, ...leases];
    }, []),
    "retail"
  );
  const legendMap = getLegendMap(groups, legends, "retail");

  return getFoodLicenseSummaryFromGroups(
    groups,
    cofMap,
    legendMap,
    otherBuildingArea
  );
}

export function getFoodLicenseSummaryFromGroups<
  T extends RetailLeaseCustomization
>(
  groupings: Grouping<T>[],
  cofMap: RecordByKeyAttribute<COF>,
  legendMap: ReturnType<typeof getLegendMap>,
  otherBuildingArea: number,
  expiryMonth = 12,
  rentReviewMonth = 12
): FoodLicenseSummary {
  const currentLeases = filterGroupings(groupings, isCurrent(cofMap));
  const currentTotalArea = getTotalArea(currentLeases, "L");

  const currentLeasesWithFoodLicense = filterGroupings(
    groupings.filter(
      (grouping) => grouping?.customization?.foodLicense != null
    ),
    (lease, grouping) =>
      isOccupied(cofMap)(lease, grouping) &&
      !getFutureFlag(lease) &&
      !getVacantFutureFlag(lease)
  );

  const currentTotalAreaWithFoodLicense = getTotalArea(
    currentLeasesWithFoodLicense,
    "L"
  );

  const occupiedLeases = filterGroupings(
    groupings,
    (lease, grouping) =>
      isOccupied(cofMap)(lease, grouping) &&
      !getFutureFlag(lease) &&
      !getVacantFutureFlag(lease)
  );
  const occupiedArea = getTotalArea(occupiedLeases, "L");

  const vacantLeases = filterGroupings(
    groupings,
    (lease) =>
      isLegendFilterType(legendMap, LegendFilterType.VACANT)(lease) &&
      !getFutureFlag(lease) &&
      !getVacantFutureFlag(lease)
  );
  const vacantArea = getTotalArea(vacantLeases, "L");

  const totalArea = occupiedArea + vacantArea;
  const totalBuildingArea = totalArea + otherBuildingArea;

  const dateRangeStart = new Date();
  const expiryDate = addMonths(dateRangeStart, expiryMonth);
  const rentReviewDate = lastDayOfMonth(
    addMonths(dateRangeStart, rentReviewMonth)
  );

  const confirmedToVacateLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.CONFIRMED_TO_VACATE)
  );
  const confirmedToVacateArea = getTotalArea(confirmedToVacateLeases, "L");

  const expiringLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.LTE_12_MONTHS)
  );
  const expiringArea = getTotalArea(expiringLeases, "L");

  const inRentReviewLeases = filterGroupings(
    groupings,
    isLegendFilterType(legendMap, LegendFilterType.RENT_REVIEW_IN_12_MONTHS)
  );
  const inRentReviewArea = getTotalArea(inRentReviewLeases, "L");

  const classMarks: [Maybe<number>, Maybe<number>][] = [
    [undefined, 499],
    [500, 999],
    [1000, 4999],
    [5000, 9999],
    [10000, undefined],
  ];

  return {
    rent: {
      totalOrAverageRent: getRentSummary(currentLeases, currentTotalArea),
      totalOrAverageRentWithFoodLicense: getRentSummary(
        currentLeasesWithFoodLicense,
        currentTotalAreaWithFoodLicense
      ),
      totalOrAverageRentWithFoodLicenseIntervals: getAreaRangeRentSummaries(
        currentLeasesWithFoodLicense,
        classMarks
      ),
    },
    area: {
      occupiedArea: {
        sqFt: occupiedArea,
        percent: getAreaPercentage(occupiedArea, totalArea),
      },
      vacantArea: {
        sqFt: vacantArea,
        percent: getAreaPercentage(vacantArea, totalArea),
      },
      totalArea: {
        sqFt: totalArea,
        percent: getAreaPercentage(totalArea, totalBuildingArea),
      },
      otherBuildingArea: {
        sqFt: otherBuildingArea,
        percent: getAreaPercentage(otherBuildingArea, totalBuildingArea),
      },
      totalBuildingArea: {
        sqFt: totalBuildingArea,
        percent: getAreaPercentage(totalBuildingArea, totalBuildingArea),
      },
      upcomingExpiryArea: {
        sqFt: expiringArea,
        percent: getAreaPercentage(expiringArea, totalBuildingArea),
      },
      upcomingExpiryDate: expiryDate,
      confirmedVacateArea: {
        sqFt: confirmedToVacateArea,
        percent: getAreaPercentage(confirmedToVacateArea, totalBuildingArea),
      },
      rentReviewArea: {
        sqFt: inRentReviewArea,
        percent: getAreaPercentage(inRentReviewArea, totalBuildingArea),
      },
      rentReviewDate: rentReviewDate,
      buildingArea: {
        sqFt: totalBuildingArea,
        percent: getAreaPercentage(totalBuildingArea, totalBuildingArea),
      },
    },
  };
}

const isCurrent =
  (cofMap: ReturnType<typeof getCOFMap>) =>
  <T extends Lease, U extends LeaseCustomization>(
    lease: T,
    grouping: Grouping<U>
  ): boolean => {
    if (grouping.customization?.includeInCalculationWithoutNewLeases != null) {
      return grouping.customization.includeInCalculationWithoutNewLeases;
    }

    const keyAttribute = getKeyAttribute(lease);

    return getRecord(cofMap, keyAttribute)?.c ?? false;
  };

const isFuture =
  (cofMap: ReturnType<typeof getCOFMap>) =>
  <T extends Lease, U extends LeaseCustomization>(
    lease: T,
    grouping: Grouping<U>
  ): boolean => {
    if (grouping.customization?.includeInCalculationWithNewLeases != null) {
      return grouping.customization.includeInCalculationWithNewLeases;
    }

    const keyAttribute = getKeyAttribute(lease);

    return getRecord(cofMap, keyAttribute)?.f ?? false;
  };

const isOccupied =
  (cofMap: ReturnType<typeof getCOFMap>) =>
  <T extends Lease, U extends LeaseCustomization>(
    lease: T,
    grouping: Grouping<U>
  ): boolean => {
    if (grouping.customization?.includeInOccupied != null) {
      return grouping.customization.includeInOccupied;
    }

    const keyAttribute = getKeyAttribute(lease);

    return getRecord(cofMap, keyAttribute)?.o ?? false;
  };

export const isLegendFilterType =
  (
    legendMap: ReturnType<typeof getLegendMap>,
    legendFilterType: LegendFilterType
  ) =>
  <T extends Lease>(lease: T): boolean => {
    // NOTE: legendMap should already considered grouping info
    const keyAttribute = getKeyAttribute(lease);
    return (
      getRecord(legendMap, keyAttribute)?.some(
        (legend) => legend.filterType === legendFilterType
      ) ?? false
    );
  };

function filterGroupings<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  ...fn: ((lease: Lease, grouping: Grouping<T>) => boolean)[]
): Grouping<T>[] {
  return fn.reduce<Grouping<T>[]>((prev, curr) => {
    const filtered: Grouping<T>[] = [];
    for (const grouping of prev) {
      const { leases } = grouping;
      const filteredLeases = leases.filter((lease) => curr(lease, grouping));
      if (filteredLeases.length === 0) {
        continue;
      }
      filtered.push({
        ...grouping,
        leases: filteredLeases,
      });
    }
    return filtered;
  }, groupings);
}

export function getRentSummary<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  totalUnitArea: number
): RentSummary {
  const faceLumpSum = groupings.reduce<number>(
    (prev, { leases, customization }) =>
      prev +
      (customization?.faceRent ??
        leases.reduce<number>(
          (prev, curr) => prev + (curr.leaseUnit?.faceRent ?? 0),
          0
        )),
    0
  );
  return {
    faceLumpSum,
    facePsf: getPsf(faceLumpSum, totalUnitArea),
    effPsf: getPsf(getEffectiveFaceRent(groupings), totalUnitArea),
  };
}

export function getTotalArea<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  calculationFloorArea: "G" | "L" | "N" | "O"
): number {
  // Dedup area by building unit id because there will be multiple lease
  // of the same building unit id denormalized
  //     1. by rent review
  const buildingUnitIdAreaMap = groupings.reduce<Record<number, number>>(
    (prev, { leases, customization }) => {
      if (
        leases[0].calculationFloorArea === calculationFloorArea &&
        customization?.unitArea != null
      ) {
        return {
          ...prev,
          [leases[0].buildingUnitID]: customization.unitArea,
        };
      }
      return {
        ...prev,
        ...leases.reduce<Record<number, number>>((prev1, lease) => {
          return {
            ...prev1,
            [lease.buildingUnitID]: (() => {
              switch (calculationFloorArea) {
                case "G":
                  return lease.grossFloorArea;
                case "L":
                  return lease.lettableFloorArea;
                case "N":
                  return lease.netFloorArea;
                case "O":
                  return lease.otherFloorArea;
                default:
                  return lease.lettableFloorArea;
              }
            })(),
          };
        }, {}),
      };
    },
    {}
  );

  return sum(Object.values(buildingUnitIdAreaMap));
}

export function getEffectiveFaceRent<T extends LeaseCustomization>(
  groupings: Grouping<T>[]
): number {
  const effectiveLeases = groupings.map(({ leases, customization }) => {
    const area =
      (customization?.unitArea || undefined) ??
      leases.reduce<number>((prev, lease) => {
        return prev + lease.lettableFloorArea;
      }, 0);

    const effectiveUnitRent =
      (customization?.effectiveUnitRent || undefined) ??
      leases[0]?.leaseUnit?.effectiveUnitRent?.effectiveUnitRent ??
      0;

    return { area, effectiveUnitRent };
  });

  return effectiveLeases.reduce<number>(
    (prev, curr) => prev + curr.effectiveUnitRent * curr.area,
    0
  );
}

export function getAreaRangeRentSummaries<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  areaRanges: Array<[Maybe<number>, Maybe<number>]>
): AreaRangeRentSummary[] {
  return areaRanges.map((areaRange) =>
    getAreaRangeRentSummary(groupings, areaRange)
  );
}

export function getAreaRangeRentSummary<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  areaRange: [Maybe<number>, Maybe<number>]
): AreaRangeRentSummary {
  const maybeMinArea = areaRange[0];
  const maybeMaxArea = areaRange[1];
  const groupingsWithinAreaRange = groupings.filter((x) => {
    const unitArea = getUnitArea(x.leases[0]);
    const outOfMinArea = maybeMinArea == null ? false : unitArea < maybeMinArea;
    const outOfMaxArea = maybeMaxArea == null ? false : unitArea > maybeMaxArea;
    return !outOfMinArea && !outOfMaxArea;
  });
  return {
    ...getRentSummary(
      groupingsWithinAreaRange,
      getTotalArea(groupingsWithinAreaRange, "L")
    ),
    minArea: maybeMinArea ?? undefined,
    maxArea: maybeMaxArea ?? undefined,
  };
}

function getAreaPercentage(area: number, totalArea: number) {
  return totalArea === 0 ? 0 : (area * 100) / totalArea;
}

function getPsf(rent: number, unitArea: number) {
  return unitArea === 0 ? 0 : rent / unitArea;
}

import * as yup from "yup";
import { BuildingSchema } from "./Building";

export const MOSchema = yup.object({
  id: yup.number().required(),
  code: yup.string().required(),
  name: yup.string().required(),
  nameHk: yup.string().required(),
  nameCn: yup.string().required(),
  active: yup.boolean().required(),
  buildings: yup.array().of(BuildingSchema).required(),
  hasRetailStackingPlan: yup.boolean().required(),
});

export type MO = yup.InferType<typeof MOSchema>;

export const MOListSchema = yup.array().of(MOSchema).required();

import * as yup from "yup";

import { PlsDateSchema } from "./PlsDate";

export enum RentReviewType {
  OMR = "OMR",
  OTHERS = "Others",
}

export const LeaseRentReviewSchema = yup.object({
  rentReviewDate: PlsDateSchema.required(),
  rentReviewType: yup
    .mixed<RentReviewType>()
    .oneOf([RentReviewType.OMR, RentReviewType.OTHERS])
    .required(),
  minimumRent: yup.number().nullable(),
  maximumRent: yup.number().nullable(),
  minimumPercentage: yup.number().nullable(),
  maximumPercentage: yup.number().nullable(),
  effectiveRent: yup.number().nullable(),
  rentReviewStatus: yup.string().nullable(),
});

export type LeaseRentReview = yup.InferType<typeof LeaseRentReviewSchema>;

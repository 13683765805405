var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-o-grand-total-summary__container"},[(_vm.grandTotalSummary == null)?_c('div',{staticClass:"m-o-grand-total-summary__loading-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"34"}})],1):_vm._e(),(_vm.grandTotalSummary != null)?_c('div',{staticClass:"m-o-grand-total-summary__content"},[_c('div',{staticClass:"m-o-grand-total-summary__sheet-heading"},[_vm._v(" "+_vm._s(_vm.$skp.t("mOGrandTotalSummary.heading.summary"))+" ")]),_c('div',{staticClass:"m-o-grand-total-summary__tabs"},[_c('div',{staticClass:"m-o-grand-total-summary__tab m-o-grand-total-summary__tab-selection",class:{
          'm-o-grand-total-summary__tab-selection--selected': _vm.tabIndex === 0,
        },on:{"click":function($event){_vm.tabIndex = 0}}},[_vm._v(" "+_vm._s(_vm.$skp.t("mOGrandTotalSummary.tab.grandTotal"))+" ")]),_c('div',{staticClass:"m-o-grand-total-summary__tab m-o-grand-total-summary__tab-selection m-o-grand-total-summary__tab--hidden-in-sm",class:{
          'm-o-grand-total-summary__tab-selection--selected': _vm.tabIndex === 1,
        },on:{"click":function($event){_vm.tabIndex = 1}}},[_vm._v(" "+_vm._s(_vm.$skp.t("mOGrandTotalSummary.tab.buildingView"))+" ")]),_c('div',{staticClass:"m-o-grand-total-summary__tab m-o-grand-total-summary__tab--hidden-in-lg",on:{"click":function($event){_vm.tabIndex = 1}}},[_c('v-select',{staticClass:"select",attrs:{"items":_vm.levelOptions,"item-value":"itemValue","item-disabled":"disabled","menu-props":{
            offsetY: true,
            nudgeTop: -5,
            zIndex: 20,
          },"hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"m-o-grand-total-summary__tab-selection",class:{
                'm-o-grand-total-summary__tab-selection--selected':
                  _vm.tabIndex === 1,
              }},[_vm._v(" "+_vm._s(item.itemValue === "building" ? _vm.$skp.t("mOGrandTotalSummary.tab.atBuildingLevel") : _vm.$skp.t("mOGrandTotalSummary.tab.atStreetLevel"))+" ")])]}},{key:"item",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.itemValue === "building" ? _vm.$skp.t("mOGrandTotalSummary.tab.atBuildingLevel") : _vm.$skp.t("mOGrandTotalSummary.tab.atStreetLevel"))+" ")])]}}],null,false,2628484980),model:{value:(_vm.selectedLevelOption),callback:function ($$v) {_vm.selectedLevelOption=$$v},expression:"selectedLevelOption"}})],1)]),_c('div',{staticClass:"m-o-grand-total-summary__tabs-slider-container"},[_c('div',{staticClass:"m-o-grand-total-summary__tabs-slider",class:{
          'm-o-grand-total-summary__tabs-slider--grand-total': _vm.tabIndex === 0,
          'm-o-grand-total-summary__tabs-slider--building-view':
            _vm.tabIndex === 1,
        }})]),_c('v-tabs-items',{staticClass:"m-o-grand-total-summary__tabs-items",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab-item',[_c('div',{staticClass:"m-o-grand-total-summary__tabs-item"},[_c('grand-total-summary-table',{attrs:{"grandTotalSummary":_vm.grandTotalSummary,"retail":true}})],1)]),_c('v-tab-item',[_c('div',{staticClass:"m-o-grand-total-summary__tabs-item",attrs:{"tile":""}},[_c('food-license-summary-table',{attrs:{"buildingLevelFoodLicenseSummary":_vm.buildingLevelFoodLicenseSummary,"streetLevelFoodLicenseSummary":_vm.streetLevelFoodLicenseSummary,"selectedLevel":_vm.selectedLevelOption,"buildingSummary":_vm.grandTotalSummary}})],1)])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
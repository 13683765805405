export type Maybe<T> = T | null | undefined;

export function maybe<T>(value: Maybe<T>, fallback: T) {
  return value ?? fallback;
}

export function isNonNullable<T>(value: Maybe<T>): value is T {
  return value != null;
}

export function maybeApply<T, U>(
  fn: (value: T) => U,
  value: Maybe<T>
): Maybe<U> {
  if (!value) {
    return null;
  }
  return fn(value);
}

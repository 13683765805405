import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";

export function isSameOrBeforeDay(date: Date, dateToCompare: Date) {
  return isBefore(date, dateToCompare) || isSameDay(date, dateToCompare);
}

export function isSameOrAfterDay(date: Date, dateToCompare: Date) {
  return isAfter(date, dateToCompare) || isSameDay(date, dateToCompare);
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"building-view-carousel__container"},[(_vm.showHeading)?_c('div',{staticClass:"building-view-carousel__heading"},[_vm._v(" "+_vm._s(_vm.$skp.t( "buildingViewCarousel.heading", _vm.views.length > 0 ? _vm.itemIndex + 1 : 0, _vm.views.length ))+" ")]):_vm._e(),_c('div',{staticClass:"building-view-carousel__controls"},[(_vm.showZoomControl)?_c('v-btn',{staticClass:"building-view-carousel__control-button",attrs:{"icon":""},on:{"click":_vm.handleZoomClick}},[_c('icon-zoom-in-svg')],1):_vm._e(),(_vm.showCloseControl)?_c('v-btn',{staticClass:"building-view-carousel__control-button",attrs:{"icon":""},on:{"click":_vm.handleCloseClick}},[_c('icon-close-svg')],1):_vm._e()],1),(_vm.showCarousel)?_c('div',{staticClass:"building-view-carousel__carousel"},[_c('hooper',{ref:"hooper",staticClass:"building-view-carousel__carousel-hooper",class:{
        'building-view-carousel__carousel-hooper--hidden':
          _vm.lazyLoadHooper && !_vm.showHooper,
      },attrs:{"wheelControl":false},on:{"slide":_vm.handleHooperSlide}},_vm._l((_vm.views),function(view,i){return _c('slide',{key:i,staticClass:"building-view-carousel__carousel-slide"},[_c('div',{staticClass:"building-view-carousel__carousel-image-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('OSSImage',{staticClass:"building-view-carousel__carousel-image",attrs:{"fileName":view.fileName ?? undefined,"width":"100%","height":"100%","contain":""}})],1)}),1),(_vm.lazyLoadHooper && !_vm.showHooper)?_c('div',{staticClass:"building-view-carousel__carousel-hooper-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e()],1):_c('div',{staticClass:"building-view-carousel__empty-label"},[_vm._v(" "+_vm._s(_vm.$skp.t("buildingViewCarousel.empty"))+" ")]),(_vm.showCarousel && _vm.showThumbnails)?_c('div',{staticClass:"building-view-carousel__thumbnails"},_vm._l((_vm.views),function(view,i){return _c('v-sheet',{key:i,staticClass:"building-view-carousel__thumbnail",class:{
        'building-view-carousel__thumbnail--selected': _vm.itemIndex === i,
      },on:{"click":function($event){return _vm.handleItemClick(i)}}},[_c('OSSImage',{staticClass:"building-view-carousel__thumbnail-image",attrs:{"fileName":view.fileName ?? undefined,"width":"100%","height":"100%","contain":""}})],1)}),1):_vm._e(),(_vm.showCarousel)?_c('div',{staticClass:"building-view-carousel__carousel-delimiters"},_vm._l((_vm.views),function(_,i){return _c('div',{key:i,staticClass:"building-view-carousel__carousel-delimiter",on:{"click":function($event){return _vm.handleItemClick(i)}}},[_c('div',{class:[
          'building-view-carousel__carousel-delimiter-dot',
          {
            'building-view-carousel__carousel-delimiter-dot--selected':
              _vm.itemIndex === i,
          },
        ]})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import * as yup from "yup";

import { Maybe } from "../types/Maybe";

export enum LegendFilterGroup {
  STATUS = "STATUS",
  ZONE = "ZONE",
  CALCULATION = "CALCULATION",
}

export enum LegendFilterType {
  VACANT = "VACANT",
  LIKELY_TO_VACATE = "LIKELY_TO_VACATE",
  CONFIRMED_TO_VACATE = "CONFIRMED_TO_VACATE",
  LTE_12_MONTHS = "LTE_12_MONTHS",
  GT_12_MONTHS = "GT_12_MONTHS",
  RENT_REVIEW_IN_12_MONTHS = "RENT_REVIEW_IN_12_MONTHS",
  VULNERABLE = "VULNERABLE",
  HIGHEST_ZONE = "HIGHEST_ZONE",
  HIGH_ZONE = "HIGH_ZONE",
  MID_ZONE = "MID_ZONE",
  LOW_ZONE = "LOW_ZONE",
  CALCULATION_C = "CALCULATION_C",
  CALCULATION_O = "CALCULATION_O",
  CALCULATION_F = "CALCULATION_F",
}

export enum LegendIconType {
  STROKE = "STROKE",
  FILL = "FILL",
  LETTER = "LETTER",
}

const LegendFilterGroupSchema = yup
  .mixed<LegendFilterGroup>()
  .oneOf([
    LegendFilterGroup.STATUS,
    LegendFilterGroup.ZONE,
    LegendFilterGroup.CALCULATION,
  ]);

const LegendFilterTypeSchema = yup
  .mixed<LegendFilterType>()
  .oneOf([
    LegendFilterType.VACANT,
    LegendFilterType.LIKELY_TO_VACATE,
    LegendFilterType.CONFIRMED_TO_VACATE,
    LegendFilterType.LTE_12_MONTHS,
    LegendFilterType.GT_12_MONTHS,
    LegendFilterType.RENT_REVIEW_IN_12_MONTHS,
    LegendFilterType.VULNERABLE,
    LegendFilterType.HIGHEST_ZONE,
    LegendFilterType.HIGH_ZONE,
    LegendFilterType.MID_ZONE,
    LegendFilterType.LOW_ZONE,
    LegendFilterType.CALCULATION_C,
    LegendFilterType.CALCULATION_O,
    LegendFilterType.CALCULATION_F,
  ]);

const LegendIconTypeSchema = yup
  .mixed<LegendIconType>()
  .oneOf([LegendIconType.STROKE, LegendIconType.FILL, LegendIconType.LETTER]);

export const LegendSchema = yup.object({
  id: yup.number().required(),
  displaySeq: yup.number().required(),
  color: yup.string().required(),
  codeValue: yup.string().required(),
  filterGroup: LegendFilterGroupSchema.required(),
  filterType: LegendFilterTypeSchema.required(),
  iconType: LegendIconTypeSchema.required(),
  iconLetter: yup.string().nullable(),
});

export type Legend = yup.InferType<typeof LegendSchema>;

export const LegendListSchema = yup.array().of(LegendSchema).required();

export function findLegendByZone(
  legends: Legend[],
  zone: Maybe<string>
): Legend | undefined {
  return legends.find((legend) => {
    switch (zone) {
      case "Highest Zone":
        return legend.filterType === LegendFilterType.HIGHEST_ZONE;
      case "High Zone":
        return legend.filterType === LegendFilterType.HIGH_ZONE;
      case "Mid Zone":
        return legend.filterType === LegendFilterType.MID_ZONE;
      case "Low Zone":
        return legend.filterType === LegendFilterType.LOW_ZONE;
      default:
        return false;
    }
  });
}

export const isImageDimensionsLessThan =
  (width: number, height: number) => (image: HTMLImageElement) => {
    return image.width < width || image.height < height;
  };

export const imageFromFile = async (file: File): Promise<HTMLImageElement> => {
  const image = new Image();
  image.src = URL.createObjectURL(file);
  return new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
};

import * as yup from "yup";
import { KeyAttribute } from "../types/LeaseKeyAttribute";
import { COF } from "../utils/lease";

export const UnitFacilitySchema = yup.object({
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  facility: yup.string().required(),
});

export const UnitFeatureSchema = yup.object({
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  feature: yup.string().required(),
});

export const LicensePeriodSchema = yup.object({
  months: yup.number().required(),
  days: yup.number().required(),
  displaySeq: yup.number().required(),
});

export const RentFreePeriodSchema = yup.object({
  months: yup.number().required(),
  days: yup.number().required(),
  displaySeq: yup.number().required(),
});

export const TurnoverRateSchema = yup.object({
  turnoverRate: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  displaySeq: yup.number().required(),
});

export type TurnoverRate = yup.InferType<typeof TurnoverRateSchema>;

export const OfficeLeaseCustomizationSchema = yup.object({
  id: yup.number().required(),
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  unitName: yup.string().nullable(),
  unitArea: yup.number().nullable(),
  tenantName: yup.string().nullable(),
  faceRent: yup.number().nullable(),
  faceUnitRent: yup.number().nullable(),
  effectiveUnitRent: yup.number().nullable(),
  licensePeriods: yup.array().of(LicensePeriodSchema.required()).nullable(),
  rentFreePeriods: yup.array().of(RentFreePeriodSchema.required()).nullable(),
  status: yup.string().nullable(),
  remarks: yup.string().nullable(),
  facilitiesRemarks: yup.string().nullable(),
  includeInCalculationWithoutNewLeases: yup.boolean().nullable(),
  includeInCalculationWithNewLeases: yup.boolean().nullable(),
  includeInOccupied: yup.boolean().nullable(),
  excludeInSummary: yup.boolean().required(),
  lastUpdatedDate: yup.date().required(),
});

export type OfficeLeaseCustomization = yup.InferType<
  typeof OfficeLeaseCustomizationSchema
>;

export const RetailLeaseCustomizationSchema = yup.object({
  id: yup.number().required(),
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  unitArea: yup.number().nullable(),
  tenantName: yup.string().nullable(),
  faceRent: yup.number().nullable(),
  faceUnitRent: yup.number().nullable(),
  effectiveUnitRent: yup.number().nullable(),
  licensePeriods: yup.array().of(LicensePeriodSchema.required()).required(),
  rentFreePeriods: yup.array().of(RentFreePeriodSchema.required()).required(),
  turnoverRates: yup.array().of(TurnoverRateSchema.required()).required(),
  foodLicense: yup.string().nullable(),
  status: yup.string().nullable(),
  excludeInSummary: yup.boolean().required(),
  outsideSittingArea: yup.boolean().required(),
  includeInCalculationWithoutNewLeases: yup.boolean().nullable(),
  includeInCalculationWithNewLeases: yup.boolean().nullable(),
  includeInOccupied: yup.boolean().nullable(),
  version: yup.number().required(),
  lastUpdatedDate: yup.date().required(),
});

export type RetailLeaseCustomization = yup.InferType<
  typeof RetailLeaseCustomizationSchema
>;

export const RetailLeaseCustomizationListSchema = yup
  .array()
  .of(RetailLeaseCustomizationSchema.required())
  .required();

export type UnitFeature = yup.InferType<typeof UnitFeatureSchema>;

export type UnitFacility = yup.InferType<typeof UnitFacilitySchema>;

export const OfficeLeaseCustomizationListSchema = yup
  .array()
  .of(OfficeLeaseCustomizationSchema.required())
  .required();

export type LeaseCustomization =
  | OfficeLeaseCustomization
  | RetailLeaseCustomization;

export function getKeyAttribute(
  obj:
    | UnitFeature
    | UnitFacility
    | OfficeLeaseCustomization
    | RetailLeaseCustomization
): KeyAttribute {
  return {
    plsBuildingUnitId: obj.plsBuildingUnitId,
    plsLeaseUnitId: `${obj.plsLeaseUnitId ?? ""}`,
    effectiveUnitRentStartDateStr:
      obj.effectiveUnitRentStartDate?.toISOString() ?? "",
  };
}

export function mapRetailLeaseCustomizationToOfficeLeaseCustomization(
  customization: RetailLeaseCustomization
): OfficeLeaseCustomization {
  return {
    ...customization,
    unitName: null,
    remarks: null,
    facilitiesRemarks: null,
  };
}

export function getCustomizedCof(
  customization: OfficeLeaseCustomization | RetailLeaseCustomization
): COF | null {
  const {
    includeInCalculationWithNewLeases,
    includeInCalculationWithoutNewLeases,
    includeInOccupied,
  } = customization;
  if (
    includeInCalculationWithNewLeases != null &&
    includeInCalculationWithoutNewLeases != null &&
    includeInOccupied != null
  ) {
    return {
      f: includeInCalculationWithNewLeases,
      c: includeInCalculationWithoutNewLeases,
      o: includeInOccupied,
    };
  }
  return null;
}

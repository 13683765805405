
import { PropType, defineComponent } from "vue";

import formatDate from "../filters/date";
import { deriveNegotiationDescription } from "../utils/negotiation";
import { Negotiation } from "../models/Negotiation";

export default defineComponent({
  name: "NegotiationSummary",

  props: {
    negotiation: {
      type: Object as PropType<Negotiation>,
      required: true,
    },
  },

  filters: {
    formatDate,
    deriveNegotiationDescription,
  },
});

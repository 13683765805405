import { Module } from "vuex";

import { getKeyAttribute, OfficeLease, RetailLease } from "../models/Lease";
import {
  getRecord,
  getValuesFromRecords,
  KeyAttribute,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

interface State {
  byKeyAttributeOffice: RecordByKeyAttribute<OfficeLease>;
  byKeyAttributeRetail: RecordByKeyAttribute<RetailLease>;
}

const getOfficeLeases = (state: State) => (): OfficeLease[] =>
  getValuesFromRecords(state.byKeyAttributeOffice);
const getRetailLeases = (state: State) => (): RetailLease[] =>
  getValuesFromRecords(state.byKeyAttributeRetail);

const getters = {
  getLeases: getOfficeLeases,
  getRetailLeases,
  getOfficeLeaseByKeyAttribute:
    (state: State) =>
    (keyAttribute: KeyAttribute): Maybe<OfficeLease> => {
      return getRecord(state.byKeyAttributeOffice, keyAttribute);
    },
  getRetailLeaseByKeyAttribute:
    (state: State) =>
    (keyAttribute: KeyAttribute): Maybe<RetailLease> => {
      return getRecord(state.byKeyAttributeRetail, keyAttribute);
    },
  getLeasesByBuildingId:
    (state: State) =>
    (plsBuildingIds: number[]): OfficeLease[] => {
      return getOfficeLeases(state)().filter(
        (lease) => plsBuildingIds.indexOf(lease.buildingID) > -1
      );
    },
  getRetailLeasesByMoCode:
    (state: State) =>
    (moCode: string): RetailLease[] => {
      return getRetailLeases(state)().filter(
        (lease) => lease.managementOfficeCode === moCode
      );
    },
};

export const leaseModule: Module<State, unknown> = {
  state: {
    byKeyAttributeOffice: {},
    byKeyAttributeRetail: {},
  },
  getters,
  mutations: {
    fillOfficeLeases(state, payload: OfficeLease[]) {
      for (const lease of payload) {
        state.byKeyAttributeOffice = setRecord(
          state.byKeyAttributeOffice,
          getKeyAttribute(lease),
          () => lease
        );
      }
    },
    fillRetailLeases(state, payload: RetailLease[]) {
      for (const lease of payload) {
        state.byKeyAttributeRetail = setRecord(
          state.byKeyAttributeRetail,
          getKeyAttribute(lease),
          () => lease
        );
      }
    },
  },
  actions: {
    fillOfficeLeases(context, payload: OfficeLease[]) {
      context.commit("fillOfficeLeases", payload);
    },
    fillRetailLeases(context, payload: RetailLease[]) {
      context.commit("fillRetailLeases", payload);
    },
  },
  modules: {},
};

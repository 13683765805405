
import { defineComponent } from "vue";

export default defineComponent({
  name: "BuildingRemarksUnsavedChangesDialog",

  props: {
    value: Boolean,
  },

  emits: ["input", "cancelClick", "leaveClick"],

  methods: {
    handleInput(value: boolean) {
      this.$emit("input", value);
    },
    handleCancelClick() {
      this.$emit("cancelClick");
    },
    handleLeaveClick() {
      this.$emit("leaveClick");
    },
  },
});

export function clamp(value: number, range: [number, number]): number {
  const [min, max] = range;
  return Math.min(Math.max(value, min), max);
}

export function clampDecimalDigits(
  value: number,
  integerDigits: number,
  fractionalDigits: number
): number {
  const min = 0;
  const max = Math.pow(10, integerDigits) - Math.pow(10, -fractionalDigits);
  return parseFloat(clamp(value, [min, max]).toFixed(fractionalDigits));
}

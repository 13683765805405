import Vue from "vue";
import Vuex, { Store } from "vuex";
import StackingPlan, {
  StackingPlanPluginOptions,
  fetchEventEmitter,
} from "@swire-pmp-web/stacking-plan";

import App from "./App.vue";
import { vuetify, i18n } from "./plugins/vuetify";
import { router } from "./router";
import { setCookies, getToken } from "./utils/auth";
import "./styles/fonts.scss";
import { ossDownloadFile, uploadFile } from "./utils/oss";
import { filterCodeMapData } from "./utils/filter";

Vue.config.productionTip = false;

Vue.use(Vuex);
const store = new Store({});

const timestamp = new Date().getTime();

fetch(`config.json?timestamp=${timestamp}`)
  .then((resp) => resp.json())
  .then((res) => {
    setCookies("baseUrl", res.baseUrl);
    Vue.prototype.KEY = res.KEY;
    Vue.prototype.publicBaseURL = res.baseUrl;
    Vue.prototype.clientId = res.clientId;
    Vue.prototype.authority = res.authority;
    Vue.prototype.redirectUri = res.redirectUri;
  });

Vue.use<StackingPlanPluginOptions>(StackingPlan, {
  baseUrlFactory: () => process.env.VUE_APP_BASE_API,
  headersFactory: () => ({
    Authorization: `Bearer ${getToken()}`,
  }),
  i18n,
  store,
  ossConfig: {
    resolveFilePath: ossDownloadFile,
    upload: uploadFile,
  },
  getOptionsMap: {
    leaseCustomizationFacilitieOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "STACKING_PLAN_LEASE_CUSTOMIZATION_FACILITY",
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    leaseCustomizationStatusOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "STACKING_PLAN_LEASE_CUSTOMIZATION_STATUS",
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    leaseCustomizationUnitFeatureOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "STACKING_PLAN_LEASE_CUSTOMIZATION_UNIT_FEATURE",
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    legendLabelOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "STACKING_PLAN_LEGEND_LABEL", //
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    legalDocumentTypeOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "LEGAL_DOCUMENT_TYPE", //
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    negotiationTypeOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "NEGOTIATION_TYPE", //
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
    leaseCustomizationFoodLicenseOptions: () => {
      const locale = localStorage.getItem("i18nLocale");
      return filterCodeMapData(
        "STACKING_PLAN_LEASE_CUSTOMIZATION_FOOD_LICENSE", //
        locale
      ).map(({ value, description }) => ({
        value,
        name: description,
      }));
    },
  },
  getPermissions: () => {
    return JSON.parse(sessionStorage.getItem("OperationCodes") ?? "[]");
  },
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

fetchEventEmitter.on("before-fetch", () => console.info("before-fetch"));
fetchEventEmitter.on("after-fetch", () => console.info("after-fetch"));
fetchEventEmitter.on("error", ({ error: e }) => {
  const el = document.getElementById("error-messages");
  if (el) {
    const m = document.createElement("div");
    m.innerText = e?.stack ?? "";
    el.appendChild(m);
    m.scrollIntoView({ behavior: "smooth", block: "end" });
  }
});

import * as yup from "yup";

export enum FloorZone {
  HIGHEST_ZONE = "Highest Zone",
  HIGH_ZONE = "High Zone",
  MID_ZONE = "Mid Zone",
  LOW_ZONE = "Low Zone",
}

export const FloorSchema = yup.object({
  id: yup.number().required(),
  buildingCode: yup.string().required(),
  displaySeq: yup.number().required(),
  floorName: yup.string().required(),
  zone: yup.string().nullable(),
  plsBuildingFloorId: yup.number().nullable(),
});

/**
 * Negative id means imaginary from lease
 */
export type Floor = yup.InferType<typeof FloorSchema>;

export const FloorListSchema = yup.array().of(FloorSchema).required();

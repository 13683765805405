import * as yup from "yup";
import { File } from "../models/File";
import {
  PlsBuildingUnitLease,
  PlsBuildingUnitLeaseFloorPlanImageFileList,
  PlsBuildingUnitLeaseFloorPlanImageFileListSchema,
  PlsBuildingUnitLeaseSchema,
} from "../models/PlsBuildingUnitLease";
import { Maybe } from "../types/Maybe";
import apiClient from "../utils/apiClient";

export const getPlsBuildingUnitLeaseListByBuildingCode = async (
  buildingCode: string,
  plsBuildingUnitIds: number[]
): Promise<PlsBuildingUnitLease[]> => {
  if (plsBuildingUnitIds.length === 0) {
    return [];
  }
  const response = await apiClient.get(
    `stackingplan/pls-building-unit-leases/building-code/${buildingCode}?plsBuildingUnitIds=${plsBuildingUnitIds.join(
      ","
    )}`
  );
  return await yup
    .array()
    .of(PlsBuildingUnitLeaseSchema.required())
    .required()
    .validate(response);
};

export const getPlsBuildingUnitLeaseListByMOCode = async (
  moCode: string,
  plsBuildingUnitIds: number[]
): Promise<PlsBuildingUnitLease[]> => {
  if (plsBuildingUnitIds.length === 0) {
    return [];
  }
  const response = await apiClient.get(
    `stackingplan/pls-building-unit-leases/mo-code/${moCode}?plsBuildingUnitIds=${plsBuildingUnitIds.join(
      ","
    )}`
  );
  return await yup
    .array()
    .of(PlsBuildingUnitLeaseSchema.required())
    .required()
    .validate(response);
};

export const createPlsBuildingUnitLeaseRemarks = async (
  moCode: string,
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>,
  remarks: string
): Promise<PlsBuildingUnitLease> => {
  const response = await apiClient.post(
    `stackingplan/pls-building-unit-leases/remarks`,
    {
      moCode,
      buildingCode,
      plsBuildingUnitId,
      plsLeaseUnitId,
      effectiveUnitRentStartDate:
        effectiveUnitRentStartDate?.toISOString() ?? null,
      remarks,
    }
  );
  return await PlsBuildingUnitLeaseSchema.validate(response);
};

export const updatePlsBuildingUnitLeaseRemarks = async (
  moCode: string,
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>,
  remarks: string
): Promise<PlsBuildingUnitLease> => {
  const response = await apiClient.put(
    `stackingplan/pls-building-unit-leases/remarks`,
    {
      moCode,
      buildingCode,
      plsBuildingUnitId,
      plsLeaseUnitId,
      effectiveUnitRentStartDate:
        effectiveUnitRentStartDate?.toISOString() ?? null,
      remarks,
    }
  );
  return await PlsBuildingUnitLeaseSchema.validate(response);
};

export const getPlsBuildingUnitLeaseFloorPlanImage = async (
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>
): Promise<PlsBuildingUnitLeaseFloorPlanImageFileList> => {
  const response = await apiClient.get(
    `stackingplan/pls-building-unit-leases/floor-plan-image?buildingCode=${buildingCode}&plsBuildingUnitId=${plsBuildingUnitId}&plsLeaseUnitId=${
      plsLeaseUnitId ?? ""
    }&effectiveUnitRentStartDate=${
      effectiveUnitRentStartDate?.toISOString() ?? ""
    }`
  );
  return await PlsBuildingUnitLeaseFloorPlanImageFileListSchema.required().validate(
    response
  );
};

export const createPlsBuildingUnitLeaseFloorPlanImage = async (
  moCode: string,
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>,
  fileDto: File
): Promise<PlsBuildingUnitLeaseFloorPlanImageFileList> => {
  const response = await apiClient.post(
    `stackingplan/pls-building-unit-leases/floor-plan-image`,
    {
      moCode,
      buildingCode,
      plsBuildingUnitId,
      plsLeaseUnitId,
      effectiveUnitRentStartDate:
        effectiveUnitRentStartDate?.toISOString() ?? null,
      fileDto,
    }
  );
  return await PlsBuildingUnitLeaseFloorPlanImageFileListSchema.required().validate(
    response
  );
};

export const updatePlsBuildingUnitLeaseFloorPlanImage = async (
  moCode: string,
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>,
  fileDto: File
): Promise<PlsBuildingUnitLeaseFloorPlanImageFileList> => {
  const response = await apiClient.put(
    `stackingplan/pls-building-unit-leases/floor-plan-image`,
    {
      moCode,
      buildingCode,
      plsBuildingUnitId,
      plsLeaseUnitId,
      effectiveUnitRentStartDate:
        effectiveUnitRentStartDate?.toISOString() ?? null,
      fileDto,
    }
  );
  return await PlsBuildingUnitLeaseFloorPlanImageFileListSchema.required().validate(
    response
  );
};

export const removePlsBuildingUnitLeaseFloorPlanImage = async (
  buildingCode: string,
  plsBuildingUnitId: number,
  plsLeaseUnitId: Maybe<number>,
  effectiveUnitRentStartDate: Maybe<Date>
): Promise<PlsBuildingUnitLeaseFloorPlanImageFileList> => {
  const response = await apiClient.delete(
    `stackingplan/pls-building-unit-leases/floor-plan-image?buildingCode=${buildingCode}&plsBuildingUnitId=${plsBuildingUnitId}&plsLeaseUnitId=${
      plsLeaseUnitId ?? ""
    }&effectiveUnitRentStartDate=${
      effectiveUnitRentStartDate?.toISOString() ?? ""
    }`
  );
  return await PlsBuildingUnitLeaseFloorPlanImageFileListSchema.required().validate(
    response
  );
};

import * as yup from "yup";

export const FileSchema = yup.object({
  fileName: yup.string().nullable(),
  fileRealName: yup.string().nullable(),
  filePath: yup.string().nullable(),
  fileSuffix: yup.string().nullable(),
  fileSize: yup.string().nullable(),
});

export type File = yup.InferType<typeof FileSchema>;

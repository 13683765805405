import { PluginObject } from "vue";
import { Store } from "vuex";
import VueI18n from "vue-i18n";

import { setupLocales } from "./locales";
import { setupStores } from "./stores";
import { BaseUrlFactory, HeadersFactory, setupFetch } from "./utils/fetch";
import { OSSConfig, setupOSSConfig } from "./utils/oss";
import { setupGetOptions, GetOptionsMap } from "./utils/options";
import { setupGetPermissions, GetPermissions } from "./utils/permissions";
import StackingPlanView from "./views/StackingPlanView.vue";

export { fetchEventEmitter } from "./events/fetchEventEmitter";
export { StackingPlanView };

export interface PluginOptions {
  baseUrlFactory: BaseUrlFactory;
  headersFactory?: HeadersFactory;
  i18n: VueI18n;
  store: Store<unknown>;
  ossConfig: OSSConfig;
  getOptionsMap: GetOptionsMap;
  getPermissions: GetPermissions;
}

export const plugin: PluginObject<PluginOptions> = {
  install: (Vue, options) => {
    if (options == null) {
      throw new Error("Missing plugin options");
    }

    const {
      // Plugin options
      baseUrlFactory,
      headersFactory,
      i18n,
      store,
      ossConfig,
      getOptionsMap,
      getPermissions,
    } = options;

    setupLocales(i18n, Vue);
    setupFetch(baseUrlFactory, headersFactory);
    setupStores(store);
    setupOSSConfig(ossConfig);
    setupGetOptions(getOptionsMap);
    setupGetPermissions(getPermissions, Vue);
  },
};

import apiClient from "../utils/apiClient";

import { Legend, LegendListSchema } from "../models/Legend";

export const getBuildingStackingPlanXlsx = async (buildingCode: string) => {
  const blob = await apiClient.download(
    `stackingplan/buildings/${buildingCode}/stacking-plan.xlsx`
  );
  return blob;
};

export const getMoRetailStackingPlanXlsx = async (moCode: string) => {
  const blob = await apiClient.download(
    `stackingplan/mos/${moCode}/retail-stacking-plan.xlsx`
  );
  return blob;
};

export const getLegendList = async (): Promise<Legend[]> => {
  const response = await apiClient.get(
    `stackingplan/legends` //
  );
  const result = await LegendListSchema.validate(response);
  return result;
};

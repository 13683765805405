
import {
  failure,
  initial,
  isPending,
  pending,
  RemoteData,
  success,
} from "@devexperts/remote-data-ts";
import { defineComponent, PropType } from "vue";
import { Maybe } from "yup/lib/types";
import { updateLeaseCustomization } from "../apis/leaseCustomization";
import formatNumber from "../filters/formatNumber";
import prefix from "../filters/prefix";
import suffix from "../filters/suffix";
import { getKeyAttribute } from "../models/Lease";
import { LeaseAndCustomization } from "../types/LeaseAndCustomization";
import { maybeApply } from "../types/Maybe";
import { clampDecimalDigits } from "../utils/clamp";
import LeaseCustomizationFormChipsInput from "./LeaseCustomizationFormChipsInput.vue";
import LeaseCustomizationFormMonthsDaysInput from "./LeaseCustomizationFormMonthsDaysInput.vue";
import LeaseCustomizationFormUnitFacilityRow from "./LeaseCustomizationFormUnitFacilityRow.vue";
import {
  FormState,
  FormValueBase,
  generateDefaultUnitFeatureFormState,
  makeRequestBody,
  SelectOption,
  UnitFacilityFormState,
} from "./LeaseCustomizationFormViewModel";
import TextDialog, { TextDialogConfig } from "./TextDialog.vue";

export default defineComponent({
  name: "LeaseCustomizationForm",

  components: {
    LeaseCustomizationFormUnitFacilityRow,
    TextDialog,
    LeaseCustomizationFormMonthsDaysInput,
    LeaseCustomizationFormChipsInput,
  },

  emits: ["submitSuccess", "cancel"],

  props: {
    buildingCode: String,
    statusOptions: Array as PropType<SelectOption<string>[]>,
    availableUnits: Array as PropType<SelectOption<number>[]>,
    availableFacilities: Array as PropType<SelectOption<string>[]>,
    availableUnitFeatures: Array as PropType<SelectOption<string>[]>,
    initialEnableCustomization: Boolean,
    initialFormState: Object as PropType<FormState>,
    formValueBase: Object as PropType<FormValueBase>,
    leaseAndCustomizations: Array as PropType<LeaseAndCustomization[]>,
  },

  data(): {
    enableCustomization: boolean;
    formState: FormState;
    submitRequestState: RemoteData<Error, unknown>;
    cancelDialogIsOpen: boolean;
    confirmDialogIsOpen: boolean;
    isFormDirty: boolean;
  } {
    return {
      enableCustomization: this.initialEnableCustomization,
      formState: {
        unitArea: this.initialFormState?.unitArea ?? null,
        tenantName: this.initialFormState?.tenantName ?? null,
        faceRent: this.initialFormState?.faceRent ?? null,
        faceUnitRent: this.initialFormState?.faceUnitRent ?? null,
        effectiveUnitRent: this.initialFormState?.effectiveUnitRent ?? null,
        licensePeriods: this.initialFormState?.licensePeriods ?? [],
        rentFreePeriods: this.initialFormState?.rentFreePeriods ?? [],
        status: this.initialFormState?.status ?? null,
        unitFacilities: this.initialFormState?.unitFacilities ?? [],
        enableUnitFeatures: this.initialFormState?.enableUnitFeatures ?? false,
        unitFeatures: this.initialFormState?.unitFeatures ?? [],
        includeInCalculationWithoutNewLeases:
          this.initialFormState?.includeInCalculationWithoutNewLeases ?? false,
        includeInCalculationWithNewLeases:
          this.initialFormState?.includeInCalculationWithNewLeases ?? false,
        includeInOccupied: this.initialFormState?.includeInOccupied ?? false,
        excludeInSummary: this.initialFormState?.excludeInSummary ?? false,
      },
      submitRequestState: initial,
      cancelDialogIsOpen: false,
      confirmDialogIsOpen: false,
      isFormDirty: false,
    };
  },

  watch: {
    formState: {
      handler() {
        this.isFormDirty = true;
      },
      deep: true,
    },
  },

  computed: {
    isSubmitLoading() {
      return isPending(this.submitRequestState);
    },

    cancelDialogConfig(): TextDialogConfig {
      return {
        title: this.$skp.t("leaseCustomization.cancelDialog.title"),
        message: this.$skp.t("leaseCustomization.cancelDialog.message"),
        buttons: [
          {
            text: this.$skp.t(
              "leaseCustomization.cancelDialog.confirmButtonText"
            ),
            style: {
              depressed: true,
            },
            color: "primary",
            action: () => {
              this.cancelDialogIsOpen = false;
              this.doCancel();
            },
          },
          {
            text: this.$skp.t(
              "leaseCustomization.cancelDialog.cancelButtonText"
            ),
            style: {
              outlined: true,
            },
            color: "grey",
            action: () => {
              this.handleCancelDialogDismiss();
            },
          },
        ],
      };
    },

    confirmDialogConfig(): TextDialogConfig {
      return {
        title: this.$skp.t("leaseCustomization.confirmDialog.title"),
        message: this.$skp.t("leaseCustomization.confirmDialog.message"),
        buttons: [
          {
            text: this.$skp.t(
              "leaseCustomization.confirmDialog.confirmButtonText"
            ),
            style: {
              depressed: true,
            },
            disabled: this.isSubmitLoading,
            loading: this.isSubmitLoading,
            color: "primary",
            action: () => {
              this.doSubmit();
            },
          },
          {
            text: this.$skp.t(
              "leaseCustomization.confirmDialog.cancelButtonText"
            ),
            style: {
              outlined: true,
            },
            disabled: this.isSubmitLoading,
            color: "grey",
            action: () => {
              this.handleConfirmDialogDismiss();
            },
          },
        ],
      };
    },
  },

  methods: {
    toggleEnableCustomization() {
      this.enableCustomization = !this.enableCustomization;
    },

    parseAndClamp(value: string): number {
      return clampDecimalDigits(parseFloat(value), 9, 2);
    },

    handleUnitAreaChange(value: string) {
      this.formState.unitArea = this.parseAndClamp(value);
    },

    handleFaceRentChange(value: string) {
      this.formState.faceRent = this.parseAndClamp(value);
    },

    handleFaceUnitRentChange(value: string) {
      this.formState.faceUnitRent = this.parseAndClamp(value);
    },

    handleEffectiveUnitRentChange(value: string) {
      this.formState.effectiveUnitRent = this.parseAndClamp(value);
    },

    handleAddLicensePeriod(months: number, days: number) {
      this.formState.licensePeriods.push({ months, days });
    },

    handleRemoveLicensePeriod(licensePeriod: unknown) {
      this.formState.licensePeriods = this.formState.licensePeriods.filter(
        (lp) => lp !== licensePeriod
      );
    },

    handleAddRentFreePeriod(months: number, days: number) {
      this.formState.rentFreePeriods.push({ months, days });
    },

    handleRemoveRentFreePeriod(rentFreePeriod: unknown) {
      this.formState.rentFreePeriods = this.formState.rentFreePeriods.filter(
        (rf) => rf !== rentFreePeriod
      );
    },

    addUnitFacilities() {
      this.formState.unitFacilities.push(generateDefaultUnitFeatureFormState());
    },

    handleUnitFacilityChange(data: UnitFacilityFormState) {
      this.formState.unitFacilities = this.formState.unitFacilities.map(
        (unitFacility) => {
          if (unitFacility.key === data.key) {
            return data;
          }
          return unitFacility;
        }
      );
    },

    handleUnitFacilityRemove(data: UnitFacilityFormState) {
      this.formState.unitFacilities = this.formState.unitFacilities.filter(
        (unitFacility) => unitFacility.key !== data.key
      );
    },

    handleSubmit() {
      this.confirmDialogIsOpen = true;
    },

    async doSubmit() {
      if (
        !this.buildingCode ||
        !this.leaseAndCustomizations ||
        !this.formValueBase
      ) {
        return;
      }
      try {
        this.submitRequestState = pending;
        const updatedLeaseCustomization = await updateLeaseCustomization(
          this.buildingCode,
          makeRequestBody(
            this.formState,
            this.leaseAndCustomizations,
            this.formValueBase.cof
          )
        );
        this.submitRequestState = success(updatedLeaseCustomization);
        this.$store.dispatch(
          "stackingPlan/fillLeaseCustomizations",
          updatedLeaseCustomization.leaseCustomizations
        );
        this.$store.dispatch("stackingPlan/updateUnitFeatures", {
          keyAttributes: this.leaseAndCustomizations.map(({ lease }) =>
            getKeyAttribute(lease)
          ),
          unitFeatures: updatedLeaseCustomization.unitFeatures,
        });
        this.$store.dispatch("stackingPlan/updateUnitFacilities", {
          keyAttributes: this.leaseAndCustomizations.map(({ lease }) =>
            getKeyAttribute(lease)
          ),
          unitFacilities: updatedLeaseCustomization.unitFacilities,
        });
        this.confirmDialogIsOpen = false;
        this.$emit("submitSuccess");
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.submitRequestState = failure(e);
        }
        throw e;
      }
    },

    handleCancel() {
      if (this.isFormDirty) {
        this.cancelDialogIsOpen = true;
      } else {
        this.doCancel();
      }
    },

    doCancel() {
      this.$emit("cancel");
    },

    handleCancelDialogDismiss() {
      this.cancelDialogIsOpen = false;
    },

    handleConfirmDialogDismiss() {
      this.confirmDialogIsOpen = false;
    },

    maybeApplyFormatNumber(value: Maybe<number>): Maybe<string> {
      return maybeApply(formatNumber, value);
    },

    maybeApplyFormatDigits(value: Maybe<number>): Maybe<string> {
      return maybeApply((value) => formatNumber(value, 2), value);
    },

    maybeApplyPrefix(
      value: Maybe<string | number>,
      suffixValue: string
    ): Maybe<string> {
      return maybeApply((v) => prefix(`${v}`, suffixValue), value);
    },

    maybeApplySuffix(
      value: Maybe<string | number>,
      suffixValue: string
    ): Maybe<string> {
      return maybeApply((v) => suffix(`${v}`, suffixValue), value);
    },

    maybeJoin<T>(value: Maybe<T[]>, delimiter: string): Maybe<string> {
      return maybeApply((xs) => xs.join(delimiter), value);
    },

    maybeMap<T, U>(value: Maybe<T[]>, fn: (t: T) => U): Maybe<U[]> {
      return maybeApply((xs) => xs.map(fn), value);
    },
  },
});

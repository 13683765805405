
import { defineComponent, PropType } from "vue";
import { KeyAttribute } from "../types/LeaseKeyAttribute";
import {
  SelectOption,
  UnitFacilityFormState,
} from "./LeaseCustomizationFormViewModel";

export default defineComponent({
  name: "LeaseCustomizationFormUnitFeatureRow",

  props: {
    availableUnits: Array as PropType<SelectOption<KeyAttribute>[]>,
    availableFacilities: Array as PropType<SelectOption<number>[]>,
    initialFormState: {
      type: Object as PropType<UnitFacilityFormState>,
      required: true,
    },
  },

  emits: ["change", "remove"],

  data(): UnitFacilityFormState {
    return {
      key: this.initialFormState.key,
      selectedUnits: this.initialFormState.selectedUnits,
      selectedFacility: this.initialFormState.selectedFacility,
      selectedOtherFacilities: this.initialFormState.selectedOtherFacilities,
    };
  },

  computed: {
    availableFacilitiesWithOtherFacilities(): SelectOption<number | null>[] {
      return [
        ...(this.availableFacilities ?? []),
        {
          value: null,
          name: this.$skp.t(
            "leaseCustomization.unitFeatureRow.otherFacility.label"
          ),
        },
      ];
    },
    otherFacilitiesSelected(): boolean {
      return this.selectedFacility == null;
    },
  },

  methods: {
    handleFieldChange() {
      this.$emit("change", this.$data);
    },

    handleRemove() {
      this.$emit("remove", this.$data);
    },
  },
});

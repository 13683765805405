import * as yup from "yup";
import {
  OfficeLeaseCustomizationSchema,
  LicensePeriodSchema,
  RentFreePeriodSchema,
  UnitFacilitySchema,
  UnitFeatureSchema,
} from "../models/LeaseCustomization";
import apiClient from "../utils/apiClient";

const LeaseCustomizationResultSchema = yup.object({
  leaseCustomizations: yup
    .array()
    .of(OfficeLeaseCustomizationSchema.required())
    .required(),
  unitFeatures: yup.array().of(UnitFeatureSchema.required()).required(),
  unitFacilities: yup.array().of(UnitFacilitySchema.required()).required(),
});

export type LeaseCustomizationResult = yup.InferType<
  typeof LeaseCustomizationResultSchema
>;

export const getLeaseCustomizations = async (
  buildingCode: string
): Promise<LeaseCustomizationResult> => {
  const response = await apiClient.get(
    `stackingplan/lease-customizations/building-code/${buildingCode}`
  );

  return LeaseCustomizationResultSchema.validate(response);
};

export const updateLeaseCustomization = async (
  buildingCode: string,
  requestBody: RequestBody
): Promise<LeaseCustomizationResult> => {
  const response = await apiClient.put(
    `stackingplan/lease-customizations/building-code/${buildingCode}`,
    requestBody
  );

  return LeaseCustomizationResultSchema.validate(response);
};

export const RequestBodySchema = yup.object({
  leaseCustomizations: yup
    .array()
    .of(
      yup.object({
        plsBuildingUnitId: yup.number().required(),
        plsLeaseUnitId: yup.number().nullable(),
        effectiveUnitRentStartDate: yup.date().nullable(),
        unitArea: yup.number().nullable(),
        tenantName: yup.string().nullable(),
        faceRent: yup.number().nullable(),
        faceUnitRent: yup.number().nullable(),
        effectiveUnitRent: yup.number().nullable(),
        licensePeriods: yup
          .array()
          .of(LicensePeriodSchema.required())
          .required(),
        rentFreePeriods: yup
          .array()
          .of(RentFreePeriodSchema.required())
          .required(),
        status: yup.string().nullable(),
        includeInCalculationWithoutNewLeases: yup.boolean().nullable(),
        includeInCalculationWithNewLeases: yup.boolean().nullable(),
        includeInOccupied: yup.boolean().nullable(),
        excludeInSummary: yup.boolean().required(),
      })
    )
    .required(),
  unitFeatures: yup.array().of(UnitFeatureSchema.required()).required(),
  unitFacilities: yup.array().of(UnitFacilitySchema.required()).required(),
});

export type RequestBody = yup.InferType<typeof RequestBodySchema>;


import Vue, { PropType } from "vue";

import formatNumber from "../filters/formatNumber";
import formatDate from "../filters/date";
import { GrandTotalSummary } from "../models/GrandTotalSummary";
import { Legend, LegendFilterType } from "../models/Legend";
import { Maybe } from "../types/Maybe";

export default Vue.extend({
  name: "GrandTotalSummaryTable",

  props: {
    grandTotalSummary: {
      type: Object as PropType<GrandTotalSummary>,
      required: true,
    },
    retail: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  methods: {
    formatDate,
    getIndicatorColor(
      kind: "expiry" | "confirmed" | "review"
    ): string | undefined {
      return this.getIndicatorLegend(kind)?.color;
    },
    getIndicatorLegend(kind: "expiry" | "confirmed" | "review"): Maybe<Legend> {
      switch (kind) {
        case "expiry":
          return this.$store.getters["stackingPlan/getLegendByFilterType"](
            LegendFilterType.LTE_12_MONTHS
          );
        case "confirmed":
          return this.$store.getters["stackingPlan/getLegendByFilterType"](
            LegendFilterType.CONFIRMED_TO_VACATE
          );
        case "review":
          return this.$store.getters["stackingPlan/getLegendByFilterType"](
            LegendFilterType.RENT_REVIEW_IN_12_MONTHS
          );
      }
    },

    isNaN,

    ifConditionMeet(
      condition: (value: number) => boolean,
      yes: () => unknown,
      no: (value: number) => unknown
    ) {
      return (value: number) => {
        return condition(value) ? yes() : no(value);
      };
    },

    render(value: string | number) {
      return () => value;
    },

    pipe(...args: ((value: unknown) => unknown)[]) {
      return (value: unknown) => {
        return args.reduce((prev, fn) => fn(prev), value);
      };
    },

    formatCurrency(value: number) {
      return `$${value}`;
    },
    formatDigits(dp?: number) {
      return (value: number) => formatNumber(value, dp);
    },
    formatPercent(value: number) {
      return `${value.toFixed(2)}%`;
    },
  },
});

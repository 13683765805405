function formatNumber(value: number, dp?: number): string {
  const numStr = dp != null ? value.toFixed(dp) : value.toString();
  const numParts = numStr.split(".");
  return (
    numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (numParts[1] ? `.${numParts[1]}` : "")
  );
}

export default formatNumber;

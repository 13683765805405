import * as yup from "yup";

import { FileSchema } from "../models/File";
import { KeyAttribute } from "../types/LeaseKeyAttribute";

export const PlsBuildingUnitLeaseSchema = yup.object({
  id: yup.number().required(),
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  remarks: yup.string().nullable(),
});

export type PlsBuildingUnitLease = yup.InferType<
  typeof PlsBuildingUnitLeaseSchema
>;

export const PlsBuildingUnitLeaseFloorPlanImageFileListSchema = yup
  .array()
  .of(FileSchema.required());

export type PlsBuildingUnitLeaseFloorPlanImageFileList = yup.InferType<
  typeof PlsBuildingUnitLeaseFloorPlanImageFileListSchema
>;

export function getKeyAttribute(obj: PlsBuildingUnitLease): KeyAttribute {
  return {
    plsBuildingUnitId: obj.plsBuildingUnitId,
    plsLeaseUnitId: `${obj.plsLeaseUnitId ?? ""}`,
    effectiveUnitRentStartDateStr:
      obj.effectiveUnitRentStartDate?.toISOString() ?? "",
  };
}

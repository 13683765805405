import Vue from "vue";
import VueRouter from "vue-router";

import MainLayout from "./layouts/MainLayout.vue";
import HomeView from "./views/HomeView/HomeView.vue";
import StackingPlanAddNegotiationView from "./views/StackingPlanAddNegotiationView/index.vue";
import { StackingPlanView } from "@swire-pmp-web/stacking-plan";

Vue.use(VueRouter);

export const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: HomeView,
    },
    {
      path: "/redirectTab",
      name: "redirectTab",
      meta: {
        title: "redirectTab",
        icon: "mdi-fingerprint",
        keepAlive: false,
      },
      component: () => import("./views/redirectTab.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: {
        title: "Login",
        icon: "mdi-fingerprint",
        keepAlive: false,
      },
      component: () => import("./views/login/index.vue"),
    },
    {
      path: "/StackingPlan",
      name: "StackingPlan",
      meta: {
        title: "StackingPlan",
        icon: "mdi-fingerprint",
        keepAlive: false,
      },
      component: MainLayout,
      children: [
        {
          path: "",
          component: StackingPlanView,
        },
        {
          path: "AddNegotiation",
          component: StackingPlanAddNegotiationView,
        },
      ],
    },
  ],
});


import { defineComponent } from "vue";

import { getOptions } from "../utils/options";

import IconFolderSvg from "../assets/images/icon-folder.svg";

export default defineComponent({
  name: "LegalDocumentChip",

  components: {
    IconFolderSvg,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  filters: {
    mapLabel(type: string) {
      const options = getOptions("legalDocumentTypeOptions");
      const label = options.find((option) => option.value === type)?.name;
      return label ?? type;
    },
  },
});

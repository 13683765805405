import { Module, Store } from "vuex";

import { buildingModule } from "./building";
import { buildingRemarksModule } from "./buildingRemarks";
import { globalModule } from "./global";
import { leaseModule } from "./lease";
import { leaseCustomizationModule } from "./leaseCustomization";
import { legendModule } from "./legend";
import { negotiationModule } from "./negotiation";
import { plsBuildingUnitLeaseModule } from "./plsBuildingUnitLease";
import { unitFacilityModule } from "./unitFacility";
import { unitFeatureModule } from "./unitFeature";

const rootModule: Module<Record<string, never>, unknown> = {
  namespaced: true,
  state: {},
  modules: {
    global: globalModule,
    building: buildingModule,
    buildingRemarks: buildingRemarksModule,
    plsBuildingUnitLease: plsBuildingUnitLeaseModule,
    leaseCustomization: leaseCustomizationModule,
    unitFeature: unitFeatureModule,
    unitFacility: unitFacilityModule,
    lease: leaseModule,
    legend: legendModule,
    negotiation: negotiationModule,
  },
};

export const setupStores = <T>(store: Store<T>) => {
  const rootModuleName = "stackingPlan";
  store.registerModule(rootModuleName, rootModule);
};

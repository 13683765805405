import { FloorListSchema } from "../models/Floor";
import apiClient from "../utils/apiClient";

export const getFloorListByBuildingCode = async (buildingCode: string) => {
  const response = await apiClient.get(
    `stackingplan/floors/building-code/${buildingCode}`
  );
  return await FloorListSchema.validate(response);
};

export const getFloorListByMOCode = async (moCode: string) => {
  const response = await apiClient.get(`stackingplan/floors/mo-code/${moCode}`);
  return await FloorListSchema.validate(response);
};

import { Module } from "vuex";

import {
  getKeyAttribute,
  OfficeLeaseCustomization,
  RetailLeaseCustomization,
} from "../models/LeaseCustomization";
import {
  getRecord,
  KeyAttribute,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { isNonNullable, Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

declare module "vuex" {
  interface Dispatch {
    (
      type: "stackingPlan/fillLeaseCustomizations",
      payload: OfficeLeaseCustomization[],
      options?: DispatchOptions
    ): Promise<unknown>;
    (
      type: "stackingPlan/fillRetailLeaseCustomizations",
      payload: RetailLeaseCustomization[],
      options?: DispatchOptions
    ): Promise<unknown>;
    (
      type: "stackingPlan/updateRetailLeaseCustomization",
      payload: RetailLeaseCustomization,
      options?: DispatchOptions
    ): Promise<unknown>;
  }
}

interface State {
  byKeyAttribute: RecordByKeyAttribute<OfficeLeaseCustomization>;
  retailByKeyAttribute: RecordByKeyAttribute<RetailLeaseCustomization>;
}

const getters = {
  getLeaseCustomization:
    (state: State) =>
    (keyAttrubute: KeyAttribute): Maybe<OfficeLeaseCustomization> => {
      return getRecord(state.byKeyAttribute, keyAttrubute);
    },

  getRetailLeaseCustomization:
    (state: State) =>
    (keyAttrubute: KeyAttribute): Maybe<RetailLeaseCustomization> => {
      return getRecord(state.retailByKeyAttribute, keyAttrubute);
    },

  getLatestLeaseCustomizationByKeyAttributes:
    (state: State) =>
    (keyAttributes: KeyAttribute[]): OfficeLeaseCustomization[] => {
      return keyAttributes
        .map((keyAttribute) => getRecord(state.byKeyAttribute, keyAttribute))
        .filter(isNonNullable);
    },
};

export const leaseCustomizationModule: Module<State, unknown> = {
  state: {
    byKeyAttribute: {},
    retailByKeyAttribute: {},
  },
  getters,
  mutations: {
    fillLeaseCustomizations(state, payload: OfficeLeaseCustomization[]) {
      for (const leaseCustomization of payload) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          getKeyAttribute(leaseCustomization),
          () => leaseCustomization
        );
      }
    },

    fillRetailLeaseCustomizations(state, payload: RetailLeaseCustomization[]) {
      for (const retailLeaseCustomization of payload) {
        state.retailByKeyAttribute = setRecord(
          state.retailByKeyAttribute,
          getKeyAttribute(retailLeaseCustomization),
          () => retailLeaseCustomization
        );
      }
    },

    updateLeaseCustomization(
      state,
      leaseCustomization: OfficeLeaseCustomization
    ) {
      state.byKeyAttribute = setRecord(
        state.byKeyAttribute,
        getKeyAttribute(leaseCustomization),
        () => leaseCustomization
      );
    },

    updateRetailLeaseCustomization(
      state,
      retailLeaseCustomization: RetailLeaseCustomization
    ) {
      state.retailByKeyAttribute = setRecord(
        state.retailByKeyAttribute,
        getKeyAttribute(retailLeaseCustomization),
        () => retailLeaseCustomization
      );
    },
  },
  actions: {
    fillLeaseCustomizations(context, payload: OfficeLeaseCustomization[]) {
      context.commit("fillLeaseCustomizations", payload);
    },

    fillRetailLeaseCustomizations(
      context,
      payload: RetailLeaseCustomization[]
    ) {
      context.commit("fillRetailLeaseCustomizations", payload);
    },

    updateLeaseCustomization(context, payload: OfficeLeaseCustomization) {
      context.commit("updateLeaseCustomization", payload);
    },

    updateRetailLeaseCustomization(context, payload: RetailLeaseCustomization) {
      context.commit("updateRetailLeaseCustomization", payload);
    },
  },
  modules: {},
};

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',_vm._g(_vm._b({attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._b({class:_vm.textFieldClass,attrs:{"autofocus":_vm.autofocus,"error":_vm.messageType > 0,"disabled":_vm.disabled,"persistent-placeholder":"","single-line":_vm.singleLine,"dense":_vm.dense,"label":_vm.label,"placeholder":_vm.placeholder,"validate-on-blur":"","rules":[
        _vm.menuOpened || !_vm.required || _vm.dateFormatted
          ? true
          : _vm.$vuetify.lang.t('$vuetify.alert.RequiredField'),
      ],"messages":_vm.messageType == 2
          ? [_vm.$vuetify.lang.t('$vuetify.alert.dateNotInRange')]
          : []},on:{"change":_vm.handleChange,"keydown":_vm.handleKeyDown},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"icon-class":"date","className":"date-icon"}},on),[_vm._v("mdi-calendar-today-outline")])]},proxy:true}],null,true),model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false))]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},'v-menu',_vm.$attrs,false),_vm.$listeners),[_c('v-date-picker',_vm._g(_vm._b({attrs:{"min":_vm.min,"max":_vm.max,"no-title":""},on:{"input":_vm.handleDateChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-date-picker',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import * as yup from "yup";

import { PlsDateSchema } from "./PlsDate";

export const LeaseRentFreePeriodSchema = yup.object({
  startDate: PlsDateSchema.required(),
  endDate: PlsDateSchema.required(),
});

export type LeaseRentFreePeriod = yup.InferType<
  typeof LeaseRentFreePeriodSchema
>;


import parse from "date-fns/parse";
import format from "date-fns/format";
import { defineComponent } from "vue";

import DatePicker from "./DatePicker.vue";

type RetailTypeOption = {
  itemText: string;
  itemValue: boolean | null;
};

export default defineComponent({
  name: "HeaderSearchBar",

  components: {
    DatePicker,
  },

  props: {
    initialSearchText: {
      type: String,
      default: "",
    },
    showRetailTypesSelect: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["searchClick"],

  data() {
    return {
      expiryDateFromDate: null as string | null,
      expiryDateFromDatePickerShown: false,
      expiryDateToDate: null as string | null,
      expiryDateToDatePickerShown: false,
      isFbOnly: null as boolean | null,
      selectedRetailTypes: null as string | null,
      searchText: this.initialSearchText,
    };
  },

  computed: {
    minExpiryDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    retailTypesOptions(): RetailTypeOption[] {
      return [
        {
          itemText: this.$skp.t("header.searchBar.retailTypes.allTypes"),
          itemValue: null,
        },
        {
          itemText: this.$skp.t("header.searchBar.retailTypes.fbOnly"),
          itemValue: true,
        },
        {
          itemText: this.$skp.t("header.searchBar.retailTypes.nonFbOnly"),
          itemValue: false,
        },
      ];
    },
  },

  methods: {
    getSelectionLabel(option: RetailTypeOption) {
      return option.itemValue == null
        ? this.$skp.t("header.searchBar.retailTypes.allRetailTypes")
        : option.itemText;
    },

    resetData() {
      this.expiryDateFromDate = null;
      this.expiryDateToDate = null;
      this.isFbOnly = null;
      this.searchText = "";
    },

    emitSearchClick() {
      const expiryDateFromDate = this.expiryDateFromDate
        ? parse(this.expiryDateFromDate, "yyyy-MM-dd", new Date())
        : null;
      const expiryDateToDate = this.expiryDateToDate
        ? parse(this.expiryDateToDate, "yyyy-MM-dd", new Date())
        : null;
      this.$emit("searchClick", {
        expiryDateFromDate,
        expiryDateToDate,
        isFbOnly: this.isFbOnly,
        searchText: this.searchText,
      });
    },

    handleSearchClick() {
      this.emitSearchClick();
    },

    handleResetClick() {
      this.resetData();
      this.emitSearchClick();
    },

    handleFormSubmit() {
      this.emitSearchClick();
    },
  },
});

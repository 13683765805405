import * as yup from "yup";

import { KeyAttribute } from "../types/LeaseKeyAttribute";
import { Maybe } from "../types/Maybe";
import { isSameOrAfterDay, isSameOrBeforeDay } from "../utils/date";
import { getUnitArea, getFaceRentFromEffectiveUnitRent } from "../utils/lease";
import { LeaseEffectiveUnitRent } from "./LeaseEffectiveUnitRent";
import { LeaseRentFreePeriod } from "./LeaseRentFreePeriod";
import { LeaseRentReview, LeaseRentReviewSchema } from "./LeaseRentReview";
import { LeaseShop } from "./LeaseShop";
import { LeaseTenant, LeaseTenantSchema } from "./LeaseTenant";
import {
  LeaseTurnoverRent,
  LeaseTurnoverRentSchema,
} from "./LeaseTurnoverRent";
import { OfficeLeaseUnitSchema } from "./OfficeLeaseUnit";
import { PlsBuildingUnit, PlsBuildingUnitSchema } from "./PlsBuildingUnit";
import { PlsDateSchema } from "./PlsDate";
import { RetailLeaseUnitSchema } from "./RetailLeaseUnit";

export const RemoteOfficeLeaseSchema = yup.object({
  buildingUnitID: yup.number().required(),
  buildingUnitNo: yup.string().required(),
  grossFloorArea: yup.number().required(),
  lettableFloorArea: yup.number().required(),
  netFloorArea: yup.number().required(),
  otherFloorArea: yup.number().required(),
  calculationFloorArea: yup.string().required(),
  portfolioType: yup.string().required(),
  managementOfficeID: yup.number().required(),
  managementOfficeName: yup.string().required(),
  managementOfficeCode: yup.string().required(),
  buildingID: yup.number().required(),
  buildingName: yup.string().required(),
  buildingFloorID: yup.number().required(),
  floor: yup.string().required(),
  unitType: yup.string().required(),
  unitZone: yup.string().nullable(),
  leaseUnit: OfficeLeaseUnitSchema.nullable(),
  leaseNo: yup.string().nullable(),
  unitReference: yup.string().nullable(),
  commencementDate: PlsDateSchema.nullable(),
  expiryDate: PlsDateSchema.nullable(),
  approvalStatus: yup.string().nullable(),
  licenseStartDate: PlsDateSchema.nullable(),
  licenseEndDate: PlsDateSchema.nullable(),
  tenant: LeaseTenantSchema.nullable(),
  rentReviews: yup.array().of(LeaseRentReviewSchema).nullable(),
  buildingUnit: PlsBuildingUnitSchema.nullable(),
});

export type RemoteOfficeLease = yup.InferType<typeof RemoteOfficeLeaseSchema>;

export interface OfficeLease {
  buildingUnitID: number;
  buildingUnitNo: string;
  grossFloorArea: number;
  lettableFloorArea: number;
  netFloorArea: number;
  otherFloorArea: number;
  calculationFloorArea: string;
  portfolioType: string;
  managementOfficeID: number;
  managementOfficeName: string;
  managementOfficeCode: string;
  buildingID: number;
  buildingName: string;
  buildingFloorID: number;
  floor: string;
  unitType: string;
  unitZone: Maybe<string>;
  leaseUnit: {
    leaseUnitID: Maybe<number>;
    effectiveDate: Maybe<Date>;
    expiryDate: Maybe<Date>;
    licenseStartDate: Maybe<Date>;
    licenseEndDate: Maybe<Date>;
    shopName: Maybe<string>;
    effectiveUnitRent: Maybe<LeaseEffectiveUnitRent>;
    rentFreePeriods: Maybe<LeaseRentFreePeriod[]>;
    faceRent: Maybe<number>;
  } | null;
  leaseNo: Maybe<string>;
  unitReference: Maybe<string>;
  commencementDate: Maybe<Date>;
  expiryDate: Maybe<Date>;
  approvalStatus: Maybe<string>;
  licenseStartDate: Maybe<Date>;
  licenseEndDate: Maybe<Date>;
  tenant: Maybe<LeaseTenant>;
  rentReviews: Maybe<LeaseRentReview[]>;
  rentReview: Maybe<LeaseRentReview>;
  buildingUnit: Maybe<PlsBuildingUnit>;
  licensePeriodIdx: number;
}

export const RemoteRetailLeaseSchema = yup.object({
  buildingUnitID: yup.number().required(),
  buildingUnitNo: yup.string().required(),
  grossFloorArea: yup.number().required(),
  lettableFloorArea: yup.number().required(),
  netFloorArea: yup.number().required(),
  otherFloorArea: yup.number().required(),
  calculationFloorArea: yup.string().required(),
  portfolioType: yup.string().required(),
  managementOfficeName: yup.string().required(),
  managementOfficeCode: yup.string().required(),
  buildingID: yup.number().required(),
  buildingName: yup.string().required(),
  buildingFloorID: yup.number().required(),
  floor: yup.string().required(),
  unitType: yup.string().required(),
  unitZone: yup.string().nullable(),
  leaseUnit: RetailLeaseUnitSchema.nullable(),
  leaseNo: yup.string().nullable(),
  unitReference: yup.string().nullable(),
  commencementDate: PlsDateSchema.nullable(),
  expiryDate: PlsDateSchema.nullable(),
  approvalStatus: yup.string().nullable(),
  licenseStartDate: PlsDateSchema.nullable(),
  licenseEndDate: PlsDateSchema.nullable(),
  tenant: LeaseTenantSchema.nullable(),
  rentReviews: yup.array().of(LeaseRentReviewSchema.required()).nullable(),
  leaseTurnoverRents: yup
    .array()
    .of(LeaseTurnoverRentSchema.required())
    .nullable(),
  buildingUnit: PlsBuildingUnitSchema.nullable(),
});

export type RemoteRetailLease = yup.InferType<typeof RemoteRetailLeaseSchema>;

export interface RetailLease {
  buildingUnitID: number;
  buildingUnitNo: string;
  grossFloorArea: number;
  lettableFloorArea: number;
  netFloorArea: number;
  otherFloorArea: number;
  calculationFloorArea: string;
  portfolioType: string;
  managementOfficeName: string;
  managementOfficeCode: string;
  buildingID: number;
  buildingName: string;
  buildingFloorID: number;
  floor: string;
  unitType: string;
  unitZone: Maybe<string>;
  leaseUnit: {
    leaseUnitID: number;
    effectiveDate: Date;
    expiryDate: Date;
    licenseStartDate: Maybe<Date>;
    licenseEndDate: Maybe<Date>;
    shopName: Maybe<string>;
    effectiveUnitRent: Maybe<LeaseEffectiveUnitRent>;
    rentFreePeriods: Maybe<LeaseRentFreePeriod[]>;
    faceRent: Maybe<number>;
    leaseShop: Maybe<LeaseShop>;
  } | null;
  leaseNo: Maybe<string>;
  unitReference: Maybe<string>;
  commencementDate: Maybe<Date>;
  expiryDate: Maybe<Date>;
  approvalStatus: Maybe<string>;
  licenseStartDate: Maybe<Date>;
  licenseEndDate: Maybe<Date>;
  tenant: Maybe<LeaseTenant>;
  rentReviews: Maybe<LeaseRentReview[]>;
  rentReview: Maybe<LeaseRentReview>;
  leaseTurnoverRents: Maybe<LeaseTurnoverRent[]>;
  buildingUnit: Maybe<PlsBuildingUnit>;
  licensePeriodIdx: number;
}

export type Lease = OfficeLease | RetailLease;

function isNonZeroEffectiveUnitRent(
  effectiveUnitRent: LeaseEffectiveUnitRent
): boolean {
  return effectiveUnitRent.effectiveUnitRent !== 0;
}

function isCurrentOrFutureEffectiveUnitRent(
  effectiveUnitRent: LeaseEffectiveUnitRent,
  now: Date
): boolean {
  const { startDate, endDate } = effectiveUnitRent;
  return (
    (isSameOrBeforeDay(startDate, now) && isSameOrAfterDay(endDate, now)) ||
    isSameOrAfterDay(startDate, now)
  );
}

function findRentFreePeriodsIn(
  rentFreePeriods: LeaseRentFreePeriod[],
  startDate: Date,
  endDate: Date
): LeaseRentFreePeriod[] {
  return rentFreePeriods.filter((rentFreePeriod) => {
    return (
      rentFreePeriod.startDate.getTime() >= startDate.getTime() &&
      rentFreePeriod.endDate.getTime() <= endDate.getTime()
    );
  });
}

function findRentReview(
  rentReviews: LeaseRentReview[],
  rentReviewDate: Date
): Maybe<LeaseRentReview> {
  return rentReviews.find(
    (rentReview) =>
      rentReview.rentReviewDate.getTime() === rentReviewDate.getTime()
  );
}

export function denormalizeOfficeLease(
  remoteOfficeLease: RemoteOfficeLease,
  now: Date
): OfficeLease[] {
  // may be null for new unit which has no leasing history
  if (!remoteOfficeLease.leaseUnit) {
    return [
      {
        ...remoteOfficeLease,
        leaseUnit: null,
        rentReview: null,
        licensePeriodIdx: 0,
      },
    ];
  }

  const {
    leaseUnit: { effectiveUnitRents, rentFreePeriods },
  } = remoteOfficeLease;

  // Vacant unit which has no rent reviews
  // E.g. Area A (retail)
  // E.g. 3401 (Vacant)
  if (!effectiveUnitRents || effectiveUnitRents.length === 0) {
    return [
      {
        ...remoteOfficeLease,
        leaseUnit: {
          ...remoteOfficeLease.leaseUnit,
          effectiveUnitRent: null,
        },
        rentReview: null,
        licensePeriodIdx: 0,
      },
    ];
  }

  // Include leases with only 1 rent review even it is 0 effective unit rent
  // E.g. 43/F (Future)
  if (effectiveUnitRents.length === 1) {
    const [effectiveUnitRent] = effectiveUnitRents;
    const rentReview = remoteOfficeLease.rentReviews
      ? findRentReview(
          remoteOfficeLease.rentReviews,
          effectiveUnitRent.startDate
        )
      : null;
    return [
      {
        ...remoteOfficeLease,
        leaseUnit: {
          ...remoteOfficeLease.leaseUnit,
          effectiveUnitRent,
        },
        rentReview,
        licensePeriodIdx: 0,
      },
    ];
  }

  const res: OfficeLease[] = [];

  const targetEffectiveUnitRents = effectiveUnitRents
    .filter(
      (effectiveUnitRent) =>
        isNonZeroEffectiveUnitRent(effectiveUnitRent) &&
        isCurrentOrFutureEffectiveUnitRent(effectiveUnitRent, now)
    )
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  // Denormalize with non zero effective unit rent
  for (const [idx, effectiveUnitRent] of targetEffectiveUnitRents.entries()) {
    const thisRentFreePeriods = rentFreePeriods
      ? findRentFreePeriodsIn(
          rentFreePeriods,
          effectiveUnitRent.startDate,
          effectiveUnitRent.endDate
        )
      : [];

    const rentReview = remoteOfficeLease.rentReviews
      ? findRentReview(
          remoteOfficeLease.rentReviews,
          effectiveUnitRent.startDate
        )
      : null;

    const shouldUseRentReviewedFaceRent =
      rentReview?.rentReviewStatus === "Completed" &&
      effectiveUnitRent.effectiveUnitRent > 0;

    const faceRent = shouldUseRentReviewedFaceRent
      ? getFaceRentFromEffectiveUnitRent(
          effectiveUnitRent,
          thisRentFreePeriods,
          getUnitArea(remoteOfficeLease)
        )
      : remoteOfficeLease.leaseUnit.faceRent;

    res.push({
      ...remoteOfficeLease,
      leaseUnit: {
        ...remoteOfficeLease.leaseUnit,
        rentFreePeriods: thisRentFreePeriods,
        effectiveUnitRent,
        faceRent,
      },
      rentReview,
      licensePeriodIdx: idx,
    });
  }

  return res;
}

export function denormalizeRetailLease(
  remoteRetailLease: RemoteRetailLease,
  now: Date
): RetailLease[] {
  // may be null for new unit which has no leasing history
  if (!remoteRetailLease.leaseUnit) {
    return [
      {
        ...remoteRetailLease,
        leaseUnit: null,
        rentReview: null,
        licensePeriodIdx: 0,
      },
    ];
  }

  const {
    leaseUnit: { effectiveUnitRents, rentFreePeriods },
  } = remoteRetailLease;

  // Vacant unit which has no rent reviews
  // E.g. Area A (retail)
  // E.g. 3401 (Vacant)
  if (!effectiveUnitRents || effectiveUnitRents.length === 0) {
    return [
      {
        ...remoteRetailLease,
        leaseUnit: {
          ...remoteRetailLease.leaseUnit,
          effectiveUnitRent: null,
        },
        rentReview: null,
        licensePeriodIdx: 0,
      },
    ];
  }

  // Include leases with only 1 rent review even it is 0 effective unit rent
  // E.g. 43/F (Future)
  if (effectiveUnitRents.length === 1) {
    const [effectiveUnitRent] = effectiveUnitRents;
    const rentReview = remoteRetailLease.rentReviews
      ? findRentReview(
          remoteRetailLease.rentReviews,
          effectiveUnitRent.startDate
        )
      : null;
    return [
      {
        ...remoteRetailLease,
        leaseUnit: {
          ...remoteRetailLease.leaseUnit,
          effectiveUnitRent,
        },
        rentReview,
        licensePeriodIdx: 0,
      },
    ];
  }

  const res: RetailLease[] = [];

  const targetEffectiveUnitRents = effectiveUnitRents
    .filter(
      (effectiveUnitRent) =>
        isNonZeroEffectiveUnitRent(effectiveUnitRent) &&
        isCurrentOrFutureEffectiveUnitRent(effectiveUnitRent, now)
    )
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  // Denormalize with non zero effective unit rent
  for (const [idx, effectiveUnitRent] of targetEffectiveUnitRents.entries()) {
    const thisRentFreePeriods = rentFreePeriods
      ? findRentFreePeriodsIn(
          rentFreePeriods,
          effectiveUnitRent.startDate,
          effectiveUnitRent.endDate
        )
      : [];

    const rentReview = remoteRetailLease.rentReviews
      ? findRentReview(
          remoteRetailLease.rentReviews,
          effectiveUnitRent.startDate
        )
      : null;

    const shouldUseRentReviewedFaceRent =
      rentReview?.rentReviewStatus === "Completed" &&
      effectiveUnitRent.effectiveUnitRent > 0;

    const faceRent = shouldUseRentReviewedFaceRent
      ? getFaceRentFromEffectiveUnitRent(
          effectiveUnitRent,
          thisRentFreePeriods,
          getUnitArea(remoteRetailLease)
        )
      : remoteRetailLease.leaseUnit.faceRent;

    res.push({
      ...remoteRetailLease,
      leaseUnit: {
        ...remoteRetailLease.leaseUnit,
        rentFreePeriods: thisRentFreePeriods,
        effectiveUnitRent,
        faceRent,
      },
      rentReview,
      licensePeriodIdx: idx,
    });
  }

  return res;
}

export const RemoteOfficeLeaseListSchema = yup
  .array()
  .of(RemoteOfficeLeaseSchema)
  .required();

export const RemoteRetailLeaseListSchema = yup
  .array()
  .of(RemoteRetailLeaseSchema.required())
  .required();

export function getKeyAttribute(
  lease: OfficeLease | RetailLease
): KeyAttribute {
  return {
    plsBuildingUnitId: lease.buildingUnitID,
    plsLeaseUnitId: `${lease.leaseUnit?.leaseUnitID ?? ""}`,
    effectiveUnitRentStartDateStr:
      lease.leaseUnit?.effectiveUnitRent?.startDate.toISOString() ?? "",
  };
}

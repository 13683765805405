import { Maybe } from "../types/Maybe";

type ComparisonValue = -1 | 0 | 1;

type Comparison = {
  _value: ComparisonValue;
  append: <T>(a: Maybe<T>, b: Maybe<T>) => Comparison;
  result: () => ComparisonValue;
};

export function buildComparison(value: ComparisonValue = 0) {
  return {
    _value: value,
    append<T>(a: Maybe<T>, b: Maybe<T>): Comparison {
      if (value != 0) {
        return buildComparison(value);
      }
      if (a == null && b == null) {
        return buildComparison(0);
      }
      if (a == null) {
        return buildComparison(-1);
      }
      if (b == null) {
        return buildComparison(1);
      }
      return buildComparison(a === b ? 0 : a < b ? -1 : 1);
    },
    result() {
      return value;
    },
  };
}

import apiClient from "../utils/apiClient";
import {
  denormalizeOfficeLease,
  denormalizeRetailLease,
  OfficeLease,
  RemoteOfficeLeaseListSchema,
  RemoteRetailLeaseListSchema,
  RetailLease,
} from "../models/Lease";

export const getOfficeLeasesByBuildingCode = async (
  buildingCode: string
): Promise<OfficeLease[]> => {
  const response = await apiClient.get(
    `stackingplan/leases/office?buildingCode=${buildingCode}`
  );
  const now = new Date();
  return (await RemoteOfficeLeaseListSchema.validate(response)).reduce<
    OfficeLease[]
  >((prev, curr) => [...prev, ...denormalizeOfficeLease(curr, now)], []);
};

export const getRetailLeasesByMOCode = async (
  moCode: string
): Promise<RetailLease[]> => {
  const response = await apiClient.get(
    `stackingplan/leases/retail?moCode=${moCode}`
  );
  const now = new Date();
  return (await RemoteRetailLeaseListSchema.validate(response)).reduce<
    RetailLease[]
  >((prev, curr) => [...prev, ...denormalizeRetailLease(curr, now)], []);
};

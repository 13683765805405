import { RequestBody } from "../apis/leaseCustomization";
import {
  Lease,
  getKeyAttribute as getLeaseKeyAttribute,
} from "../models/Lease";
import {
  getCustomizedCof,
  getKeyAttribute as getLeaseCustomizationKeyAttribute,
  OfficeLeaseCustomization,
  UnitFacility,
  UnitFeature,
} from "../models/LeaseCustomization";
import { getMonthDayFromStartAndEnd, MonthDay } from "../models/MonthDay";
import { LeaseAndCustomization } from "../types/LeaseAndCustomization";
import { isNonNullable, Maybe } from "../types/Maybe";
import { isEqual, KeyAttribute } from "../types/LeaseKeyAttribute";
import {
  COF,
  getExpiryGreaterThan12MonthsFlag,
  getExpiryLessThanOrEqualTo12MonthsFlag,
  getFutureLeasesOfLease,
  getFutureLeasesOfLeaseEndAfter12Months,
  getPendingRentReviewIn12Month,
  getTotalUnitArea,
  getVacantFlag,
} from "../utils/lease";
import { Option } from "../utils/options";
import { sum } from "../utils/sum";
import { uuid } from "../utils/uuid";

export interface SelectOption<ValueType> {
  value: ValueType;
  name: string;
}

export interface UnitFacilityFormState {
  key: string;
  selectedUnits: KeyAttribute[];
  selectedFacility: string | null;
  selectedOtherFacilities: string | null;
}

export const generateDefaultUnitFeatureFormState: () => UnitFacilityFormState =
  () => ({
    key: uuid(),
    selectedUnits: [],
    selectedFacility: null,
    selectedOtherFacilities: null,
  });

export interface FormValueBase {
  unitArea: number | null;
  tenantName: string | null;
  faceRent: number | null;
  faceUnitRent: number | null;
  effectiveUnitRent: number | null;
  licensePeriods: MonthDay[];
  rentFreePeriods: MonthDay[];
  status: string | null;
  cof: COF;
}

export function makeFormValueBase(
  lease: Lease,
  leases: Lease[],
  grouping: Lease[],
  statusOptions: Option[],
  cof: COF
): FormValueBase {
  const { tenant } = lease;
  const licenseStartDate = lease.leaseUnit?.licenseStartDate;
  const licenseEndDate = lease.leaseUnit?.licenseEndDate;

  const totalUnitArea = getTotalUnitArea(grouping);
  const rentFreePeriods = lease.leaseUnit?.rentFreePeriods;
  const faceRent = sum(grouping.map((x) => x.leaseUnit?.faceRent ?? 0));

  const licensePeriods =
    licenseStartDate && licenseEndDate
      ? [getMonthDayFromStartAndEnd(licenseStartDate, licenseEndDate)]
      : [];

  const _rentFreePeriods =
    rentFreePeriods?.map((rentFreePeriod) =>
      getMonthDayFromStartAndEnd(
        rentFreePeriod.startDate,
        rentFreePeriod.endDate
      )
    ) ?? [];

  const status = (() => {
    const now = new Date();
    let option: Maybe<Option> = null;
    if (
      getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
      getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now).length === 0
    ) {
      option = statusOptions.find((x) => x.name === "<= 12 Months");
    }
    if (
      getExpiryGreaterThan12MonthsFlag(lease, now) ||
      (getExpiryLessThanOrEqualTo12MonthsFlag(lease, now) &&
        getFutureLeasesOfLeaseEndAfter12Months(lease, leases, now).length > 0)
    ) {
      option = statusOptions.find((x) => x.name === "> 12 Months");
    }
    if (
      getPendingRentReviewIn12Month(lease, now) &&
      getFutureLeasesOfLease(lease, leases).length === 0
    ) {
      option = statusOptions.find((x) => x.name === "Rent Review in 12 Months");
    }
    if (getVacantFlag(lease)) {
      option = statusOptions.find((x) => x.name === "Vacant");
    }
    return option?.value ?? null;
  })();

  return {
    unitArea: totalUnitArea,
    tenantName: tenant?.tenantShortName ?? tenant?.tenantName ?? "",
    faceRent,
    faceUnitRent: totalUnitArea > 0 ? faceRent / totalUnitArea : 0,
    effectiveUnitRent:
      lease.leaseUnit?.effectiveUnitRent?.effectiveUnitRent ?? null,
    licensePeriods,
    rentFreePeriods: _rentFreePeriods,
    status,
    cof,
  };
}

export interface FormState {
  unitArea: number | null;
  tenantName: string | null;
  faceRent: number | null;
  faceUnitRent: number | null;
  effectiveUnitRent: number | null;
  licensePeriods: { months: number; days: number }[];
  rentFreePeriods: { months: number; days: number }[];
  status: string | null;
  unitFacilities: UnitFacilityFormState[];
  enableUnitFeatures: boolean;
  unitFeatures: string[];
  includeInCalculationWithoutNewLeases: boolean;
  includeInCalculationWithNewLeases: boolean;
  includeInOccupied: boolean;
  excludeInSummary: boolean;
}

export function makeFormState(
  leaseCustomization: Maybe<OfficeLeaseCustomization>,
  unitFeatures: UnitFeature[],
  unitFacilities: UnitFacility[],
  cof: COF,
  availableFacilities: SelectOption<string>[]
): FormState {
  if (!leaseCustomization) {
    return {
      unitArea: null,
      tenantName: null,
      faceRent: null,
      faceUnitRent: null,
      effectiveUnitRent: null,
      licensePeriods: [],
      rentFreePeriods: [],
      status: null,
      unitFacilities: [],
      enableUnitFeatures: false,
      unitFeatures: [],
      includeInCalculationWithoutNewLeases: cof.c,
      includeInCalculationWithNewLeases: cof.f,
      includeInOccupied: cof.o,
      excludeInSummary: false,
    };
  }

  const {
    unitArea = null,
    tenantName = null,
    faceRent = null,
    faceUnitRent = null,
    effectiveUnitRent = null,
    licensePeriods = null,
    rentFreePeriods = null,
    status = null,
    excludeInSummary,
  } = leaseCustomization;

  const maybeCustomizedCof = getCustomizedCof(leaseCustomization) ?? cof;

  const facilityUnitMap = unitFacilities.reduce<{
    [key in UnitFacility["facility"]]: KeyAttribute[];
  }>((prev, curr) => {
    prev[curr.facility] = [
      ...(prev[curr.facility] || []),
      getLeaseCustomizationKeyAttribute(curr),
    ];
    return prev;
  }, {});
  const facilities = Object.keys(facilityUnitMap);

  const featureUnitMap = unitFeatures.reduce<{
    [key in UnitFeature["feature"]]: KeyAttribute[];
  }>((prev, curr) => {
    prev[curr.feature] = [
      ...(prev[curr.feature] || []),
      getLeaseCustomizationKeyAttribute(curr),
    ];
    return prev;
  }, {});
  const features = Object.keys(featureUnitMap);

  return {
    unitArea,
    tenantName,
    faceRent,
    faceUnitRent,
    effectiveUnitRent,
    licensePeriods: [...(licensePeriods ?? [])].sort(
      (a, b) => a.displaySeq - b.displaySeq
    ),
    rentFreePeriods: [...(rentFreePeriods ?? [])].sort(
      (a, b) => a.displaySeq - b.displaySeq
    ),
    status,
    unitFacilities: facilities.map((facility) => {
      const selectedFacility =
        availableFacilities.find((f) => f.value === facility)?.value ?? null;
      return {
        key: uuid(),
        selectedUnits: facilityUnitMap[facility],
        selectedFacility: selectedFacility,
        selectedOtherFacilities: selectedFacility ? null : facility,
      };
    }),
    enableUnitFeatures: unitFeatures.length > 0 || unitFacilities.length > 0,
    unitFeatures: features,
    includeInCalculationWithNewLeases: maybeCustomizedCof.f,
    includeInCalculationWithoutNewLeases: maybeCustomizedCof.c,
    includeInOccupied: maybeCustomizedCof.o,
    excludeInSummary,
  };
}

export function makeRequestBody(
  formState: FormState,
  leaseAndCustomizations: LeaseAndCustomization[],
  baseCof: COF
): RequestBody {
  const isCusotmizedCofSameAsBaseCof =
    baseCof.c === formState.includeInCalculationWithoutNewLeases &&
    baseCof.o === formState.includeInOccupied &&
    baseCof.f === formState.includeInCalculationWithNewLeases;

  const leaseCustomizations: RequestBody["leaseCustomizations"] =
    leaseAndCustomizations.map(({ lease }) => ({
      plsBuildingUnitId: lease.buildingUnitID,
      plsLeaseUnitId: lease.leaseUnit?.leaseUnitID,
      effectiveUnitRentStartDate: lease.leaseUnit?.effectiveUnitRent?.startDate,
      unitArea: formState.unitArea,
      tenantName: formState.tenantName || null,
      faceRent: formState.faceRent,
      faceUnitRent: formState.faceUnitRent,
      effectiveUnitRent: formState.effectiveUnitRent,
      licensePeriods: formState.licensePeriods.map((value, displaySeq) => ({
        ...value,
        displaySeq,
      })),
      rentFreePeriods: formState.rentFreePeriods.map((value, displaySeq) => ({
        ...value,
        displaySeq,
      })),
      status: formState.status,
      includeInCalculationWithNewLeases: isCusotmizedCofSameAsBaseCof
        ? null
        : formState.includeInCalculationWithNewLeases,
      includeInCalculationWithoutNewLeases: isCusotmizedCofSameAsBaseCof
        ? null
        : formState.includeInCalculationWithoutNewLeases,
      includeInOccupied: isCusotmizedCofSameAsBaseCof
        ? null
        : formState.includeInOccupied,
      excludeInSummary: formState.excludeInSummary,
    }));

  const unitFeatures: RequestBody["unitFeatures"] = formState.enableUnitFeatures
    ? formState.unitFeatures.reduce<RequestBody["unitFeatures"]>(
        (prev, curr) => {
          return [
            ...prev,
            ...leaseAndCustomizations.map(({ lease }) => ({
              plsBuildingUnitId: lease.buildingUnitID,
              plsLeaseUnitId: lease.leaseUnit?.leaseUnitID,
              effectiveUnitRentStartDate:
                lease.leaseUnit?.effectiveUnitRent?.startDate,

              feature: curr,
            })),
          ];
        },
        []
      )
    : [];

  const unitFacilities: RequestBody["unitFacilities"] =
    formState.enableUnitFeatures
      ? formState.unitFacilities.reduce<RequestBody["unitFacilities"]>(
          (prev, curr) => {
            const facility =
              curr.selectedFacility ?? curr.selectedOtherFacilities;
            if (!facility) {
              return prev;
            }
            return [
              ...prev,
              ...curr.selectedUnits
                .map((selectedUnit) => {
                  const leaseAndCustomization = leaseAndCustomizations.find(
                    ({ lease }) => {
                      const keyAttribute = getLeaseKeyAttribute(lease);
                      return isEqual(keyAttribute, selectedUnit);
                    }
                  );
                  if (!leaseAndCustomization) {
                    return null;
                  }
                  const { lease } = leaseAndCustomization;
                  return {
                    plsBuildingUnitId: lease.buildingUnitID,
                    plsLeaseUnitId: lease.leaseUnit?.leaseUnitID,
                    effectiveUnitRentStartDate:
                      lease.leaseUnit?.effectiveUnitRent?.startDate ?? null,
                    facility,
                  };
                })
                .filter(isNonNullable),
            ];
          },
          []
        )
      : [];

  const obj: RequestBody = {
    leaseCustomizations,
    unitFacilities,
    unitFeatures,
  };
  return obj;
}

import * as yup from "yup";
import { AuditorSchema } from "./Auditor";
import { OpportunityTypeSchema } from "./Opportunity";

export const NegotiationOpportunityProspectSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
});

export type NegotiationOpportunityProspect = yup.InferType<
  typeof NegotiationOpportunityProspectSchema
>;

export const NegotiationOpportunityLegalDocumentSchema = yup.object({
  id: yup.number().required(),
  type: yup.string().required(),
});

export type NegotiationOpportunityLegalDocument = yup.InferType<
  typeof NegotiationOpportunityLegalDocumentSchema
>;

export const NegotiationOpportunitySchema = yup.object({
  id: yup.number().required(),
  type: OpportunityTypeSchema.required(),
  active: yup.boolean(),
  prospect: NegotiationOpportunityProspectSchema.required(),
  legalDocuments: yup
    .array()
    .of(NegotiationOpportunityLegalDocumentSchema)
    .required(),
});

export type NegotiationOpportunity = yup.InferType<
  typeof NegotiationOpportunitySchema
>;

export const NegotiationRentReviewSchema = yup.object({
  id: yup.number().required(),
  negotiationId: yup.number().required(),
  rentReviewDate: yup.date().required(),
  rentReviewType: yup.string().required(),
  cap: yup.number().nullable(),
  collar: yup.number().nullable(),
  remarks: yup.string().nullable(),
});

export type NegotiationRentReview = yup.InferType<
  typeof NegotiationRentReviewSchema
>;

export const NegotiationAdditionalConditionSchema = yup.object({
  id: yup.number().required(),
  negotiationId: yup.number().required(),
  type: yup.string().required(),
  renewDate: yup.date().nullable(),
  rentReviewType: yup.string().nullable(),
  cap: yup.number().nullable(),
  collar: yup.number().nullable(),
  remarks: yup.string().nullable(),
  premisesType: yup.string().nullable(),
  surrenderRight: yup.number().nullable(),
  noticePeriodMonth: yup.number().nullable(),
  subletRight: yup.number().nullable(),
  displaySeq: yup.number().nullable(),
});

export type NegotiationAdditionalCondition = yup.InferType<
  typeof NegotiationAdditionalConditionSchema
>;

export const NegotiationUnitSchema = yup.object({
  id: yup.number().required(),
  negotiationId: yup.number().required(),
  buildingUnitId: yup.number().required(),
});

export type NegotiationUnit = yup.InferType<typeof NegotiationUnitSchema>;

export const NegotiationSchema = yup.object({
  id: yup.number().required(),
  deadline: yup.date().nullable(),
  originator: yup.string().required(),
  dateSentOutReceived: yup.date().required(),
  licenceCommencementDate: yup.date().nullable(),
  licenceExpiryDate: yup.date().nullable(),
  leaseCommencementDate: yup.date().nullable(),
  leaseExpiryDate: yup.date().nullable(),
  faceUnitRent: yup.number().required(),
  effectiveUnitRent: yup.number().nullable(),
  rentReviewDate: yup.date().nullable(),
  yearsAtOmr: yup.string().nullable(),
  monthlyRentalHkd: yup.number().nullable(),
  leaseDurationMonth: yup.number().nullable(),
  specialCondition: yup.date().nullable(),
  leaseTermMonth: yup.string().nullable(),
  rentFreePeriod: yup.number().nullable(),
  depositMonths: yup.date().nullable(),
  units: yup.array().of(NegotiationUnitSchema).required(),
  rentReviews: yup.array().of(NegotiationRentReviewSchema).required(),
  additionalConditions: yup
    .array()
    .of(NegotiationAdditionalConditionSchema)
    .required(),
  lastUpdatedDate: yup.date().required(),
  lastUpdatedBy: AuditorSchema.required(),
  opportunityId: yup.number().required(),
  opportunity: NegotiationOpportunitySchema.required(),
});

export type Negotiation = yup.InferType<typeof NegotiationSchema>;

export const NegotiationListSchema = yup
  .array()
  .of(NegotiationSchema)
  .required();

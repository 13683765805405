import { Module } from "vuex";

interface State {
  resultUpdateSnackbar: {
    show: boolean;
    message: string | null;
  };
}

export const globalModule: Module<State, unknown> = {
  state: {
    resultUpdateSnackbar: {
      show: false,
      message: null,
    },
  },
  mutations: {
    showResultUpdateSnackbar(state, payload: string) {
      state.resultUpdateSnackbar = {
        show: true,
        message: payload,
      };
    },
  },
  actions: {
    showResultUpdateSnackbar(context, payload: string) {
      context.commit("showResultUpdateSnackbar", payload);
    },
  },
  modules: {},
};

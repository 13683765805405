
import Vue from "vue";
import { removeCookies, removeToken } from "../utils/auth";
import { signOut } from "../utils/msal-auth/authRedirect";

export default Vue.extend({
  name: "App",

  methods: {
    _removeSession() {
      removeCookies("userInfo");
      removeToken();
    },
    changeLocale(lang: string) {
      this.$i18n.locale = lang;
      localStorage.setItem("i18nLocale", lang);
    },
    remove() {
      this._removeSession();
    },
    logout() {
      this._removeSession();
      signOut();
    },
  },
});

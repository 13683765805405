import { Module } from "vuex";
import { getKeyAttribute, UnitFeature } from "../models/LeaseCustomization";
import {
  getRecord,
  KeyAttribute,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { isNonNullable, Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

declare module "vuex" {
  interface Dispatch {
    (
      type: "stackingPlan/fillUnitFeatures",
      payload: UnitFeature[],
      options?: DispatchOptions
    ): Promise<unknown>;
    (
      type: "stackingPlan/updateUnitFeatures",
      payload: { keyAttributes: KeyAttribute[]; unitFeatures: UnitFeature[] },
      options?: DispatchOptions
    ): Promise<unknown>;
  }
}

interface State {
  byKeyAttribute: RecordByKeyAttribute<UnitFeature[]>;
}

const getters = {
  getUnitFeatures:
    (state: State) =>
    (keyAttribute: KeyAttribute): Maybe<UnitFeature[]> => {
      return getRecord(state.byKeyAttribute, keyAttribute);
    },

  getUnitFeaturesByKeyAttributes:
    (state: State) =>
    (keyAttributes: KeyAttribute[]): UnitFeature[] => {
      return keyAttributes
        .map((keyAttribute) => getRecord(state.byKeyAttribute, keyAttribute))
        .filter(isNonNullable)
        .reduce<UnitFeature[]>((prev, curr) => [...prev, ...curr], []);
    },
};

export const unitFeatureModule: Module<State, unknown> = {
  state: {
    byKeyAttribute: {},
  },

  getters,

  mutations: {
    fillUnitFeatures(state, payload: UnitFeature[]) {
      for (const unitFeature of payload) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          getKeyAttribute(unitFeature),
          (maybeUnitFeatures) => [...(maybeUnitFeatures ?? []), unitFeature]
        );
      }
    },

    updateUnitFeatures(
      state,
      payload: {
        keyAttributes: KeyAttribute[];
        unitFeatures: UnitFeature[];
      }
    ) {
      const { keyAttributes, unitFeatures } = payload;

      for (const keyAttribute of keyAttributes) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          keyAttribute,
          () => []
        );
      }

      for (const unitFeature of unitFeatures) {
        state.byKeyAttribute = setRecord(
          state.byKeyAttribute,
          getKeyAttribute(unitFeature),
          (maybeUs) => [...(maybeUs ?? []), unitFeature]
        );
      }
    },
  },

  actions: {
    fillUnitFeatures(context, payload: UnitFeature[]) {
      context.commit("fillUnitFeatures", payload);
    },

    updateUnitFeatures(
      context,
      payload: { keyAttributes: KeyAttribute[]; unitFeatures: UnitFeature[] }
    ) {
      context.commit("updateUnitFeatures", payload);
    },
  },

  modules: {},
};

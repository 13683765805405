var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"scroll-bar__container"},[_c('div',{staticClass:"scroll-bar__track",class:{
      'scroll-bar__track--horizontal': _vm.horizontal,
      'scroll-bar__track--vertical': !_vm.horizontal,
    },style:(_vm.horizontal ? { height: `${_vm.size}px` } : { width: `${_vm.size}px` })},[(_vm.scrollable)?_c('div',{staticClass:"scroll-bar__thumb",class:{
        'scroll-bar__thumb--dragging': _vm.isDragging,
        'scroll-bar__thumb--horizontal': _vm.horizontal,
        'scroll-bar__thumb--vertical': !_vm.horizontal,
      },style:(_vm.horizontal
          ? {
              left: `${_vm.offset.toFixed(2)}px`,
              width: `${_vm.thumbSize.toFixed(2)}px`,
              height: `${_vm.size.toFixed(2)}px`,
              borderRadius: `${_vm.borderRadius.toFixed(2)}px`,
            }
          : {
              top: `${_vm.offset.toFixed(2)}px`,
              width: `${_vm.size.toFixed(2)}px`,
              height: `${_vm.thumbSize.toFixed(2)}px`,
              borderRadius: `${_vm.borderRadius.toFixed(2)}px`,
            }),on:{"mousedown":function($event){$event.preventDefault();return _vm.handleThumbMousedown.apply(null, arguments)}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
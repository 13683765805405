import * as yup from "yup";

import { PlsDateSchema } from "./PlsDate";

export const LeaseShopSchema = yup.object({
  leaseShopID: yup.number().required(),
  shopName: yup.string().required(),
  tradeCategoryGroup: yup.string().nullable(),
  tradeCategory: yup.string().nullable(),
  tradeSubCategory: yup.string().nullable(),
  businessOpenDate: PlsDateSchema.nullable(),
});

export type LeaseShop = yup.InferType<typeof LeaseShopSchema>;

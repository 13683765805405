
import formatDate from "../filters/date";
import formatNumber from "../filters/formatNumber";
import FoodLicenseSummaryTableStatistics from "./FoodLicenseSummaryTableStatistics.vue";
import Vue, { PropType } from "vue";
import {
  FoodLicenseLevel,
  FoodLicenseSummary,
} from "@/models/FoodLicenseSummary";
export default Vue.extend({
  name: "FoodLicenseSummaryTable",
  components: {
    FoodLicenseSummaryTableStatistics,
  },
  props: {
    buildingLevelFoodLicenseSummary: {
      type: Object as PropType<FoodLicenseSummary>,
      required: true,
    },
    streetLevelFoodLicenseSummary: {
      type: Object as PropType<FoodLicenseSummary>,
      required: true,
    },
    selectedLevel: {
      type: String as PropType<FoodLicenseLevel>,
      required: true,
    },
  },
  computed: {
    buildingLevelFoodLicenseSummaryTitle() {
      return this.$skp.t(
        "foodLicenseSummaryTable.buildingLevelFoodLicenseSummaryTitle"
      );
    },
    streetLevelFoodLicenseSummaryTitle() {
      return this.$skp.t(
        "foodLicenseSummaryTable.streetLevelFoodLicenseSummaryTitle"
      );
    },
    totalOrAverageRentWithFoodLicenseIntervalClassBoundaries() {
      return this.buildingLevelFoodLicenseSummary.rent.totalOrAverageRentWithFoodLicenseIntervals
        .map((intervals) => {
          const formattedBoundary = [intervals.minArea, intervals.maxArea].map(
            (value) => {
              return value == null ? undefined : formatNumber(value, 0);
            }
          );
          if (formattedBoundary[0] == null) {
            return `≤${formattedBoundary[1]}`;
          } else if (formattedBoundary[1] == null) {
            return `≥${formattedBoundary[0]}`;
          } else {
            return `${formattedBoundary[0]}-${formattedBoundary[1]}`;
          }
        })
        .map((boundary, i) => {
          return {
            id: i,
            value: boundary,
          };
        });
    },
  },
  methods: {
    formatDate,
    formatNumber,
  },
});

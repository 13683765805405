
import { PropType, defineComponent } from "vue";
import {
  RemoteData,
  initial,
  pending,
  isPending,
  failure,
  success,
} from "@devexperts/remote-data-ts";

import { getValidationMessageLocalizer } from "../utils/validate";

import BuildingRemarksUnsavedChangesDialog from "./BuildingRemarksUnsavedChangesDialog.vue";

export default defineComponent({
  name: "BuildingRemarksDialog",

  components: {
    BuildingRemarksUnsavedChangesDialog,
  },

  props: {
    value: Boolean,
    addTitle: { type: String, default: "" },
    editTitle: { type: String, default: "" },
    initialRemarks: String,
    updateRemarksValidationErrorMessage: String,
    updateRemarks: Function as PropType<(remarks: string) => Promise<void>>,
  },

  emits: ["alertUnsavedChanges", "cancelClick", "change", "confirm", "input"],

  data() {
    return {
      currentRemarks: null as string | null,
      updateRemarksRemoteData: initial as RemoteData<Error, unknown>,
      isUnsavedChangesDialogOpen: false,
    };
  },

  watch: {
    value(value) {
      if (value) {
        this.currentRemarks = this.initialRemarks ?? null;
      }
    },
  },

  computed: {
    title() {
      return !this.initialRemarks
        ? this.addTitle || this.$skp.t("buildingRemarksDialog.form.title.add")
        : this.editTitle ||
            this.$skp.t("buildingRemarksDialog.form.title.edit");
    },
    isDirty() {
      return (
        this.currentRemarks != null &&
        this.initialRemarks !== this.currentRemarks
      );
    },
    isUpdatingRemarks() {
      return isPending(this.updateRemarksRemoteData);
    },
  },

  methods: {
    handleInput(value: boolean) {
      this.$emit("input", value);
    },
    handleCancelClick() {
      this._cancelOrAlertUnsavedChanges();
    },
    handleConfirmClick() {
      this._updateRemarks();
    },
    handleBuildingRemarksUnsavedChangesLeaveClick() {
      this.isUnsavedChangesDialogOpen = false;
      this._emitCancelClick();
    },
    handleBuildingRemarksUnsavedChangesCancelClick() {
      this.isUnsavedChangesDialogOpen = false;
    },
    _cancelOrAlertUnsavedChanges() {
      if (this.isDirty) {
        this.isUnsavedChangesDialogOpen = true;
      } else {
        this._emitCancelClick();
      }
    },
    _emitConfirm() {
      this.$emit("confirm");
      this.currentRemarks = null;
    },
    _emitCancelClick() {
      this.$emit("cancelClick");
      this.currentRemarks = null;
    },
    async _updateRemarks() {
      if (!this.updateRemarks) {
        return;
      }
      this.updateRemarksRemoteData = pending;
      try {
        const building = await this.updateRemarks(this.currentRemarks ?? "");
        this.updateRemarksRemoteData = success(building);
        this._emitConfirm();
      } catch (e) {
        if (e instanceof Error) {
          this.updateRemarksRemoteData = failure(e);
        }
        throw e;
      }
    },

    processUpdateRemarksValidationError(value: string | undefined) {
      if (!value) {
        return null;
      }
      return getValidationMessageLocalizer(value)(this.$skp.t);
    },
  },
});

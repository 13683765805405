
import { defineComponent, PropType } from "vue";
import { LeaseAndCustomization } from "../types/LeaseAndCustomization";
import LeaseCustomizationForm from "./LeaseCustomizationForm.vue";
import {
  FormState,
  FormValueBase,
  SelectOption,
} from "./LeaseCustomizationFormViewModel";

export default defineComponent({
  name: "CustomizationDialog",

  components: { LeaseCustomizationForm },

  emits: ["cancel", "submitSuccess"],

  props: {
    isOpen: Boolean,
    buildingCode: String,
    statusOptions: Array as PropType<SelectOption<string>[]>,
    availableUnits: Array as PropType<SelectOption<number>[]>,
    availableFacilities: Array as PropType<SelectOption<string>[]>,
    availableUnitFeatures: Array as PropType<SelectOption<string>[]>,
    formValueBase: Object as PropType<FormValueBase>,
    initialEnableCustomization: Boolean,
    initialFormState: Object as PropType<FormState>,
    leaseAndCustomizations: Array as PropType<LeaseAndCustomization[]>,
  },

  methods: {
    handleSubmitSuccess() {
      this.$emit("submitSuccess");
    },

    handleCancel() {
      this.$emit("cancel");
    },
  },
});

import { VueConstructor } from "vue";

type Permission = string;

export type GetPermissions = () => Permission[];

export function setupGetPermissions(
  getPermissions: GetPermissions,
  Vue: VueConstructor
) {
  Vue.prototype.$skp = Vue.prototype.$skp || {};
  Vue.prototype.$skp.can = (op: string) => {
    return getPermissions().includes(op);
  };
}

export const filterCodeMapData = (type, language) => {
  let data = JSON.parse(sessionStorage.getItem("CodeMapData")) || {};
  let data2 = [];
  if (language == "en") {
    data[type]?.map((l) => {
      data2.push({
        value: l.value,
        ...l.enCodeDesc,
      });
    });
  } else if (language == "zhtw") {
    data[type]?.map((l) => {
      data2.push({
        value: l.value,
        ...l.zhCkCodeDesc,
      });
    });
  } else {
    data[type]?.map((l) => {
      data2.push({
        value: l.value,
        ...l.zhCnCodeDesc,
      });
    });
  }

  return data2;
};


import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { defineComponent } from "vue";

const valueDateFormat = "yyyy-MM-dd";

export function parseValue(value: string): Date {
  return parse(value, valueDateFormat, new Date());
}

function normalizeDisplayDateFormat(
  dateStr: string,
  displayDateFormat: string
) {
  return format(
    parse(dateStr, displayDateFormat, new Date()),
    displayDateFormat
  );
}

function fromValueToDisplayDateFormat(
  valueDateStr: string,
  displayFormat: string
) {
  return format(
    parse(valueDateStr, valueDateFormat, new Date()),
    displayFormat
  );
}

export default defineComponent({
  name: "DatePicker",

  props: {
    value: {
      type: null,
      required: true,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    textFieldClass: {
      type: String,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    displayDateFormat: {
      type: String,
      default: "dd-MM-yyyy",
    },
  },

  data() {
    return {
      dateFormatted: "",
      menuOpened: false,
      date: this.value,
      messageType: 0,
    };
  },

  mounted() {
    this.dateFormatted = this.date
      ? fromValueToDisplayDateFormat(this.date, this.displayDateFormat)
      : "";
  },

  watch: {
    date() {
      this.dateFormatted = this.date
        ? fromValueToDisplayDateFormat(this.date, this.displayDateFormat)
        : "";
      this.$emit("update:value", this.date);
      if (!this.date) {
        this.$emit("change", this.date);
      }
    },

    value() {
      this.date = this.value;
      this.$emit("update:value", this.date);
      this.dateFormatted = this.date
        ? fromValueToDisplayDateFormat(this.date, this.displayDateFormat)
        : "";
    },

    dateFormatted() {
      if (!this.dateFormatted) {
        this.date = "";
        this.$emit("change", this.date);
        this.$emit("update:value", this.date);
      }
    },
  },

  methods: {
    isDate(str: string) {
      const parsed = parse(str, this.displayDateFormat, new Date());
      if (!isValid(parsed)) {
        return false;
      }
      return this.checkDate(
        parsed.getFullYear(),
        parsed.getMonth() + 1,
        parsed.getDate()
      );
    },

    checkDate(YYYY: number, MM: number, DD: number) {
      if (!(YYYY >= 1970 && YYYY <= 9999)) {
        return false;
      }
      if (!(MM >= 1 && MM <= 12)) {
        return false;
      }
      switch (MM) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (!(DD >= 1 && DD <= 31)) {
            return false;
          }
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          if (!(DD >= 1 && DD <= 30)) {
            return false;
          }
          break;
        case 2:
          if (this.isRun(YYYY)) {
            if (!(DD >= 1 && DD <= 29)) {
              return false;
            }
          } else {
            if (!(DD >= 1 && DD <= 28)) {
              return false;
            }
          }
      }
      return true;
    },

    isRun(year: number) {
      if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
        return true;
      } else {
        return false;
      }
    },

    inRange(str: string) {
      if (!this.isDate(str)) {
        return false;
      }
      let res = true;
      let large = true;
      let low = true;
      const parsed = parse(str, this.displayDateFormat, new Date());
      if (!isValid(parsed)) {
        return false;
      }
      const now = parsed.getTime();
      if (this.min) {
        const min = parse(this.min, valueDateFormat, new Date()).getTime();
        low = now >= min;
      }
      if (this.max) {
        const max = parse(this.max, valueDateFormat, new Date()).getTime();
        large = now <= max;
      }
      res = low && large;
      if (!res) {
        this.messageType = 2;
      }
      return res;
    },

    handleChange() {
      const date = this.dateFormatted;
      this.messageType = 0;
      if (!date || !this.isDate(date) || !this.inRange(date)) {
        this.date = "";
        this.dateFormatted = "";
        return "";
      }
      this.dateFormatted = normalizeDisplayDateFormat(
        date,
        this.displayDateFormat
      );
      this.date = format(
        parse(this.dateFormatted, this.displayDateFormat, new Date()),
        valueDateFormat
      );
      this.$emit("change", this.date);
    },

    handleTab() {
      const date = this.dateFormatted;
      this.messageType = 0;
      if (!date || !this.isDate(date) || !this.inRange(date)) {
        return "";
      }
      this.dateFormatted = normalizeDisplayDateFormat(
        date,
        this.displayDateFormat
      );
      this.date = format(
        parse(this.dateFormatted, this.displayDateFormat, new Date()),
        valueDateFormat
      );
    },

    handleKeyDown(event: KeyboardEvent) {
      if (event.code == "Tab") {
        this.handleTab();
      }
    },

    handleDateChange() {
      this.messageType = 0;
      this.menuOpened = false;
    },
  },
});

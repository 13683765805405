
import { defineComponent } from "vue";

import { clamp } from "../utils/clamp";

export default defineComponent({
  name: "LeaseCustomizationFormMonthsDaysInput",

  props: {
    minMonths: { type: Number, required: true },
    maxMonths: { type: Number, required: true },
    minDays: { type: Number, required: true },
    maxDays: { type: Number, required: true },
  },

  emits: ["submit"],

  data(): { months: string | null; days: string | null } {
    return {
      months: null,
      days: null,
    };
  },

  computed: {
    canAdd() {
      return this.months && this.days;
    },
  },

  methods: {
    parse(value: string | null): number | null {
      try {
        return value != null ? parseInt(value, 10) : null;
      } catch (e: unknown) {
        return null;
      }
    },
    handleMonthsChange(value: string) {
      let months = this.parse(value);
      if (months == null) {
        return;
      }
      this.months = clamp(months, [this.minMonths, this.maxMonths]).toString();
    },
    handleDaysChange(value: string) {
      let days = this.parse(value);
      if (days == null) {
        return;
      }
      this.days = clamp(days, [this.minDays, this.maxDays]).toString();
    },
    handleSubmit() {
      const months = this.parse(this.months);
      const days = this.parse(this.days);
      if (months == null || days == null) {
        return;
      }
      this.$emit("submit", months, days);
    },
    reset() {
      this.months = null;
      this.days = null;
    },
  },
});

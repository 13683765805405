import * as yup from "yup";

import { PlsDateSchema } from "./PlsDate";

export const PlsBuildingUnitSchema = yup.object({
  buildingID: yup.number().required(),
  buildingName: yup.string().required(),
  measurementUnit: yup.string().required(),
  buildingUnitID: yup.number().required(),
  buildingUnitNo: yup.string().required(),
  effectiveDate: PlsDateSchema.required(),
  expiryDate: PlsDateSchema.nullable(),
  status: yup.string().required(),
  grossFloorArea: yup.number().required(),
  lettableFloorArea: yup.number().required(),
  netFloorArea: yup.number().required(),
  otherFloorArea: yup.number().required(),
  buildingFloorID: yup.number().required(),
  floor: yup.string().required(),
  region: yup.string().required(),
});

export type PlsBuildingUnit = yup.InferType<typeof PlsBuildingUnitSchema>;

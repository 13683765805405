import { Module } from "vuex";

import { Building } from "../models/Building";
import { Maybe } from "../types/Maybe";
import { ToGetters } from "../types/vuex";

declare module "vue/types/vue" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Getters extends ToGetters<typeof getters> {}
}

interface State {
  byBuildingCode: Record<Building["code"], Building>;
}

const getters = {
  getBuildingByBuildingCode:
    (state: State) =>
    (buildingCode: Building["code"]): Maybe<Building> => {
      return state.byBuildingCode[buildingCode];
    },
};

export const buildingModule: Module<State, unknown> = {
  state: {
    byBuildingCode: {},
  },
  mutations: {
    fillBuildings(state, payload: Building[]) {
      for (const building of payload) {
        state.byBuildingCode[building.code] = building;
      }
    },
  },
  actions: {
    fillBuildings(context, payload: Building[]) {
      context.commit("fillBuildings", payload);
    },
  },
  getters,
  modules: {},
};

import * as yup from "yup";
import {
  LicensePeriodSchema,
  RentFreePeriodSchema,
  RetailLeaseCustomization,
  RetailLeaseCustomizationListSchema,
  RetailLeaseCustomizationSchema,
  TurnoverRateSchema,
} from "../models/LeaseCustomization";
import apiClient from "../utils/apiClient";

export const getRetailLeaseCustomizationsByMOCode = async (
  moCode: string,
  plsBuildingUnitIds: number[]
): Promise<RetailLeaseCustomization[]> => {
  if (plsBuildingUnitIds.length === 0) {
    return [];
  }
  const response = await apiClient.get(
    `stackingplan/retail-lease-customizations/mo-code/${moCode}?plsBuildingUnitIds=${plsBuildingUnitIds.join(
      ","
    )}`
  );

  return RetailLeaseCustomizationListSchema.validate(response);
};

export const createOrUpdateRetailLeaseCustomization = async (
  requestBody: RequestBody
): Promise<RetailLeaseCustomization> => {
  const response = await apiClient.put(
    `stackingplan/retail-lease-customizations`,
    requestBody
  );

  return RetailLeaseCustomizationSchema.validate(response);
};

export const RequestBodySchema = yup.object({
  moCode: yup.string().required(),
  buildingCode: yup.string().required(),
  plsBuildingUnitId: yup.number().required(),
  plsLeaseUnitId: yup.number().nullable(),
  effectiveUnitRentStartDate: yup.date().nullable(),
  unitArea: yup.number().nullable(),
  tenantName: yup.string().nullable(),
  faceRent: yup.number().nullable(),
  faceUnitRent: yup.number().nullable(),
  effectiveUnitRent: yup.number().nullable(),
  licensePeriods: yup.array().of(LicensePeriodSchema.required()).required(),
  rentFreePeriods: yup.array().of(RentFreePeriodSchema.required()).required(),
  turnoverRates: yup.array().of(TurnoverRateSchema.required()).required(),
  foodLicense: yup.string().nullable(),
  status: yup.string().nullable(),
  excludeInSummary: yup.boolean().required(),
  outsideSittingArea: yup.boolean().required(),
  includeInCalculationWithoutNewLeases: yup.boolean().nullable(),
  includeInCalculationWithNewLeases: yup.boolean().nullable(),
  includeInOccupied: yup.boolean().nullable(),
  version: yup.number().nullable(),
});

export type RequestBody = yup.InferType<typeof RequestBodySchema>;


import { defineComponent, PropType } from "vue";
import { Lease } from "../models/Lease";
import { RetailLeaseCustomization } from "../models/LeaseCustomization";
import RetailLeaseCustomizationForm from "./RetailLeaseCustomizationForm.vue";
import {
  FormState,
  FormValueBase,
  SelectOption,
} from "./RetailLeaseCustomizationFormViewModel";

export default defineComponent({
  name: "RetailCustomizationDialog",

  components: { RetailLeaseCustomizationForm },

  emits: ["cancel", "submitSuccess"],

  props: {
    isOpen: Boolean,
    moCode: String,
    buildingCode: String,
    statusOptions: Array as PropType<SelectOption<string>[]>,
    foodLicenseOptions: Array as PropType<SelectOption<string>[]>,
    formValueBase: Object as PropType<FormValueBase>,
    initialEnableCustomization: Boolean,
    initialFormState: Object as PropType<FormState>,
    lease: Object as PropType<Lease>,
    customization: Object as PropType<RetailLeaseCustomization>,
  },

  methods: {
    handleSubmitSuccess() {
      this.$emit("submitSuccess");
    },

    handleCancel() {
      this.$emit("cancel");
    },
  },
});

import * as yup from "yup";
import parse from "date-fns/parse";

function parsePlsDate(value: unknown, originalValue: string) {
  return originalValue != null
    ? parse(`${originalValue}+0800`, "yyyy-MM-ddxxxx", new Date())
    : null;
}

export const PlsDateSchema = yup.date().transform(parsePlsDate);


import { defineComponent } from "vue";

export default defineComponent({
  name: "StackingPlanAddNegotiationView",
  methods: {
    handleClick() {
      console.log(this.$route.query.redirect);
      this.$router.push({ path: String(this.$route.query.redirect) });
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "CollapsibleArea",

  props: {
    thresholdHeight: {
      type: Number,
      required: true,
    },
    collapsedHeight: {
      type: Number,
      required: true,
    },
  },

  data(): {
    mode: "default" | "collapsed" | "expanded";
  } {
    return {
      mode: "default",
    };
  },

  mounted() {
    this.updateDisplayMode();
  },

  updated() {
    this.updateDisplayMode();
  },

  methods: {
    updateDisplayMode() {
      const { clientHeight: contentHeight } = this.$refs.content as Element;
      if (contentHeight > this.thresholdHeight) {
        if (this.mode === "default") {
          this.mode = "collapsed";
        }
      } else {
        this.mode = "default";
      }
    },
    toggle() {
      switch (this.mode) {
        case "collapsed":
          this.mode = "expanded";
          break;
        case "expanded":
          this.mode = "collapsed";
          break;
        default:
      }
    },
  },
});

import * as yup from "yup";
import { Building, BuildingSchema } from "../models/Building";
import { Valid } from "../types/Validate";
import apiClient from "../utils/apiClient";

export const UpdateBuildingRemarksSchema = yup
  .string()
  .max(2000, "maxLength:${max}");

export const createBuildingRemarks = async (
  buildingCode: string,
  { data: remarks }: Valid<yup.InferType<typeof UpdateBuildingRemarksSchema>>
): Promise<Building> => {
  const response = await apiClient.post(
    `stackingplan/buildings/${buildingCode}/remarks`,
    {
      stackingPlanRemarks: remarks,
    }
  );
  return await BuildingSchema.validate(response);
};

export const updateBuildingRemarks = async (
  buildingCode: string,
  { data: remarks }: Valid<yup.InferType<typeof UpdateBuildingRemarksSchema>>
): Promise<Building> => {
  const response = await apiClient.put(
    `stackingplan/buildings/${buildingCode}/remarks`,
    {
      stackingPlanRemarks: remarks,
    }
  );
  return await BuildingSchema.validate(response);
};

import Vue from "vue";
import VueI18n from "vue-i18n";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: {},
  },
  zhcn: {
    $vuetify: {},
  },
  zhtw: {
    $vuetify: {},
  },
};

// Copied (and modified) from web-mgmt
let i18nLocale = localStorage.getItem("i18nLocale");
if (i18nLocale == null) {
  i18nLocale = "en";
  localStorage.setItem("i18nLocale", i18nLocale);
}

const i18n = new VueI18n({
  locale: i18nLocale,
  messages,
  // silentTranslationWarn: true,
});

const vuetify = new Vuetify({
  lang: {
    current: i18nLocale,
    t: (key, ...params) => i18n.t(key, params) as string,
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#1D3557",
        secondary: "#333333",
        accent: "#3A4F64",
        error: "#FC1B1C",
        warning: "#E7B34B",
        info: "#EBE9E3",
        success: "#6AB262",
        pageDisable: "#D2DADD",
      },
    },
  },
});

export { vuetify, i18n };


import {
  RemoteData,
  getOrElse,
  initial,
  pending,
  success,
  failure,
  isInitial,
  isSuccess,
  isPending,
} from "@devexperts/remote-data-ts";
import format from "date-fns/format";
import { PropType, defineComponent } from "vue";

import { getNegotiationsByBuildingCodeAndBuildingUnitIds } from "../apis/negotiation";
import {
  createPlsBuildingUnitLeaseRemarks,
  updatePlsBuildingUnitLeaseRemarks,
  getPlsBuildingUnitLeaseFloorPlanImage,
  createPlsBuildingUnitLeaseFloorPlanImage,
  updatePlsBuildingUnitLeaseFloorPlanImage,
  removePlsBuildingUnitLeaseFloorPlanImage,
} from "../apis/plsBuildingUnitLease";
import formatDate from "../filters/date";
import getOptionName from "../filters/option";
import { getGrouping, getCof, isVacant, isRetail } from "../utils/lease";
import {
  deriveNegotiationDescription,
  formatRentReview,
} from "../utils/negotiation";
import { getOptions } from "../utils/options";
import { uploadFile } from "../utils/oss";
import { isFileSizeLessThan, MB } from "../utils/validateFileSize";
import {
  isImageDimensionsLessThan,
  imageFromFile,
} from "../utils/validateImageDimensions";
import { LeaseAndCustomization } from "../types/LeaseAndCustomization";
import { KeyAttribute } from "../types/LeaseKeyAttribute";
import { Maybe } from "../types/Maybe";
import { Building } from "../models/Building";
import {
  Negotiation,
  NegotiationRentReview,
  NegotiationAdditionalCondition,
} from "../models/Negotiation";
import {
  getKeyAttribute,
  Lease,
  OfficeLease,
  RetailLease,
} from "../models/Lease";
import {
  OfficeLeaseCustomization,
  UnitFeature,
  UnitFacility,
  LeaseCustomization,
  RetailLeaseCustomization,
} from "../models/LeaseCustomization";
import { formatMonthDayInterval } from "../models/MonthDay";
import { File as OssFile } from "../models/File";

import BuildingRemarksDialog from "./BuildingRemarksDialog.vue";
import LeaseCustomizationDialog from "./LeaseCustomizationDialog.vue";
import {
  FormState as LeaseCustomizationFormState,
  FormValueBase as LeaseCustomizationFormValueBase,
  makeFormState,
  makeFormValueBase,
  SelectOption,
} from "./LeaseCustomizationFormViewModel";
import OSSImage from "./OSSImage.vue";
import RetailLeaseCustomizationDialog from "./RetailLeaseCustomizationDialog.vue";
import {
  FormState as RetailLeaseCustomizationFormState,
  FormValueBase as RetailLeaseCustomizationFormValueBase,
  makeFormState as makeRetailFormState,
  makeFormValueBase as makeRetailFormValueBase,
  makeFormValueBaseFromOfficeLease as makeRetailFormValueBaseFromOfficeLease,
} from "./RetailLeaseCustomizationFormViewModel";
import LegalDocumentChip from "./LegalDocumentChip.vue";
import TextDialog, { TextDialogConfig } from "./TextDialog.vue";
import OpportunityTypeBadge from "./OpportunityTypeBadge.vue";

type LeaseAndKind =
  | {
      kind: "office";
      lease: Maybe<OfficeLease>;
      customization: Maybe<OfficeLeaseCustomization>;
    }
  | {
      kind: "office-with-retail-customization";
      lease: Maybe<OfficeLease>;
      customization: Maybe<RetailLeaseCustomization>;
    }
  | {
      kind: "retail";
      lease: Maybe<RetailLease>;
      customization: Maybe<RetailLeaseCustomization>;
    };

export default defineComponent({
  name: "BuildingUnitDetailsDialog",

  components: {
    BuildingRemarksDialog,
    LeaseCustomizationDialog,
    RetailLeaseCustomizationDialog,
    LegalDocumentChip,
    TextDialog,
    OpportunityTypeBadge,
    OSSImage,
  },

  props: {
    value: Boolean,
    data: Object as PropType<{
      kind: "office" | "retail";
      moCode: string;
      buildingCode: string;
      buildingUnitId: number;
      keyAttribute: Maybe<KeyAttribute>;
      building: Building;
    }>,
  },

  emits: ["closeClick", "input"],

  data() {
    return {
      carouselItemIndex: 0,
      isBuildingUnitRemarksDialogOpen: false,
      updateUnitFloorPlanState: initial as RemoteData<unknown, unknown>,
      removeUnitFloorPlanState: initial as RemoteData<unknown, unknown>,
      itemIndexInitialized: false,
      unitFloorPlanRemoteData: initial as RemoteData<unknown, Maybe<OssFile>>,
      negotiationsRemoteData: initial as RemoteData<unknown, Negotiation[]>,
      leaseCustomizationDialogData: { isOpen: false } as {
        isOpen: boolean;
        buildingCode?: string;
        statusOptions?: SelectOption<string>[];
        availableUnits?: SelectOption<KeyAttribute>[];
        availableFacilities?: SelectOption<string>[];
        availableUnitFeatures?: SelectOption<string>[];
        formValueBase?: LeaseCustomizationFormValueBase;
        initialEnableCustomization: boolean;
        initialFormState?: LeaseCustomizationFormState;
        leaseAndCustomizations?: LeaseAndCustomization[];
      },
      retailLeaseCustomizationDialogData: { isOpen: false } as {
        isOpen: boolean;
        moCode?: string;
        buildingCode?: string;
        statusOptions?: SelectOption<string>[];
        foodLicenseOptions?: SelectOption<string>[];
        formValueBase?: RetailLeaseCustomizationFormValueBase;
        initialEnableCustomization: boolean;
        initialFormState?: RetailLeaseCustomizationFormState;
        lease?: Lease;
        customization?: RetailLeaseCustomization;
      },
      removeFloorPlanImageDialogIsOpen: false,
    };
  },

  computed: {
    removeFloorPlanImageDialogConfig(): TextDialogConfig {
      return {
        title: this.$skp.t(
          "buildingUnitDetailsDialog.removeFloorPlanDialog.title"
        ),
        message: this.$skp.t(
          "buildingUnitDetailsDialog.removeFloorPlanDialog.message"
        ),
        buttons: [
          {
            text: this.$skp.t(
              "buildingUnitDetailsDialog.removeFloorPlanDialog.confirmButtonText"
            ),
            style: {
              depressed: true,
            },
            disabled: this.isRemovingFloorPlanImage,
            loading: this.isRemovingFloorPlanImage,
            color: "primary",
            action: () => {
              this.doRemoveFloorPlanImage();
            },
          },
          {
            text: this.$skp.t(
              "buildingUnitDetailsDialog.removeFloorPlanDialog.cancelButtonText"
            ),
            style: {
              outlined: true,
            },
            disabled: this.isRemovingFloorPlanImage,
            color: "grey",
            action: () => {
              this.handleRemoveFloorPlanImageDialogDismiss();
            },
          },
        ],
      };
    },

    isRemovingFloorPlanImage() {
      return isPending(this.removeUnitFloorPlanState);
    },

    isRemoteDataInitial() {
      return (
        isInitial(this.negotiationsRemoteData) &&
        isInitial(this.unitFloorPlanRemoteData)
      );
    },
    isRemoteDataSuccess() {
      return (
        isSuccess(this.negotiationsRemoteData) &&
        isSuccess(this.unitFloorPlanRemoteData)
      );
    },
    leaseAndKind(): Maybe<LeaseAndKind> {
      if (!this.data?.keyAttribute) {
        return null;
      }
      switch (this.data.kind) {
        case "office": {
          const lease = this.$store.getters[
            "stackingPlan/getOfficeLeaseByKeyAttribute"
          ](this.data.keyAttribute);
          if (lease != null && isRetail(lease)) {
            return {
              kind: "office-with-retail-customization",
              lease,
              customization: this.$store.getters[
                "stackingPlan/getRetailLeaseCustomization"
              ](this.data.keyAttribute),
            };
          }
          return {
            kind: "office",
            lease,
            customization: this.$store.getters[
              "stackingPlan/getLeaseCustomization"
            ](this.data.keyAttribute),
          };
        }
        case "retail":
          return {
            kind: "retail",
            lease: this.$store.getters[
              "stackingPlan/getRetailLeaseByKeyAttribute"
            ](this.data.keyAttribute),
            customization: this.$store.getters[
              "stackingPlan/getRetailLeaseCustomization"
            ](this.data.keyAttribute),
          };
        default:
          return null;
      }
    },
    building(): Maybe<Building> {
      if (!this.data?.buildingCode) {
        return null;
      }
      return this.$store.getters["stackingPlan/getBuildingByBuildingCode"](
        this.data.buildingCode
      );
    },
    lease(): Maybe<Lease> {
      return this.leaseAndKind?.lease;
    },
    leaseCustomization(): Maybe<LeaseCustomization> {
      return this.leaseAndKind?.customization ?? null;
    },
    leaseTitle(): Maybe<string> {
      if (this.leaseAndKind == null) {
        return null;
      }
      const { lease, kind } = this.leaseAndKind;
      switch (kind) {
        case "office":
          return lease?.tenant?.tenantName;
        case "retail":
          return lease?.leaseUnit?.leaseShop?.shopName;
        default:
          return null;
      }
    },
    leasePeriod() {
      if (this.lease == null) {
        return null;
      }
      const effectiveDate = this.lease.leaseUnit?.effectiveDate;
      const expiryDate = this.lease.leaseUnit?.expiryDate;
      if (!effectiveDate || !expiryDate) {
        return null;
      }
      const monthDayString = formatMonthDayInterval(effectiveDate, expiryDate);
      return `${format(effectiveDate, "dd/MM/yyyy")} - ${format(
        expiryDate,
        "dd/MM/yyyy"
      )} (${monthDayString})`;
    },
    unitFeatures() {
      if (!this.data?.keyAttribute) {
        return null;
      }
      const unitFeatures: Maybe<UnitFeature[]> = this.$store.getters[
        "stackingPlan/getUnitFeatures"
      ](this.data.keyAttribute);
      if (!unitFeatures || unitFeatures.length === 0) {
        return null;
      }
      return unitFeatures.map(({ feature }) => feature);
    },
    unitFacilities() {
      if (!this.data?.keyAttribute) {
        return null;
      }
      const unitFacilities: Maybe<UnitFacility[]> = this.$store.getters[
        "stackingPlan/getUnitFacilities"
      ](this.data.keyAttribute);
      if (!unitFacilities || unitFacilities.length === 0) {
        return null;
      }
      return unitFacilities
        .map(({ facility }) => {
          return (
            getOptionName(facility, "leaseCustomizationFacilitieOptions") ||
            facility
          );
        })
        .join(", ");
    },
    unitNegotiations(): Negotiation[] {
      if (this.data == null || !isSuccess(this.negotiationsRemoteData)) {
        return [];
      }
      return this.$store.getters["stackingPlan/getNegotiations"](
        this.data.buildingUnitId
      );
    },
    unitNegotiationsByOpportunity(): {
      opportunity: Negotiation["opportunity"];
      negotiations: Negotiation[];
    }[] {
      const byOpportunityId: Record<
        number,
        {
          opportunity: Negotiation["opportunity"];
          negotiations: Negotiation[];
        }
      > = {};
      this.unitNegotiations.forEach((negotiation) => {
        const { opportunity } = negotiation;
        if (!(opportunity.id in byOpportunityId)) {
          byOpportunityId[opportunity.id] = {
            opportunity,
            negotiations: [],
          };
        }
        byOpportunityId[opportunity.id].negotiations.push(negotiation);
      });
      return Object.values(byOpportunityId);
    },
    unitRemarks() {
      const { buildingUnitId, keyAttribute } = this.data ?? {};
      if (buildingUnitId == null || keyAttribute == null) {
        return null;
      }
      return (
        this.$store.getters["stackingPlan/getPlsBuildingUnitLease"](
          keyAttribute
        )?.remarks ?? null
      );
    },

    unitFloorPlanImage() {
      return getOrElse((): Maybe<OssFile> => null)(
        this.unitFloorPlanRemoteData
      );
    },

    unitFloorPlanImageIsPending() {
      return (
        isPending(this.updateUnitFloorPlanState) ||
        isPending(this.removeUnitFloorPlanState)
      );
    },

    canReadNegotiation() {
      if (this.data?.kind === "office") {
        return this.building?.canReadOfficeNegotiation;
      } else if (this.data?.kind === "retail") {
        return this.building?.canReadRetailNegotiation;
      }
      return false;
    },

    canCreateNegotiation() {
      if (this.data?.kind === "office") {
        return this.building?.canCreateOfficeNegotiation;
      } else if (this.data?.kind === "retail") {
        return this.building?.canCreateRetailNegotiation;
      }
      return false;
    },

    canUpdateNegotiation() {
      if (this.data?.kind === "office") {
        return this.building?.canUpdateOfficeNegotiation;
      } else if (this.data?.kind === "retail") {
        return this.building?.canUpdateRetailNegotiation;
      }
      return false;
    },

    canCustomize() {
      return this.building?.canCustomize;
    },
  },

  updated() {
    if (this.value) {
      this.fetchRemoteData();
    }
  },

  methods: {
    async fetchRemoteData() {
      if (isInitial(this.negotiationsRemoteData)) {
        this.fetchNegotiations();
      }
      if (isInitial(this.unitFloorPlanRemoteData)) {
        this.fetchUnitFloorPlan();
      }
    },
    async fetchNegotiations() {
      if (this.data == null) {
        return;
      }
      if (!this.canReadNegotiation) {
        this.negotiationsRemoteData = success([]);
        return;
      }
      this.negotiationsRemoteData = pending;
      try {
        const negotiations =
          await getNegotiationsByBuildingCodeAndBuildingUnitIds(
            this.data.buildingCode,
            [this.data.buildingUnitId]
          );
        this.$store.dispatch("stackingPlan/fillNegotiations", negotiations);
        this.negotiationsRemoteData = success(negotiations);
      } catch (e: unknown) {
        this.negotiationsRemoteData = failure(e);
        this._closeDialogOnFailure();
      }
    },

    async fetchUnitFloorPlan() {
      if (this.data == null || !this.lease) {
        return;
      }
      const { buildingCode, buildingUnitId, keyAttribute } = this.data;
      if (
        keyAttribute?.plsLeaseUnitId == null ||
        !this.data.building?.canReadUnitFloorPlan
      ) {
        this.unitFloorPlanRemoteData = success(null);
        return;
      }
      this.unitFloorPlanRemoteData = pending;
      try {
        const unitFloorPlanImageFileList =
          await getPlsBuildingUnitLeaseFloorPlanImage(
            buildingCode,
            buildingUnitId,
            this.lease.leaseUnit?.leaseUnitID,
            this.lease.leaseUnit?.effectiveUnitRent?.startDate
          );
        const file = unitFloorPlanImageFileList?.[0];
        this.unitFloorPlanRemoteData = success(file);
      } catch (e: unknown) {
        this.unitFloorPlanRemoteData = failure(e);
        this._closeDialogOnFailure();
      }
    },

    handleInput(value: boolean) {
      this.$emit("input", value);
    },

    handleCloseClick() {
      this._resetRemoteDataStates();
      this.$emit("closeClick");
    },

    handleCustomizationClick() {
      if (
        this.data == null ||
        this.lease == null ||
        this.leaseAndKind == null ||
        this.leaseAndKind.lease == null
      ) {
        return;
      }

      const { kind, lease, customization } = this.leaseAndKind;

      switch (kind) {
        case "office":
          this.handleCustomizeOfficeUnitsClick(
            this.data.buildingCode,
            lease,
            customization
          );
          break;
        case "office-with-retail-customization":
          this.handleCustomizeRetailUnitsFromOfficeClick(
            lease.managementOfficeCode,
            this.data.buildingCode,
            lease,
            customization
          );
          break;
        case "retail":
          this.handleCustomizeRetailUnitsClick(
            this.data.moCode,
            this.data.buildingCode,
            lease,
            customization
          );
          break;
      }
    },

    handleCustomizeOfficeUnitsClick(
      buildingCode: string,
      lease: OfficeLease,
      customization: Maybe<OfficeLeaseCustomization>
    ) {
      const officeLeases = this.$store.getters["stackingPlan/getLeases"]();

      const { leases } = getGrouping(
        {
          lease: lease,
          customization: customization,
        },
        officeLeases.map((lease) => ({
          lease,
          customization: this.$store.getters[
            "stackingPlan/getLeaseCustomization"
          ](getKeyAttribute(lease)),
        }))
      );
      const groupedLeaseAndCustomizations = leases.map((lease) => ({
        lease,
        customization: this.$store.getters[
          "stackingPlan/getLeaseCustomization"
        ](getKeyAttribute(lease)),
      }));
      const statusOptions = getOptions("leaseCustomizationStatusOptions");

      const keyAttributes = leases.map(getKeyAttribute);

      const unitFeatures: UnitFeature[] =
        this.$store.getters["stackingPlan/getUnitFeaturesByKeyAttributes"](
          keyAttributes
        );

      const unitFacilities: UnitFacility[] =
        this.$store.getters["stackingPlan/getUnitFacilitiesByKeyAttributes"](
          keyAttributes
        );
      const cof = getCof(lease, officeLeases, "office", new Date());

      this.leaseCustomizationDialogData = {
        isOpen: true,
        buildingCode: buildingCode,
        statusOptions,
        availableUnits: leases.map((x) => ({
          name: x.buildingUnitNo,
          value: getKeyAttribute(x),
        })),
        availableFacilities: getOptions("leaseCustomizationFacilitieOptions"),
        availableUnitFeatures: getOptions(
          "leaseCustomizationUnitFeatureOptions"
        ),
        formValueBase: makeFormValueBase(
          lease,
          officeLeases,
          leases,
          statusOptions,
          cof
        ),
        initialEnableCustomization: customization != null,
        initialFormState: makeFormState(
          customization,
          unitFeatures,
          unitFacilities,
          cof,
          getOptions("leaseCustomizationFacilitieOptions")
        ),
        leaseAndCustomizations: groupedLeaseAndCustomizations,
      };
    },

    handleCustomizeRetailUnitsFromOfficeClick(
      moCode: string,
      buildingCode: string,
      lease: OfficeLease,
      customization: Maybe<RetailLeaseCustomization>
    ) {
      const leases = this.$store.getters["stackingPlan/getLeases"]();
      const statusOptions = getOptions("leaseCustomizationStatusOptions");
      const cof = getCof(lease, leases, "office", new Date());

      this.retailLeaseCustomizationDialogData = {
        isOpen: true,
        moCode,
        buildingCode,
        statusOptions,
        foodLicenseOptions: getOptions("leaseCustomizationFoodLicenseOptions"),
        formValueBase: makeRetailFormValueBaseFromOfficeLease(
          lease,
          leases,
          statusOptions,
          cof
        ),
        initialEnableCustomization: customization != null,
        initialFormState: makeRetailFormState(customization, cof),
        lease,
        customization: customization ?? undefined,
      };
    },

    handleCustomizeRetailUnitsClick(
      moCode: string,
      buildingCode: string,
      lease: RetailLease,
      customization: Maybe<RetailLeaseCustomization>
    ) {
      const leases = this.$store.getters["stackingPlan/getRetailLeases"]();
      const statusOptions = getOptions("leaseCustomizationStatusOptions");
      const cof = getCof(lease, leases, "retail", new Date());

      this.retailLeaseCustomizationDialogData = {
        isOpen: true,
        moCode: moCode,
        buildingCode: buildingCode,
        statusOptions,
        foodLicenseOptions: getOptions("leaseCustomizationFoodLicenseOptions"),
        formValueBase: makeRetailFormValueBase(
          lease,
          leases,
          statusOptions,
          cof
        ),
        initialFormState: makeRetailFormState(customization, cof),
        lease,
        customization: customization ?? undefined,
      };
    },

    handleAddNegotiationClick() {
      if (
        this.data == null ||
        this.lease == null ||
        this.lease.tenant == null
      ) {
        return;
      }
      this.$router.push({
        path: "/StackingPlan/AddNegotiation",
        query: {
          tenantId: String(this.lease.tenant.tenantID),
          tenantName: this.lease.tenant.tenantName,
          tenantShortName: this.lease.tenant.tenantShortName,
          moCode: this.lease.managementOfficeCode,
          buildingCode: this.data.buildingCode,
          buildingUnitId: String(this.data.buildingUnitId),
          usage: this.data.kind === "office" ? "Office" : "Retail",
          redirect: this.$route.fullPath,
        },
      });
    },

    handleEditNegotiationClick(negotiation: Negotiation) {
      if (
        this.data == null ||
        this.lease == null ||
        this.lease.tenant == null
      ) {
        return;
      }
      this.$router.push({
        path: "/StackingPlan/EditNegotiation",
        query: {
          negotiationId: String(negotiation.id),
          opportunityId: String(negotiation.opportunityId),
          tenantId: String(this.lease.tenant.tenantID),
          tenantName: this.lease.tenant.tenantName,
          tenantShortName: this.lease.tenant.tenantShortName,
          moCode: this.lease.managementOfficeCode,
          buildingCode: this.data.buildingCode,
          buildingUnitId: String(this.data.buildingUnitId),
          usage: this.data.kind === "office" ? "Office" : "Retail",
          redirect: this.$route.fullPath,
        },
      });
    },

    handleEditRemarksClick() {
      this.isBuildingUnitRemarksDialogOpen = true;
    },

    handleBuildingUnitRemarksCancelClick() {
      this.isBuildingUnitRemarksDialogOpen = false;
    },

    handleBuildingUnitRemarksConfirm() {
      this.isBuildingUnitRemarksDialogOpen = false;
      this.$store.dispatch(
        "stackingPlan/showResultUpdateSnackbar",
        this.$skp.t("buildingUnitDetailsDialog.toast.updateRemarksSuccess")
      );
    },

    async updateRemarks(value: string) {
      if (this.data == null || !this.lease) {
        return;
      }
      const api = !this.unitRemarks
        ? createPlsBuildingUnitLeaseRemarks
        : updatePlsBuildingUnitLeaseRemarks;
      const { moCode, buildingCode } = this.data;
      const plsBuildingUnitLease = await api(
        moCode,
        buildingCode,
        this.lease.buildingUnitID,
        this.lease.leaseUnit?.leaseUnitID,
        this.lease.leaseUnit?.effectiveUnitRent?.startDate,
        value
      );
      this.$store.dispatch(
        "stackingPlan/updatePlsBuildingUnitLease",
        plsBuildingUnitLease
      );
    },

    handleLeaseCustomizationSubmitSuccess() {
      this.leaseCustomizationDialogData = {
        isOpen: false,
      };
      this.$store.dispatch(
        "stackingPlan/showResultUpdateSnackbar",
        this.$skp.t(
          "buildingUnitDetailsDialog.toast.updateLeaseCustomizationSuccess"
        )
      );
    },

    handleRetailLeaseCustomizationSubmitSuccess() {
      this.retailLeaseCustomizationDialogData = {
        isOpen: false,
      };
      this.$store.dispatch(
        "stackingPlan/showResultUpdateSnackbar",
        this.$skp.t(
          "buildingUnitDetailsDialog.toast.updateLeaseCustomizationSuccess"
        )
      );
    },

    handleLeaseCustomizationCancel() {
      this.leaseCustomizationDialogData = {
        isOpen: false,
      };
    },

    handleRetailLeaseCustomizationCancel() {
      this.retailLeaseCustomizationDialogData = {
        isOpen: false,
      };
    },

    handleDropFloorPlanImage(e: DragEvent) {
      if (isPending(this.updateUnitFloorPlanState)) {
        return;
      }
      if (!this.data?.building.canCreateUnitFloorPlan) {
        return;
      }
      const { dataTransfer } = e;
      if (!dataTransfer) {
        return;
      }
      const {
        files: [file],
      } = dataTransfer;
      if (!file) {
        return;
      }
      this.doUpdateUnitFloorPlan(file);
    },

    handleEmptyViewClick() {
      if (isPending(this.updateUnitFloorPlanState)) {
        return;
      }
      if (!this.data?.building.canCreateUnitFloorPlan) {
        return;
      }
      this.$refs.floorPlanImageFileInput?.click();
    },

    handleFloorPlanImageInputClick() {
      if (this.$refs.floorPlanImageFileInput) {
        this.$refs.floorPlanImageFileInput.value = "";
      }
    },

    handleFloorPlanImageChange(e: Event) {
      if (isPending(this.updateUnitFloorPlanState)) {
        return;
      }
      const target = e.target as HTMLInputElement | null;
      if (!target) {
        return;
      }
      const files = target.files;
      if (!files || files.length === 0) {
        return;
      }
      const [file] = files;
      this.doUpdateUnitFloorPlan(file);
    },

    async doUpdateUnitFloorPlan(file: File) {
      if (this.data == null || this.lease == null) {
        return;
      }
      const { moCode, buildingCode } = this.data;

      if (isImageDimensionsLessThan(300, 300)(await imageFromFile(file))) {
        alert(this.$skp.t("alert.common.minImageDimension", 300, 300));
        return;
      }
      if (!isFileSizeLessThan(MB(10))(file)) {
        alert(this.$skp.t("alert.common.maxFileSize", "10MB"));
        return;
      }

      this.updateUnitFloorPlanState = pending;
      try {
        const resp = await uploadFile(file);
        const api =
          this.unitFloorPlanImage != null
            ? createPlsBuildingUnitLeaseFloorPlanImage
            : updatePlsBuildingUnitLeaseFloorPlanImage;
        const unitFloorPlanFileList = await api(
          moCode,
          buildingCode,
          this.lease.buildingUnitID,
          this.lease.leaseUnit?.leaseUnitID,
          this.lease.leaseUnit?.effectiveUnitRent?.startDate,
          {
            fileName: resp.name,
            fileRealName: file.name,
            filePath: null,
            fileSize: null,
            fileSuffix: null,
          }
        );
        this.updateUnitFloorPlanState = success(resp);
        this.unitFloorPlanRemoteData = success(unitFloorPlanFileList?.[0]);
      } catch (e) {
        if (e instanceof Error) {
          this.updateUnitFloorPlanState = failure(e);
        }
        throw e;
      }
    },

    handleRemoveFloorPlanImageClick() {
      this.removeFloorPlanImageDialogIsOpen = true;
    },

    handleRemoveFloorPlanImageSuccess() {
      this.removeFloorPlanImageDialogIsOpen = false;
      this.$store.dispatch(
        "stackingPlan/showResultUpdateSnackbar",
        this.$skp.t(
          "buildingUnitDetailsDialog.toast.removeFloorPlanImageSuccess"
        )
      );
    },

    handleRemoveFloorPlanImageDialogDismiss() {
      this.removeFloorPlanImageDialogIsOpen = false;
    },

    async doRemoveFloorPlanImage() {
      if (isPending(this.removeUnitFloorPlanState)) {
        return;
      }
      if (this.data == null || this.lease == null) {
        return;
      }
      const { buildingCode } = this.data;
      this.removeUnitFloorPlanState = pending;
      try {
        const resp = await removePlsBuildingUnitLeaseFloorPlanImage(
          buildingCode,
          this.lease.buildingUnitID,
          this.lease.leaseUnit?.leaseUnitID,
          this.lease.leaseUnit?.effectiveUnitRent?.startDate
        );
        this.$store.dispatch("stackingPlan/updatePlsBuildingUnitLease", resp);
        this.removeUnitFloorPlanState = success(resp);
        this.unitFloorPlanRemoteData = success(null);
        this.handleRemoveFloorPlanImageSuccess();
      } catch (e) {
        if (e instanceof Error) {
          this.removeUnitFloorPlanState = failure(e);
        }
        throw e;
      }
    },

    _resetRemoteDataStates() {
      this.negotiationsRemoteData = initial;
      this.unitFloorPlanRemoteData = initial;
    },

    _closeDialogOnFailure() {
      this._resetRemoteDataStates();
      this.$emit("closeClick");
    },

    formatDate,
    getOptionName,
  },

  filters: {
    formatDate,

    formatNegotiation(negotiation: Negotiation) {
      return deriveNegotiationDescription(negotiation);
    },

    formatRentReview(rentReview: NegotiationRentReview) {
      const { rentReviewDate, rentReviewType, cap, collar } = rentReview;
      return formatRentReview(rentReviewDate, rentReviewType, cap, collar);
    },

    formatAdditionalCondition(
      additionalCondition: NegotiationAdditionalCondition
    ) {
      const {
        type,
        rentReviewType,
        renewDate,
        cap,
        collar,
        premisesType,
        noticePeriodMonth,
        surrenderRight,
        subletRight,
        remarks,
      } = additionalCondition;
      const remarksString = remarks ? ` (${remarks})` : "";
      switch (type) {
        case "Option to Renew":
          return (
            `Option to renew on ${formatRentReview(
              renewDate,
              rentReviewType,
              cap,
              collar
            )}` + remarksString
          );
        case "2nd Option to Renew":
          return (
            `2nd option to renew on ${formatRentReview(
              renewDate,
              rentReviewType,
              cap,
              collar
            )}` + remarksString
          );
        case "Surrender Right":
          return (
            (premisesType === "Partial"
              ? `Surrender right up to ${surrenderRight}% partial premises with ${noticePeriodMonth} months' notice`
              : premisesType === "Whole"
              ? `Surrender right whole premises with ${noticePeriodMonth} months' notice`
              : "Surrender Right") + remarksString
          );
        case "Sublet Right":
          return `Sublet right ${subletRight}%` + remarksString;
        case "Termination Right":
          return (
            `Termination right with ${noticePeriodMonth} months' notice` +
            remarksString
          );
        case "Signage Right":
          return `Signage right` + remarksString;
        case "Refusal Right":
          return `Refusal right` + remarksString;
        case "2nd Refusal Right":
          return `2nd Refusal right` + remarksString;
        case "Relocation Right":
          return (
            `Relocation right with ${noticePeriodMonth} months' notice` +
            remarksString
          );
      }
    },
  },

  watch: {
    value: {
      handler(isDialogOpen: boolean) {
        if (isDialogOpen) {
          this.fetchRemoteData();
        }
      },
      immediate: true,
    },
    lease: {
      handler() {
        this.fetchRemoteData();
      },
    },
  },
});

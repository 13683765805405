// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
import * as msal from "@azure/msal-browser";
import { router } from "../../router";
import { getCookies, setCookies } from "../auth";
import { loginRequest, msalConfig } from "./authConfig";
import { callMSGraph } from "./graph";
import { graphConfig } from "./graphConfig";
import { setToken } from "../auth";

let username = "";

/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
let timestamp = Date.parse(new Date());
let myMSALObj;
fetch(`config.json?timestamp=${timestamp}`)
  .then((resp) => resp.json())
  .then((res) => {
    msalConfig.auth.clientId = res.clientId;
    msalConfig.auth.authority = res.authority;
    msalConfig.auth.redirectUri = res.redirectUri;
    myMSALObj = new msal.PublicClientApplication(msalConfig);
    console.log(myMSALObj, "myMSALObj");
    myMSALObj
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((error) => {
        console.error(error);
      });
  });

export function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = myMSALObj.getAllAccounts();

  if (currentAccounts.length === 0) {
    return;
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
    showWelcomeMessage(username);
  }
}
export function selectAccountA() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = myMSALObj.getAllAccounts();

  if (currentAccounts.length === 0) {
    return;
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
  }
}
function handleResponse(response) {
  if (response !== null) {
    setToken(response.idToken);
    username = response.account.username;
    showWelcomeMessage(username);
  } else {
    selectAccount();
  }
}

export async function signIn() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  try {
    console.log("authRedirect.signIn");
    await myMSALObj.loginRedirect(loginRequest);
  } catch (e) {
    console.warn("authRedirect", "signIn", e);
  }
}

export function signOut() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  const logoutRequest = {
    account: myMSALObj.getAccountByUsername(username),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  };

  myMSALObj.logoutRedirect(logoutRequest);
}

export function getTokenRedirect(request) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  request.account = myMSALObj.getAccountByUsername(username);
  return myMSALObj.acquireTokenSilent(request).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using redirect"
    );
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenRedirect(request);
    } else {
      console.warn(error);
    }
  });
}

export function getIdToken() {
  let request = loginRequest;
  request.forceRefresh = true;
  getTokenRedirect(request)
    .then((response) => {
      if (response) {
        setToken(response.idToken);
        if (!getCookies("userInfo")) {
          seeProfile();
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function seeProfile() {
  let request = loginRequest;
  getTokenRedirect(request)
    .then((response) => {
      callMSGraph(
        graphConfig.graphMeEndpoint,
        response.accessToken,
        saveUserInfo
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

function saveUserInfo(res) {
  setCookies("userInfo", JSON.stringify(res));
}

function showWelcomeMessage() {
  if (!getCookies("userInfo")) {
    seeProfile();
  }
  let redirect = getCookies("redirect");
  if (getCookies("toPath")) {
    router.replace(getCookies("toPath"));
  } else if (redirect && redirect != undefined && redirect != "undefined") {
    router.replace(redirect);
  } else {
    router.replace("/");
  }
}

import { getOssKey } from "../api/oss";

const OSS = require("ali-oss");
let region = "oss-cn-shanghai";
let bucket = "spl-dev-pmp-storage";

export async function uploadFile(
  files,
  path = "/current/administration/mo-building/"
) {
  return new Promise((resolve) => {
    getOssKey().then((res) => {
      let result = put(
        res.result.accessKeyId,
        res.result.accessKeySecret,
        res.result.securityToken,
        path,
        files
      );
      resolve(result);
    });
  });
}

async function put(accessKeyId, accessKeySecret, securityToken, path, files) {
  const client = new OSS({
    // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: region,
    // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
    accessKeyId: accessKeyId,
    accessKeySecret: accessKeySecret,
    // 填写Bucket名称。
    bucket: bucket,
    stsToken: securityToken,
  });
  const headers = {
    // 指定该Object被下载时网页的缓存行为。
    // 'Cache-Control': 'no-cache',
    // 指定该Object被下载时的名称。
    // 'Content-Disposition': files.name,
    // 指定该Object被下载时的内容编码格式。
    // 'Content-Encoding': 'UTF-8',
    // 指定过期时间。
    // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
    // 指定Object的存储类型。
    // 'x-oss-storage-class': 'Standard',
    // 指定Object的访问权限。
    // 'x-oss-object-acl': 'private',
    // 设置Object的标签，可同时设置多个标签。
    // 'x-oss-tagging': 'Tag1=1&Tag2=2',
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    // 'x-oss-forbid-overwrite': 'true',
  };
  try {
    // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
    // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
    let name = files.name;
    let index = name.lastIndexOf(".");
    let ext = name.substr(index + 1);
    let returnData = await client.put(
      path + name.split(".")[0] + uuid() + new Date().getTime() + "." + ext,
      files,
      { headers }
    );
    returnData.url = await client.signatureUrl(returnData.name, {
      expires: 3600,
    });
    return returnData;
  } catch (e) {
    console.error(e);
  }
}

function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join("");
}

// OSS 文件下载
export function ossDownloadFile(fileName) {
  return new Promise((resolve, reject) => {
    getOssKey()
      .then(async (res) => {
        let region = "oss-cn-shanghai";
        let accessKeyId = res.result.accessKeyId;
        let accessKeySecret = res.result.accessKeySecret;
        let bucket = "spl-dev-pmp-storage";
        let securityToken = res.result.securityToken;
        const client = new OSS({
          // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: region,
          // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
          accessKeyId: accessKeyId,
          accessKeySecret: accessKeySecret,
          // 填写Bucket名称。
          bucket: bucket,
          stsToken: securityToken,
          // https://github.com/ali-sdk/ali-oss#ossoptions
          refreshSTSToken,
        });
        let url = client.signatureUrl(fileName, {
          expires: 3600,
        });
        resolve(url);
        return url;
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

async function refreshSTSToken() {
  return getOssKey().then((res) => {
    let accessKeyId = res.result.accessKeyId;
    let accessKeySecret = res.result.accessKeySecret;
    let securityToken = res.result.securityToken;
    return {
      accessKeyId,
      accessKeySecret,
      stsToken: securityToken,
    };
  });
}

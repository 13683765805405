
import { PropType, defineComponent } from "vue";

import {
  UpdateBuildingRemarksSchema,
  createBuildingRemarks,
  updateBuildingRemarks,
} from "../apis/building";
import { Building } from "../models/Building";
import { GrandTotalSummary } from "../models/GrandTotalSummary";
import { validate } from "../utils/validate";

import BuildingRemarksDialog from "./BuildingRemarksDialog.vue";
import GrandTotalSummaryRemarks from "./GrandTotalSummaryRemarks.vue";
import GrandTotalSummaryTable from "./GrandTotalSummaryTable.vue";
import BuildingViewCarousel from "./BuildingViewCarousel.vue";
import BuildingViewDialog from "./BuildingViewDialog.vue";
import ResultUpdateSnackbar from "./ResultUpdateSnackbar.vue";

export default defineComponent({
  name: "BuildingGrandTotalSummary",

  components: {
    BuildingRemarksDialog,
    GrandTotalSummaryRemarks,
    GrandTotalSummaryTable,
    BuildingViewCarousel,
    BuildingViewDialog,
    ResultUpdateSnackbar,
  },

  props: {
    building: Object as PropType<Building | undefined>,
    grandTotalSummary: {
      type: Object as PropType<GrandTotalSummary | null>,
      default: null,
    },
  },

  data() {
    return {
      buildingViewCarouselItemIndex: 0,
      isBuildingRemarksDialogOpen: false,
      isBuildingRemarksUnsavedChangesDialogOpen: false,
      isBuildingRemarksUpdated: false,
      isBuildingViewDialogOpen: false,
      tabIndex: 0,
      updateRemarksValidationErrorMessage: null as string | null,
    };
  },

  computed: {
    buildingRemarks() {
      if (!this.building?.code) {
        return null;
      }
      return (
        this.$store.state.stackingPlan.buildingRemarks.byBuildingCode[
          this.building.code
        ] ?? this.building.stackingPlanRemarks
      );
    },
    isBuildingViewListEmpty() {
      return (
        this.building?.viewList == null || this.building.viewList.length === 0
      );
    },
  },

  methods: {
    handleBuildingRemarksEditClick() {
      this.isBuildingRemarksDialogOpen = true;
    },
    handleBuildingRemarksConfirm() {
      this.isBuildingRemarksDialogOpen = false;
      this.isBuildingRemarksUpdated = true;
    },
    handleBuildingRemarksCancelClick() {
      this.isBuildingRemarksDialogOpen = false;
    },
    handleBuildingViewCarouselItemClick(itemIndex: number) {
      this.buildingViewCarouselItemIndex = itemIndex;
    },
    handleBuildingViewCarouselZoomClick() {
      this.isBuildingViewDialogOpen = true;
    },
    handleBuildingViewCarouselItemIndexChange(itemIndex: number) {
      this.buildingViewCarouselItemIndex = itemIndex;
    },
    handleBuildingViewDialogCloseClick() {
      this.isBuildingViewDialogOpen = false;
    },
    async updateRemarks(remarks: string) {
      const buildingCode = this.building?.code;
      if (!buildingCode) {
        return;
      }

      this.updateRemarksValidationErrorMessage = null;

      const validated = validate(UpdateBuildingRemarksSchema, remarks);

      if (validated.type === "Valid") {
        const api = !this.buildingRemarks
          ? createBuildingRemarks
          : updateBuildingRemarks;
        await api(buildingCode, validated);
        this.$store.dispatch("stackingPlan/updateBuildingRemarks", {
          buildingCode,
          remarks,
        });
      } else {
        this.updateRemarksValidationErrorMessage = validated.error.message;
        throw validated.error;
      }
    },
  },
});

import differenceInMonths from "date-fns/differenceInMonths";

import { isNonNullable, Maybe } from "../types/Maybe";
import { Building } from "../models/Building";
import { Floor } from "../models/Floor";
import { getKeyAttribute, Lease } from "../models/Lease";
import {
  LeaseCustomization,
  OfficeLeaseCustomization,
  RetailLeaseCustomization,
  TurnoverRate as RetailLeaseCustomizationTurnoverRate,
} from "../models/LeaseCustomization";
import { LeaseEffectiveUnitRent } from "../models/LeaseEffectiveUnitRent";
import { LeaseRentFreePeriod } from "../models/LeaseRentFreePeriod";
import { LeaseRentReview } from "../models/LeaseRentReview";
import { LeaseTurnoverRent } from "../models/LeaseTurnoverRent";
import { Legend, LegendFilterGroup, LegendFilterType } from "../models/Legend";
import {
  getCustomizedOrElse,
  OfficeLeaseAndCustomization,
  RetailLeaseAndCustomization,
} from "../types/LeaseAndCustomization";
import {
  isEqual,
  RecordByKeyAttribute,
  setRecord,
} from "../types/LeaseKeyAttribute";
import { matchLegendFilter } from "../types/TableFilter";
import {
  getMonthsFromStartAndEnd,
  formatMonthDay,
  formatMonthDayInterval,
} from "../models/MonthDay";
import { sum } from "./sum";

export function isApproved(lease: Lease) {
  return lease.approvalStatus === "Approved";
}

export function isWaitingForApproval(lease: Lease) {
  return lease.approvalStatus === "Waiting for Approval";
}

export function isRevising(lease: Lease) {
  return lease.approvalStatus === "Revising";
}

export function isDraft(lease: Lease) {
  return lease.approvalStatus === "Draft";
}

export function isOffice(lease: Lease) {
  return lease.portfolioType === "O";
}

export function isRetail(lease: Lease) {
  return lease.portfolioType === "R";
}

export function isVacant(lease: Lease) {
  return lease.leaseNo == null;
}

export type GroupType = "vacant" | "default" | "group-parent" | "group-child";

export interface Grouping<T extends LeaseCustomization> {
  leases: Lease[];
  customization: Maybe<T>;
  groupType: GroupType;
  isFuture: boolean;
  isRentReview: boolean;
}

export function getGrouping(
  leaseAndCustomization: OfficeLeaseAndCustomization,
  leaseAndCustomizations: OfficeLeaseAndCustomization[]
): Grouping<OfficeLeaseCustomization> {
  const { lease } = leaseAndCustomization;
  if (lease.leaseNo == null) {
    return {
      leases: [lease],
      customization: leaseAndCustomization.customization,
      groupType: "vacant",
      isFuture: getVacantFutureFlag(lease),
      isRentReview: false,
    };
  }

  const leaseNo = lease.leaseNo;
  const effPsf = getCustomizedOrElse(
    leaseAndCustomization,
    "effectiveUnitRent"
  )((lease) => lease.leaseUnit?.effectiveUnitRent?.effectiveUnitRent);
  const isFuture = getFutureFlag(lease);
  const isRentReview = getRentReviewFlag(lease);

  const grouping = groupLeases(leaseAndCustomizations, {
    leaseNo,
    effPsf,
    isFuture,
  });

  const groupedLeases = grouping.map(({ lease }) => lease);
  const latestCustomization = grouping
    .map(({ customization }) => customization)
    .filter(isNonNullable)
    .sort((a, b) => {
      return b.lastUpdatedDate.getTime() - a.lastUpdatedDate.getTime();
    })[0];

  if (grouping.length > 1) {
    if (
      lease.leaseUnit?.leaseUnitID === grouping[0].lease.leaseUnit?.leaseUnitID
    ) {
      return {
        leases: groupedLeases,
        customization: latestCustomization,
        groupType: "group-parent",
        isFuture,
        isRentReview,
      };
    } else {
      return {
        leases: groupedLeases,
        customization: latestCustomization,
        groupType: "group-child",
        isFuture,
        isRentReview,
      };
    }
  }
  return {
    leases: groupedLeases,
    customization: latestCustomization,
    groupType: "default",
    isFuture,
    isRentReview,
  };
}

export function getRetailGrouping(
  leaseAndCustomization: RetailLeaseAndCustomization
): Grouping<RetailLeaseCustomization> {
  const { lease, customization } = leaseAndCustomization;
  return isVacant(lease)
    ? {
        leases: [lease],
        customization,
        groupType: "vacant",
        isFuture: false,
        isRentReview: false,
      }
    : {
        leases: [lease],
        customization,
        groupType: "default",
        isFuture: getFutureFlag(lease),
        isRentReview: getRentReviewFlag(lease),
      };
}

export function getTotalUnitArea(leases: Lease[]): number {
  return sum(leases.map((lease) => getUnitArea(lease)));
}

export function getUnitArea(
  lease: Pick<
    Lease,
    | "calculationFloorArea"
    | "netFloorArea"
    | "grossFloorArea"
    | "otherFloorArea"
    | "lettableFloorArea"
  >
): number {
  switch (lease.calculationFloorArea) {
    case "N":
      return lease.netFloorArea;
    case "G":
      return lease.grossFloorArea;
    case "O":
      return lease.otherFloorArea;
    case "L":
      return lease.lettableFloorArea;
    default:
      return 0;
  }
}

export function getVacantFutureFlag(lease: Lease): boolean {
  const now = new Date();
  const buildingUnitEffectiveDate = lease.buildingUnit?.effectiveDate;
  return buildingUnitEffectiveDate != null && buildingUnitEffectiveDate > now;
}

export function getFutureFlag(lease: Lease): boolean {
  const now = new Date();
  const startDate = lease.leaseUnit?.licenseStartDate ?? lease.commencementDate;
  return startDate != null && now < startDate;
}

export function getRentReviewFlag(lease: Lease): boolean {
  const { rentReview } = lease;
  const effectiveUnitRent = lease.leaseUnit?.effectiveUnitRent;
  if (rentReview == null || effectiveUnitRent == null) {
    return false;
  }
  const now = new Date();
  return (
    rentReview.rentReviewDate > now && effectiveUnitRent.effectiveUnitRent > 0
  );
}

export function getVacantFlag(lease: Lease): boolean {
  return isVacant(lease);
}

export function getExpiryLessThanOrEqualTo12MonthsFlag(
  lease: Lease,
  now: Date
): boolean {
  if (lease.leaseUnit?.expiryDate == null) {
    return false;
  }
  if (lease.leaseUnit.expiryDate < now) {
    // catch past date but not 1 month yet will still return 0
    return false;
  }
  const diff = differenceInMonths(lease.leaseUnit.expiryDate, now);
  return 0 <= diff && diff < 12;
}

export function getFutureLeasesOfLeaseEndAfter12Months<L extends Lease>(
  lease: L,
  leases: L[],
  now: Date
): L[] {
  const otherLeasesOfTheSameBuildingUnitId = leases.filter(
    (l) =>
      l.buildingUnitID === lease.buildingUnitID &&
      !isEqual(getKeyAttribute(l), getKeyAttribute(lease))
  );
  return otherLeasesOfTheSameBuildingUnitId.filter((l) =>
    getExpiryGreaterThan12MonthsFlag(l, now)
  );
}

export function getExpiryGreaterThan12MonthsFlag(
  lease: Lease,
  now: Date
): boolean {
  if (lease.leaseUnit?.expiryDate == null) {
    return false;
  }
  const diff = differenceInMonths(lease.leaseUnit.expiryDate, now);
  return diff >= 12;
}

function isRentReviewCompleted(rentReview: LeaseRentReview) {
  return rentReview.rentReviewStatus === "Completed";
}

export function getPendingRentReviewIn12Month(
  lease: Lease,
  now: Date
): boolean {
  return (lease.rentReviews ?? []).some((rentReview) => {
    if (isRentReviewCompleted(rentReview)) {
      return false;
    }
    if (rentReview.rentReviewDate < now) {
      // catch past date but not 1 month yet will still return 0
      return false;
    }
    const diff = differenceInMonths(rentReview.rentReviewDate, now);
    return 0 <= diff && diff < 12;
  });
}

export function getFutureLeasesOfLease<L extends Lease>(
  lease: L,
  leases: L[]
): L[] {
  const otherLeasesOfTheSameBuildingUnitId = leases.filter(
    (l) =>
      l.buildingUnitID === lease.buildingUnitID &&
      !isEqual(getKeyAttribute(l), getKeyAttribute(lease))
  );
  return otherLeasesOfTheSameBuildingUnitId.filter((l) => getFutureFlag(l));
}

export type TurnoverRate = {
  percentage: number;
  from: Maybe<Date>;
  to: Maybe<Date>;
};
export function getCurrentTurnoverRates(
  turnoverRents: Maybe<LeaseTurnoverRent[]>
): TurnoverRate[] {
  const now = new Date();
  return (turnoverRents ?? [])
    .flatMap((x) => {
      const [torPercentage] = x.torPercentages ?? [];
      if (torPercentage == null) {
        return [];
      }
      if (x.endDate != null && x.endDate < now) {
        return [];
      }
      return [
        {
          percentage: torPercentage.turnoverPercentage,
          from: x.startDate,
          to: x.endDate,
        },
      ];
    })
    .sort((a, b) => {
      if (b.from == null) {
        return -1;
      }
      if (a.from == null) {
        return 1;
      }
      return a.from < b.from ? -1 : 1;
    });
}
export function getFaceRentFromEffectiveUnitRent(
  effectiveUnitRent: Maybe<LeaseEffectiveUnitRent>,
  rentFreePeriods: Maybe<Array<LeaseRentFreePeriod>>,
  unitArea: number
): Maybe<number> {
  if (effectiveUnitRent == null) {
    return null;
  }
  if (rentFreePeriods == null || rentFreePeriods.length === 0) {
    return effectiveUnitRent.effectiveUnitRent * unitArea;
  }
  const rentEffectivePeriod = getMonthsFromStartAndEnd(
    effectiveUnitRent.startDate,
    effectiveUnitRent.endDate
  );
  const rentFreePeriod = sum(
    rentFreePeriods.map((x) => getMonthsFromStartAndEnd(x.startDate, x.endDate))
  );
  return (
    (effectiveUnitRent.effectiveUnitRent * unitArea * rentEffectivePeriod) /
    (rentEffectivePeriod - rentFreePeriod)
  );
}

export function mapRetailLeaseCustomizationTurnoverRateToTurnoverRate(
  tor: RetailLeaseCustomizationTurnoverRate
): TurnoverRate {
  return {
    from: tor.startDate,
    to: tor.endDate,
    percentage: tor.turnoverRate,
  };
}

export function getRentReviews(
  rentReviews: LeaseRentReview[]
): LeaseRentReview[] {
  return rentReviews
    .slice() // Avoid mutating origin array after `.sort`
    .sort((a, b) => a.rentReviewDate.getTime() - b.rentReviewDate.getTime());
}

export function getRflp(lease: Lease): {
  rf: string | null;
  lp: string | null;
} {
  const licenseStartDate = lease.leaseUnit?.licenseStartDate;
  const licenseEndDate = lease.leaseUnit?.licenseEndDate;
  const rentFreePeriods = lease.leaseUnit?.rentFreePeriods;
  const rf: string | null =
    rentFreePeriods && rentFreePeriods.length > 0
      ? rentFreePeriods
          .map(({ startDate, endDate }) =>
            formatMonthDayInterval(startDate, endDate)
          )
          .join(", ")
      : null;

  const lp =
    licenseStartDate != null && licenseEndDate != null
      ? formatMonthDayInterval(licenseStartDate, licenseEndDate)
      : null;

  return { rf, lp };
}

export function getRflpFromLeaseCustomization(
  leaseCustomization: OfficeLeaseCustomization | RetailLeaseCustomization
): { rf: string | null; lp: string | null } {
  if (!leaseCustomization) {
    return { rf: null, lp: null };
  }
  const { licensePeriods, rentFreePeriods } = leaseCustomization;

  return {
    rf:
      rentFreePeriods != null && rentFreePeriods.length > 0
        ? [...rentFreePeriods]
            .sort((a, b) => a.displaySeq - b.displaySeq)
            .map(formatMonthDay)
            .join(", ")
        : null,
    lp:
      licensePeriods != null && licensePeriods.length > 0
        ? [...licensePeriods]
            .sort((a, b) => a.displaySeq - b.displaySeq)
            .map(formatMonthDay)
            .join(", ")
        : null,
  };
}

export interface COF {
  c: boolean;
  o: boolean;
  f: boolean;
}

/**
 * - Vacant should have no cof
 * - Rent review done should be future only
 * - Retail should not be counted occupied in office summary
 * - For all leases
 *     - if there exists a rent review done lease, it has no future
 *     - otherwise cof
 */
export function getCof(
  lease: Lease,
  leases: Lease[],
  stackingPlanType: "office" | "retail",
  asOf: Date
): COF {
  if (isVacant(lease)) {
    return {
      c: false,
      o: false,
      f: false,
    };
  }
  if (getRentReviewFlag(lease)) {
    return {
      c: false,
      o: false,
      f: true,
    };
  }
  if (lease.buildingUnit && lease.buildingUnit.effectiveDate > asOf) {
    // Only F when the building unit takes effect in the future
    return {
      c: false,
      o: false,
      f: true,
    };
  }
  if (getFutureFlag(lease)) {
    const isStrictlyFutureLease = !leases.some(
      (l) => l.buildingUnitID === lease.buildingUnitID && !getFutureFlag(l)
    );
    return {
      c: false,
      o: isStrictlyFutureLease,
      f: true,
    };
  }
  if (isRetail(lease) && stackingPlanType === "office") {
    return {
      c: true,
      o: false,
      f: true,
    };
  }
  const hasFutureInSameBuildingUnit = leases.some((l) => {
    return l.buildingUnitID === lease.buildingUnitID && getFutureFlag(l);
  });
  const isRentReviewByOtherLease = leases.some((l) => {
    return l.leaseNo === lease.leaseNo && getRentReviewFlag(l);
  });
  const hasBuildingUnitExpiry = lease.buildingUnit?.expiryDate != null;
  if (
    hasBuildingUnitExpiry ||
    hasFutureInSameBuildingUnit ||
    isRentReviewByOtherLease
  ) {
    return {
      c: true,
      o: true,
      f: false,
    };
  }
  return {
    c: true,
    o: true,
    f: true,
  };
}

export function getLegendMap<T extends LeaseCustomization>(
  groupings: Grouping<T>[],
  legends: Legend[],
  stackingPlanType: "office" | "retail"
): RecordByKeyAttribute<Maybe<Legend[]>> {
  let res: RecordByKeyAttribute<Maybe<Legend[]>> = {};
  const leases = groupings.reduce((prev: Lease[], curr: Grouping<T>) => {
    return [...prev, ...curr.leases];
  }, []);
  for (const grouping of groupings) {
    for (const lease of grouping.leases) {
      res = setRecord(res, getKeyAttribute(lease), () =>
        legends.filter((legend) =>
          matchLegendFilter(
            legend,
            {
              lease,
              leaseCustomization: grouping.customization,
              leases,
              floorZone: null,
            },
            stackingPlanType
          )
        )
      );
    }
  }
  return res;
}

export function getCOFMap(
  leases: Lease[],
  stackingPlanType: "office" | "retail"
): RecordByKeyAttribute<COF> {
  let res: RecordByKeyAttribute<COF> = {};
  for (const lease of leases) {
    res = setRecord(res, getKeyAttribute(lease), () =>
      getCof(lease, leases, stackingPlanType, new Date())
    );
  }
  return res;
}

function groupLeases(
  leaseAndCustomizations: OfficeLeaseAndCustomization[],
  opts: {
    leaseNo: string;
    effPsf: Maybe<number>;
    isFuture: boolean;
  }
): OfficeLeaseAndCustomization[] {
  return leaseAndCustomizations.filter((leaseAndCustomization) => {
    const { lease } = leaseAndCustomization;
    const leaseNo = lease.leaseNo;
    const isFuture = getFutureFlag(lease);
    const effPsf = getCustomizedOrElse(
      leaseAndCustomization,
      "effectiveUnitRent"
    )((lease) => lease.leaseUnit?.effectiveUnitRent?.effectiveUnitRent);
    if (leaseNo !== opts.leaseNo) {
      return false;
    }
    if (isFuture !== opts.isFuture) {
      return false;
    }
    if (effPsf == null || opts.effPsf == null) {
      return effPsf == null && opts.effPsf == null;
    }
    return effPsf.toFixed(2) === opts.effPsf.toFixed(2);
  });
}

interface LeaseFloor {
  floorName: string;
  plsBuildingId: number;
  plsBuildingFloorId: number;
}

function makeFloorFromLease(lease: Lease): LeaseFloor {
  return {
    floorName: lease.floor,
    plsBuildingId: lease.buildingID,
    plsBuildingFloorId: lease.buildingFloorID,
  };
}

export function makeFloorsFromLeases(leases: Lease[]): LeaseFloor[] {
  const floors = leases.map(makeFloorFromLease);
  const grouppedFloors: Record<number, LeaseFloor> = {};
  for (const floor of floors) {
    const { plsBuildingFloorId } = floor;
    if (!plsBuildingFloorId) {
      continue;
    }
    grouppedFloors[plsBuildingFloorId] = floor;
  }
  return Object.values(grouppedFloors);
}

export function aggregateFloorsAndLeaseFloors(
  floors: Floor[],
  buildings: Building[],
  leaseFloors: LeaseFloor[]
): Floor[] {
  const idsFromFloors = floors
    .map((f) => f.plsBuildingFloorId)
    .filter(isNonNullable);
  const leaseFloorsNotInFloors = leaseFloors.filter(
    (lf) => idsFromFloors.indexOf(lf.plsBuildingFloorId) === -1
  );
  const buildingsById = Object.fromEntries(
    buildings.flatMap((x) => x.plsBuildingId.map((y) => [y, x]))
  );
  const maxDisplaySeq = Math.max(...floors.map((f) => f.displaySeq));
  return floors.concat(
    ...leaseFloorsNotInFloors.map((lf, index) => ({
      // Negative id means imaginary
      id: -index - 1,
      buildingCode: buildingsById[lf.plsBuildingId]?.code ?? "",
      displaySeq: maxDisplaySeq + lf.plsBuildingFloorId,
      floorName: lf.floorName,
      zone: null,
      plsBuildingFloorId: lf.plsBuildingFloorId,
    }))
  );
}

export function matchLegendForColor(
  legends: Legend[],
  args: {
    lease: Lease;
    leaseCustomization: Maybe<LeaseCustomization>;
    leases: Lease[];
    floorZone: Maybe<string>;
  },
  stackingPlanType: "office" | "retail",
  selectedLegends: Legend[]
): Maybe<Legend> {
  let priorityByFilterType = [
    LegendFilterType.RENT_REVIEW_IN_12_MONTHS,
    LegendFilterType.LTE_12_MONTHS,
    LegendFilterType.GT_12_MONTHS,
  ];
  if (selectedLegends.length > 0) {
    // order by isSelected, then the defined priority
    const selectedLegendFilterTypes = new Set(
      selectedLegends
        .map((legend) => legend.filterType)
        .filter((type) => type != null)
    );
    priorityByFilterType = [
      ...priorityByFilterType.filter((type) =>
        selectedLegendFilterTypes.has(type)
      ),
      ...priorityByFilterType.filter(
        (type) => !selectedLegendFilterTypes.has(type)
      ),
    ];
  }
  const { leaseCustomization } = args;
  if (leaseCustomization?.status == null) {
    return legends
      .filter((legend) => legend.filterGroup === LegendFilterGroup.STATUS)
      .filter((legend) => matchLegendFilter(legend, args, stackingPlanType))
      .sort((a, b) => {
        return (
          priorityByFilterType.indexOf(a.filterType) -
          priorityByFilterType.indexOf(b.filterType)
        );
      })[0];
  }
  const filterType = ((status) => {
    switch (status) {
      case "Vacant":
        return LegendFilterType.VACANT;
      case "Likely to Vacate":
        return LegendFilterType.LIKELY_TO_VACATE;
      case "Confirmed to Vacate":
        return LegendFilterType.CONFIRMED_TO_VACATE;
      case "<= 12 Months":
        return LegendFilterType.LTE_12_MONTHS;
      case "> 12 Months":
        return LegendFilterType.GT_12_MONTHS;
      case "Rent Review in 12 Months":
        return LegendFilterType.RENT_REVIEW_IN_12_MONTHS;
      case "Vulnerable":
        return LegendFilterType.VULNERABLE;
      default:
        return null;
    }
  })(leaseCustomization.status);
  return legends.find((legend) => legend.filterType === filterType);
}

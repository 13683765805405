import {
  compareBuildingsByIsStreetBuildingAndDisplaySeq,
  isOfficeBuilding,
} from "../utils/buildings";
import { Building } from "../models/Building";
import { MO } from "../models/MO";
import { Maybe } from "../types/Maybe";

interface LocationSiteOption {
  type: "locationSite";
  locationSite: MO;
  disabled: true;
}

export function makeLocationSiteOption(locationSite: MO): LocationSiteOption {
  return {
    type: "locationSite",
    locationSite,
    disabled: true,
  };
}

interface BuildingOption {
  type: "building";
  building: Building;
  image: Maybe<string>;
  itemText: string;
  itemValue: string;
}

export function makeBuildingOption(building: Building): BuildingOption {
  return {
    type: "building",
    building,
    image: building.photoList?.[0]?.filePath,
    itemText: building.name,
    itemValue: building.code,
  };
}

interface RetailOption {
  type: "retail";
  mo: MO;
  itemText: string;
  itemValue: string;
}

export function makeRetailOption(mo: MO): RetailOption {
  return {
    type: "retail",
    mo,
    itemText: mo.name,
    itemValue: mo.code,
  };
}

export type Option = LocationSiteOption | BuildingOption | RetailOption;

export type SelectableOption = BuildingOption | RetailOption;

export function isSelectableOption(option: Option): option is SelectableOption {
  return option.type !== "locationSite";
}

export function makeBuildingSelectOptionsFromMOList(moList: MO[]): Option[] {
  return moList
    .filter((x) => x.active)
    .reduce<Option[]>((prev, mo) => {
      const buildingOptions = mo.buildings
        .filter((x) => x.active)
        .filter((x) => x.canReadOfficeStackingPlan)
        .filter(isOfficeBuilding)
        .sort(compareBuildingsByIsStreetBuildingAndDisplaySeq)
        .map(makeBuildingOption);
      const canReadRetailBuildings = mo.buildings
        .filter((x) => x.active)
        .filter((x) => x.canReadRetailStackingPlan);
      const retailOptions =
        canReadRetailBuildings.length > 0 && mo.hasRetailStackingPlan
          ? [makeRetailOption(mo)]
          : [];
      if (buildingOptions.length > 0 || retailOptions.length > 0) {
        return [
          ...prev,
          makeLocationSiteOption(mo),
          ...buildingOptions,
          ...retailOptions,
        ];
      }
      return prev;
    }, []);
}

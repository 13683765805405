
import {
  failure,
  initial,
  isPending,
  pending,
  RemoteData,
  success,
} from "@devexperts/remote-data-ts";
import format from "date-fns/format";
import { defineComponent, PropType } from "vue";
import { Maybe } from "yup/lib/types";
import { createOrUpdateRetailLeaseCustomization } from "../apis/retailLeaseCustomization";
import formatNumber from "../filters/formatNumber";
import prefix from "../filters/prefix";
import suffix from "../filters/suffix";
import { Lease } from "../models/Lease";
import { RetailLeaseCustomization } from "../models/LeaseCustomization";
import { maybeApply } from "../types/Maybe";
import { clampDecimalDigits } from "../utils/clamp";
import { TurnoverRate } from "../utils/lease";
import LeaseCustomizationFormChipsInput from "./LeaseCustomizationFormChipsInput.vue";
import LeaseCustomizationFormMonthsDaysInput from "./LeaseCustomizationFormMonthsDaysInput.vue";
import LeaseCustomizationFormTurnoverRateInput from "./LeaseCustomizationFormTurnoverRateInput.vue";
import {
  FormState,
  FormValueBase,
  makeRequestBody,
  SelectOption,
} from "./RetailLeaseCustomizationFormViewModel";
import TextDialog, { TextDialogConfig } from "./TextDialog.vue";

export default defineComponent({
  name: "RetailLeaseCustomizationForm",

  components: {
    TextDialog,
    LeaseCustomizationFormMonthsDaysInput,
    LeaseCustomizationFormChipsInput,
    LeaseCustomizationFormTurnoverRateInput,
  },

  emits: ["submitSuccess", "cancel"],

  props: {
    moCode: String,
    buildingCode: String,
    statusOptions: Array as PropType<SelectOption<string>[]>,
    foodLicenseOptions: Array as PropType<SelectOption<string>[]>,
    initialEnableCustomization: Boolean,
    initialFormState: Object as PropType<FormState>,
    formValueBase: Object as PropType<FormValueBase>,
    lease: Object as PropType<Lease>,
    customization: Object as PropType<RetailLeaseCustomization>,
  },

  data(): {
    enableCustomization: boolean;
    formState: FormState;
    submitRequestState: RemoteData<Error, unknown>;
    cancelDialogIsOpen: boolean;
    confirmDialogIsOpen: boolean;
    isFormDirty: boolean;
  } {
    return {
      enableCustomization: this.initialEnableCustomization,
      formState: {
        unitArea: this.initialFormState?.unitArea ?? null,
        tenantName: this.initialFormState?.tenantName ?? null,
        faceRent: this.initialFormState?.faceRent ?? null,
        faceUnitRent: this.initialFormState?.faceUnitRent ?? null,
        effectiveUnitRent: this.initialFormState?.effectiveUnitRent ?? null,
        licensePeriods: this.initialFormState?.licensePeriods ?? [],
        rentFreePeriods: this.initialFormState?.rentFreePeriods ?? [],
        turnoverRates: this.initialFormState?.turnoverRates ?? [],
        hasFoodLicense: this.initialFormState?.hasFoodLicense ?? false,
        foodLicense: this.initialFormState?.foodLicense ?? null,
        status: this.initialFormState?.status ?? null,
        excludeInSummary: this.initialFormState?.excludeInSummary ?? false,
        outsideSittingArea: this.initialFormState?.outsideSittingArea ?? false,
        includeInCalculationWithoutNewLeases:
          this.initialFormState?.includeInCalculationWithoutNewLeases ?? false,
        includeInCalculationWithNewLeases:
          this.initialFormState?.includeInCalculationWithNewLeases ?? false,
        includeInOccupied: this.initialFormState?.includeInOccupied ?? false,
      },
      submitRequestState: initial,
      cancelDialogIsOpen: false,
      confirmDialogIsOpen: false,
      isFormDirty: false,
    };
  },

  watch: {
    formState: {
      handler() {
        this.isFormDirty = true;
      },
      deep: true,
    },
  },

  computed: {
    isSubmitLoading() {
      return isPending(this.submitRequestState);
    },

    cancelDialogConfig(): TextDialogConfig {
      return {
        title: this.$skp.t("leaseCustomization.cancelDialog.title"),
        message: this.$skp.t("leaseCustomization.cancelDialog.message"),
        buttons: [
          {
            text: this.$skp.t(
              "leaseCustomization.cancelDialog.confirmButtonText"
            ),
            style: {
              depressed: true,
            },
            color: "primary",
            action: () => {
              this.cancelDialogIsOpen = false;
              this.doCancel();
            },
          },
          {
            text: this.$skp.t(
              "leaseCustomization.cancelDialog.cancelButtonText"
            ),
            style: {
              outlined: true,
            },
            color: "grey",
            action: () => {
              this.handleCancelDialogDismiss();
            },
          },
        ],
      };
    },

    confirmDialogConfig(): TextDialogConfig {
      return {
        title: this.$skp.t("leaseCustomization.confirmDialog.title"),
        message: this.$skp.t("leaseCustomization.confirmDialog.message"),
        buttons: [
          {
            text: this.$skp.t(
              "leaseCustomization.confirmDialog.confirmButtonText"
            ),
            style: {
              depressed: true,
            },
            disabled: this.isSubmitLoading,
            loading: this.isSubmitLoading,
            color: "primary",
            action: () => {
              this.doSubmit();
            },
          },
          {
            text: this.$skp.t(
              "leaseCustomization.confirmDialog.cancelButtonText"
            ),
            style: {
              outlined: true,
            },
            disabled: this.isSubmitLoading,
            color: "grey",
            action: () => {
              this.handleConfirmDialogDismiss();
            },
          },
        ],
      };
    },
  },

  methods: {
    toggleEnableCustomization() {
      this.enableCustomization = !this.enableCustomization;
    },

    parseAndClamp(value: string): number {
      return clampDecimalDigits(parseFloat(value), 9, 2);
    },

    handleUnitAreaChange(value: string) {
      this.formState.unitArea = this.parseAndClamp(value);
    },

    handleFaceRentChange(value: string) {
      this.formState.faceRent = this.parseAndClamp(value);
    },

    handleFaceUnitRentChange(value: string) {
      this.formState.faceUnitRent = this.parseAndClamp(value);
    },

    handleEffectiveUnitRentChange(value: string) {
      this.formState.effectiveUnitRent = this.parseAndClamp(value);
    },

    handleAddLicensePeriod(months: number, days: number) {
      this.formState.licensePeriods.push({ months, days });
    },

    handleRemoveLicensePeriod(licensePeriod: unknown) {
      this.formState.licensePeriods = this.formState.licensePeriods.filter(
        (lp) => lp !== licensePeriod
      );
    },

    handleAddRentFreePeriod(months: number, days: number) {
      this.formState.rentFreePeriods.push({ months, days });
    },

    handleRemoveRentFreePeriod(rentFreePeriod: unknown) {
      this.formState.rentFreePeriods = this.formState.rentFreePeriods.filter(
        (rf) => rf !== rentFreePeriod
      );
    },

    handleAddTurnoverRate(
      turnoverRate: number,
      startDate: Date,
      endDate: Date
    ) {
      this.formState.turnoverRates.push({ turnoverRate, startDate, endDate });
    },

    handleRemoveTurnoverRate(turnoverRate: unknown) {
      this.formState.turnoverRates = this.formState.turnoverRates.filter(
        (tor) => tor !== turnoverRate
      );
    },

    handleSubmit() {
      this.confirmDialogIsOpen = true;
    },

    async doSubmit() {
      if (
        !this.moCode ||
        !this.buildingCode ||
        !this.lease ||
        !this.formValueBase
      ) {
        return;
      }
      try {
        this.submitRequestState = pending;
        const updatedLeaseCustomization =
          await createOrUpdateRetailLeaseCustomization(
            makeRequestBody(
              this.moCode,
              this.buildingCode,
              this.formState,
              this.lease,
              this.customization,
              this.formValueBase.cof
            )
          );
        this.submitRequestState = success(updatedLeaseCustomization);
        this.$store.dispatch("stackingPlan/fillRetailLeaseCustomizations", [
          updatedLeaseCustomization,
        ]);
        this.confirmDialogIsOpen = false;
        this.$emit("submitSuccess");
      } catch (e: unknown) {
        if (e instanceof Error) {
          this.submitRequestState = failure(e);
        }
        throw e;
      }
    },

    handleCancel() {
      if (this.isFormDirty) {
        this.cancelDialogIsOpen = true;
      } else {
        this.doCancel();
      }
    },

    doCancel() {
      this.$emit("cancel");
    },

    handleCancelDialogDismiss() {
      this.cancelDialogIsOpen = false;
    },

    handleConfirmDialogDismiss() {
      this.confirmDialogIsOpen = false;
    },

    maybeApplyFormatNumber(value: Maybe<number>): Maybe<string> {
      return maybeApply(formatNumber, value);
    },

    maybeApplyFormatDigits(value: Maybe<number>): Maybe<string> {
      return maybeApply((value) => formatNumber(value, 2), value);
    },

    maybeApplyPrefix(
      value: Maybe<string | number>,
      suffixValue: string
    ): Maybe<string> {
      return maybeApply((v) => prefix(`${v}`, suffixValue), value);
    },

    maybeApplySuffix(
      value: Maybe<string | number>,
      suffixValue: string
    ): Maybe<string> {
      return maybeApply((v) => suffix(`${v}`, suffixValue), value);
    },

    maybeJoin<T>(value: Maybe<T[]>, delimiter: string): Maybe<string> {
      return maybeApply((xs) => xs.join(delimiter), value);
    },

    maybeMap<T, U>(value: Maybe<T[]>, fn: (t: T) => U): Maybe<U[]> {
      return maybeApply((xs) => xs.map(fn), value);
    },

    formatTurnoverRateDate(value: Date) {
      return format(value, "dd/MM/yyyy");
    },

    formatTurnoverRate(turnoverRate: TurnoverRate) {
      const { percentage, from, to } = turnoverRate;
      return `${percentage}%${
        from != null && to != null
          ? ` - ${format(from, "dd/MM/yyyy")} - ${format(to, "dd/MM/yyyy")}`
          : ""
      }`;
    },
  },
});

import * as yup from "yup";

import { isNonNullable } from "../types/Maybe";
import { parseInteger } from "../utils/parseInteger";
import { FileSchema } from "./File";

export const BuildingSchema = yup.object({
  id: yup.number().required(),
  plsBuildingId: yup
    .array()
    .of(yup.number().required())
    .required()
    .transform(
      (_, originalValue: string | null) =>
        originalValue?.split(",").map(parseInteger).filter(isNonNullable) ?? []
    ),
  moCode: yup.string().required(),
  displaySeq: yup.number().required(),
  code: yup.string().required(),
  name: yup.string().required(),
  nameHk: yup.string().required(),
  nameCn: yup.string().required(),
  photoList: yup.array().of(FileSchema).nullable(),
  viewList: yup.array().of(FileSchema).nullable(),
  stackingPlanRemarks: yup.string().nullable(),
  stackingPlanOtherBuildingArea: yup.number().nullable(),
  active: yup.boolean().required(),
  isStreetBuilding: yup.boolean().required(),
  isOfficeBuilding: yup.boolean().required(),
  canReadOfficeStackingPlan: yup.boolean().required(),
  canReadRetailStackingPlan: yup.boolean().required(),
  canCustomize: yup.boolean().required(),
  canCreateOfficeNegotiation: yup.boolean().required(),
  canReadOfficeNegotiation: yup.boolean().required(),
  canUpdateOfficeNegotiation: yup.boolean().required(),
  canCreateRetailNegotiation: yup.boolean().required(),
  canReadRetailNegotiation: yup.boolean().required(),
  canUpdateRetailNegotiation: yup.boolean().required(),
  canReadRemarks: yup.boolean().required(),
  canCreateRemarks: yup.boolean().required(),
  canUpdateRemarks: yup.boolean().required(),
  canCreateUnitRemarks: yup.boolean().required(),
  canReadUnitRemarks: yup.boolean().required(),
  canUpdateUnitRemarks: yup.boolean().required(),
  canCreateUnitFloorPlan: yup.boolean().required(),
  canReadUnitFloorPlan: yup.boolean().required(),
  canUpdateUnitFloorPlan: yup.boolean().required(),
  canDeleteUnitFloorPlan: yup.boolean().required(),
});

export type Building = yup.InferType<typeof BuildingSchema>;


import { PropType, defineComponent } from "vue";

const __default__ = defineComponent({
  name: "InvertedCorner",

  props: {
    type: {
      type: String as PropType<
        "top-left" | "top-right" | "bottom-left" | "bottom-right"
      >,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "3307297f": (_vm.size),
  "2cff15a5": (_vm.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__
import * as yup from "yup";

export enum OpportunityType {
  NEW_LETTING = "New Letting",
  RENT_REVIEW = "Rent Review",
  RENEWAL = "Renewal",
  EXTENSION = "Extension",
  RELOCATION = "Relocation",
}

export const OpportunityTypeSchema = yup
  .mixed<OpportunityType>()
  .oneOf([
    OpportunityType.NEW_LETTING,
    OpportunityType.RENT_REVIEW,
    OpportunityType.RENEWAL,
    OpportunityType.EXTENSION,
    OpportunityType.RELOCATION,
  ]);
